import router from '../../router';
import { getCreditReport } from '../../providers/creditWatchApiProviders';
import { getCreditChartData, getMortgagesChartData, getHirePurchaseData, getUnsecuredLoansChartData, getHistoricalDebtDetailsChartData } from '../../../utils/creditUtils';

const CUSTOMER_CREDIT_INFO = 'CUSTOMER_CREDIT_INFO';
export const CUSTOMER_CREDIT_INFO_FETCH = `${CUSTOMER_CREDIT_INFO}/FETCH`;
const CUSTOMER_CREDIT_INFO_UPDATE = `${CUSTOMER_CREDIT_INFO}/UPDATE`;
const CUSTOMER_CREDIT_INFO_FAILED = `${CUSTOMER_CREDIT_INFO}/FAILED`;
const CUSTOMER_CREDIT_INFO_PENDING = `${CUSTOMER_CREDIT_INFO}/PENDING`;
export const CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL = `${CUSTOMER_CREDIT_INFO}/ON_CLOSE_ERROR_MODAL`;
const CUSTOMER_CREDIT_INFO_CLOSE_ERROR_MODAL = `${CUSTOMER_CREDIT_INFO}/CLOSE_ERROR_MODAL`;

const emptyCustomerCreditInfo = {
    creditReport: {},
    creditChartData: { entries: [], isEmpty: true },
    hirePurchaseData: { entries: [], isEmpty: true },
    mortgagesChartData: { entries: [], isEmpty: true },
    unsecuredLoansChartData: { entries: [], isEmpty: true },
    ucscoreChartData: {},
    historicalDebtDetails: { entries: [], isEmpty: true },
}

const state = {
    customerCreditInfo: emptyCustomerCreditInfo,
    loaded: false,
    failed: false,
    pending: false,
    showErrorModal: false,
};

const mutations = {
    [CUSTOMER_CREDIT_INFO_UPDATE](state, newModel) {
        state.customerCreditInfo = newModel;
        state.customerCreditInfo.creditChartData = getCreditChartData(newModel.creditReport);
        state.customerCreditInfo.mortgagesChartData = getMortgagesChartData(newModel.creditReport);
        state.customerCreditInfo.hirePurchaseData = getHirePurchaseData(newModel.creditReport);
        state.customerCreditInfo.unsecuredLoansChartData = getUnsecuredLoansChartData(newModel.creditReport);
        state.customerCreditInfo.historicalDebtDetails = getHistoricalDebtDetailsChartData(newModel.creditReport);
        state.loaded = true;
        state.failed = false;
        state.pending = false;
    },
    [CUSTOMER_CREDIT_INFO_FAILED](state) {
        state.customerCreditInfo = emptyCustomerCreditInfo;
        state.loaded = true;
        state.failed = true;
        state.pending = false;
        state.showErrorModal = true;
    },
    [CUSTOMER_CREDIT_INFO_PENDING](state) {
        state.customerCreditInfo = emptyCustomerCreditInfo;
        state.loaded = false;
        state.failed = false;
        state.pending = true;
    },
    [CUSTOMER_CREDIT_INFO_CLOSE_ERROR_MODAL](state) {
        state.showErrorModal = false;
    },
};

const actions = {
    [CUSTOMER_CREDIT_INFO_FETCH]({ commit }) {
        commit(CUSTOMER_CREDIT_INFO_PENDING);
        return getCreditReport().then(response => {
            commit(CUSTOMER_CREDIT_INFO_UPDATE, response.data);
        }).catch(() => {
            commit(CUSTOMER_CREDIT_INFO_FAILED);
            //TODO: fix this after reworking episerver error page handling and moving to spa. We are doing this since
            // if the credit report cant be fetched then products like creditwatch and my informtion are broken
            router.push({ path: '/mina-tjanster' });
        });
    },
    [CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL]({ commit }) {
        commit(CUSTOMER_CREDIT_INFO_CLOSE_ERROR_MODAL);
    },
};

export const customerCreditInfoContex = {
    state,
    mutations,
    actions
};
