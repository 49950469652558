<template>
  <div class="my-credit-score-chart">
    <figure class="highcharts-figure">
      <div
        id="container-speed"
        class="chart-container"
      />

      <p class="highcharts-description" />
    </figure>
  </div>
</template>
<script>

    import * as Highcharts from 'highcharts/highcharts';
    import * as HighchartsMore from 'highcharts/highcharts-more';
    import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

    // Now init modules:
    HighchartsMore(Highcharts);
    HighchartsSolidGauge(Highcharts);
export default {
    name: 'CreditScore',
    components: {
    //ContactForm, BrokerPuff
    },
    props: {
        chartData: {},
        report: {
            creditReport: {
                //creditwatchScoreList:[]
            }
        }
    },
    data: function(){
        return{
            
            
        }
    },
    mounted: function () {
        this.InitChart(this.report.creditReport.creditScore);
    },methods: {
        InitChart: function (creditScore) {
            var riskGrade = creditScore.riskGrade;
            if (riskGrade < 0.2) riskGrade = 10;
            else if (riskGrade < 1) riskGrade = 30;
            else if (riskGrade < 4.1) riskGrade = 50;
            else if (riskGrade < 25.1) riskGrade = 70;
            else if (riskGrade > 25) riskGrade = 90;

            var gaugeOptions = {
                chart: {
                    styledMode:true,
                    type: 'gauge',
                    /*width:'100%',*/
                    /*backgroundColor: '#2b3d51',*/
                    height: '100%'
                },
                title:null,
                //title: {
                //    useHTML:true,
                //    align: 'left',
                //    text:'<h2>Mitt kreditbetyg</h2><p>Kreditbetyget är ett perfekt verktyg för att hjälpa dig ta kontroll över din privatekonomi. Du ser snabbt hur betyget ändras när din ekonomi ändras. Och det är spännande. Vad händer om du avslutar krediter eller tar ett nytt bolån?</p>'
                //},
                pane: {
                    center: ['50%', '55%'],
                    size: '100%',
                    startAngle: -80,
                    endAngle: 80,
                    background:null
                },

                exporting: {
                    enabled: false
                },

                tooltip: {
                    enabled: false
                },

                // the value axis
                yAxis: {
                    reversed: true,
                    labels: {
                        enabled: false,
                        x: 35, y: 0
                    },
                    tickPositions: [0,100],
                    minorTickLength: 0,
                    minorTickInterval:null,
                    min: 0,
                    max: 100,
                    plotBands: [{
                        from: 0,
                        to: 20,
                        className:'excellent',
                        thickness: '50%',
                        //label: {
                        //    text: 'Utmärkt',
                        //    align: 'top',
                        //    rotation:70,
                        //    //x: 5,
                        //    //y:-10
                        //}
                    }, {
                        from: 20,
                        to: 40,
                        className:'very-good',
                        thickness: '50%',
                        //label: {
                        //    text: 'Mycket bra',
                        //    align: 'top',
                        //    rotation: 35,
                        //    //x: 280,
                        //    //y: -10
                        //}
                    }, {
                        from: 40,
                        to: 60,
                        className:'good',
                        thickness: '50%',
                        //label: {
                        //    text: 'Bra',
                        //    align: 'center',
                        //    //rotation: 0,
                        //    x: 95,
                        //    y: 5
                        //}
                    },
                    {
                        from: 60,
                        to: 80,
                        className: 'worse',
                        thickness: '50%',
                        //label: {
                        //    text: 'Mindre bra',
                        //    align: 'top',
                        //    rotation: -35,
                        //    x:75,
                        //    y: 30
                        //}
                    },
                    {
                        from: 80,
                        to: 99.9,
                        className: 'not-good',
                        thickness: '50%',
                        //label: {
                        //    text: 'Svag',
                        //    align: 'top',
                        //    rotation: -60,
                        //    x: 20,
                        //    //y: 0
                        //}
                    }]
                }
            };

            // The speed gauge
            Highcharts.chart('container-speed', Highcharts.merge(gaugeOptions, {

                credits: {
                    enabled: false
                },

                series: [{
                    name: 'Speed',
                    data: [{ y: riskGrade }],
                    //data: [30],
                    dataLabels: {
                        y: 50,
                        verticalAlign: 'bottom',
                        className: 'gauge-value',
                        formatter: function () {
                            return creditScore.riskGrade + '%';
                        }
                        //format:
                        //    '<div class="gauge-value" style="text-align:center">' +
                        //    '<span style="font-size:32px">' + creditScore.riskGrade + '%</span><br/>' +
                        //    '</div>'
                    },
                    tooltip: {
                        valueSuffix: ' %'
                    }
                }]

            }));

            
            
        
        }
    }
}
</script>
<style lang="scss">
    @import "../../../../styles/abstracts/_breakpoints.scss";
    .dashboard__container .dashboard__wrapper .my-credit-score-chart {
        width: 100%;
        overflow: hidden;
        height: 280px;

        @include breakpoint(max-tablet-landscape) {
            height: 360px;
        }

        @include breakpoint(max-tablet-portrait) {
            height: 360px;
        }

        @include breakpoint(max-phone) {
            height: 280px;
        }

        @include breakpoint(max-smallphone) {
            height: 180px;
        }

        .excellent {
            fill: #57AF46;
            fill-opacity: 1;
        }

        .very-good {
            fill: #B7AE45;
            fill-opacity: 1;
        }

        .good {
            fill: #D8B74D;
            fill-opacity: 1;
        }

        .worse {
            fill: #E09353;
            fill-opacity: 1;
        }

        .not-good {
            fill: #EB585A;
            fill-opacity: 1;
        }

        .gauge-value {
            text {
                font-size: 32px;
                fill: #00A04B;
                stroke: #00A04B;
            }
        }
        /*tspan.gauge-value {
            tspan {
                fill: #00A04B;
                stroke: #00A04B;
            }
        }*/
        .highcharts-title {
            left: 0 !important;
            top: 0 !important;
        }

        .highcharts-label-box {
            stroke: none;
            background-color: #fff;
            fill: #fff !important;
        }
    }

</style>
