<template>
  <component
    :is="getComponentTypeForPage(model)"
    v-if="modelLoaded"
    :model="model"
  />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { getComponentTypeForContent } from '../Scripts/providers/epiContentProvider';

const store = useStore();
const model = computed(() => store.state.epiDataModelContext.model);
const modelLoaded = computed(() => store.state.epiDataModelContext.modelLoaded);

const getComponentTypeForPage = (model) => {
        return getComponentTypeForContent(model, window.components)
};
</script>
