<template>
  <NdsHeading title="Kontokredit" type="h4" tight>
    <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
      @click="creditCardQuestionModal.openModal" aria-label="Förklaring Kontokredit" />
  </NdsHeading>
  <div v-if="hasTerminatedCredits" class="d-flex justify-content-end my-3">
    <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
      variant="secondary" @click="showTerminated = !showTerminated">
      {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
    </NdsButton>
  </div>
  <NdsAccordion v-if="paginatedCredits.length > 0" v-model="activeAccordionId" :accordions="paginatedCredits">
    <template v-for="(credit, index) in paginatedCredits" #[credit.id] :key="index">
      <div class="p-3">
        <div class="row pb-3">
          <div class="col-8">
            Kreditlimit:
          </div>
          <div class="col-4 pl-0 text-right">
            {{ credit.grantedCredit }}
          </div>
          <div class="col-8 color-ocean">
            Utnyttjad kredit:
          </div>
          <div class="col-4 pl-0 color-ocean text-right">
            {{ credit.usedCredit }}
          </div>
        </div>
        <div class="row">
          <template v-if="credit.startDate">
            <div class="col-8">
              Startdatum:
            </div>
            <div class="col-4 pl-0 text-right">
              {{ credit.startDate }}
            </div>
          </template>
          <template v-if="!credit.endDate || credit.usedCredit > 0">
            <div class="col-8">
              Senast uppdaterad:
            </div>
            <div class="col-4 pl-0 text-right">
              {{ credit.updatedAt }}
            </div>
          </template>
          <template v-if="credit.endDate">
            <div class="col-8 color-red">
              Avslutad:
            </div>
            <div class="col-4 pl-0 color-red text-right">
              {{ credit.endDate }}
            </div>
          </template>
        </div>
      </div>
    </template>
  </NdsAccordion>
  <ZeroState v-else icon="far fa-credit-card" :text="model.descriptionNoCreditDevelopment.value || 'Ingen Kredit registrerad'" isRichText />
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
  <div class="mt-4">
    <NdsHeading title="Kreditkortsutveckling" type="h4" tight>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
        @click="() => creditDevelopmentQuestionModal.openModal()" aria-label="Förklaring Kreditkortsutveckling" />
    </NdsHeading>
    <div class="ndsCreditChartContainer">
      <NdsCreditBarChart v-if="isReady" title="" emptyTitle="Ingen kredit registrerad" :chartData="creditData" />
    </div>
  </div>
  <NdsFooter class="mt-3" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige" tight />
  <NdsConfirmModal v-bind="creditCardQuestionModal.props" v-model="creditCardQuestionModal.isOpen.value"
    @onConfirm="creditCardQuestionModal.closeModal" @onCancel="creditCardQuestionModal.closeModal" />
  <NdsConfirmModal v-bind="creditDevelopmentQuestionModal.props" v-model="creditDevelopmentQuestionModal.isOpen.value"
    @onConfirm="creditDevelopmentQuestionModal.closeModal" @onCancel="creditDevelopmentQuestionModal.closeModal" />
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { formatToSek, FormatDate } from '../_helpers.js';
import { NdsHeading, NdsCreditBarChart, NdsButton, NdsConfirmModal, NdsIconButton, NdsAccordion, NdsFooter, NdsPagination, useConfirmModal, usePagination } from '@nds/vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
  report: Object,
});

const credits = ref([]);
const showTerminated = ref(false);
const hasTerminatedCredits = ref(false);
const isReady = ref(false);

const formattedCredits = computed(() => (credits.value.map((credit, index) => ({
  id: `credit ${index + 1}`,
  title: credit.creditor ?? "Okänd långivare",
  grantedCredit: credit.grantedCredit != null ? formatToSek(credit.grantedCredit) : null,
  usedCredit: credit.usedCredit != null ? formatToSek(credit.usedCredit) : null,
  startDate: credit.fromDate?.timeStamp.value ? FormatDate(credit.fromDate.timeStamp.value) : null,
  updatedAt: credit.updatedAtDate?.timeStamp.value ? FormatDate(credit.updatedAtDate.timeStamp.value) : null,
  endDate: credit.toDate?.timeStamp.value ? FormatDate(credit.toDate.timeStamp.value) : null,
}))));

const filteredFormattedCredits = computed(() => (formattedCredits.value.filter(credit => (showTerminated.value ? true : !credit.endDate))));

const creditData = computed(() => ({
  valueLabel: 'Utnyttjad kredit',
  valueTooltip: 'Utnyttjad',
  totalLabel: 'Beviljad Kredit',
  totalTooltip: 'Beviljad',
  entries: props.report.creditChartData.entries,
}));

const creditCardQuestionModal = useConfirmModal({
  title: props.model.questionCreditCardTitle.value || 'Kontokredit',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionCreditCard.value,
    isRichText: true,
  },
});

const creditDevelopmentQuestionModal = useConfirmModal({
  title: props.model.questionCreditDevelopmentTitle.value || 'Kreditkortsutveckling',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionCreditDevelopment.value,
    isRichText: true,
  }
});

onMounted(() => {
  isReady.value = true;
  props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations.forEach((item) => {
    if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === 1) {
      credits.value.push(item);
      if (item.toDate != null) {
        hasTerminatedCredits.value = true;
      }
    }
  });
});

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedCredits,
} = usePagination(filteredFormattedCredits);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
<style scoped>
.ndsCreditChartContainer {
  margin-top: 8px;
  width: 100%;
  height: 320px;
}
</style>