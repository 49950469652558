<template>
    <div v-if="title" class="page-header" :class="isMobile && 'pl-4'">
        <NdsHeading :title="title" type="h3" :prefix="{ prefix, iconName }" tight onDarkBg />
    </div>
</template>
<script setup>
import { NdsHeading } from '@nds/vue';
import { useSiteBreakpoints } from '../../utils/breakpointsUtils';

const { isMobile } = useSiteBreakpoints();

defineProps({
    title: String,
    iconName: String,
    prefix: String,
});
</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_variables.scss";

.page-header {
    background: $night-rider;
}
</style>

