<template>
  <div class="orderform signupform" data-testid="signup-form-block">
    <h3>{{ model.heading }}</h3>
    <p>{{ model.priceInformation }}</p>
    <div class="signuplink-form">
      <label class="input-label" for="email">{{ formModel.email.label }}</label>
      <div class="signuplink-content">
        <input id="email" v-model="form.email" type="text" class="form-control"
          :class="v$.email.$error ? 'is-invalid' : ''" :placeholder="formModel.email.placeholder"
          :aria-describedby="formModel.email.ariaLabel" @keyup="onDataChange">
        <small v-if="v$.email.required.$invalid && dataChanged" class="is-invalid">
          {{ v$.email.required.$message }}
        </small>
        <small v-else-if="v$.email.email.$invalid && dataChanged" class="is-invalid">
          {{ v$.email.email.$message }}
        </small>
      </div>
    </div>
    <div class="form-check mt-2">
      <input class="form-check-input" type="checkbox" v-model="form.integritycheck" id="integrityCheck"
        :class="v$.integritycheck.$error ? 'is-invalid' : ''" :aria-describedby="formModel.integritycheck.ariaLabel"
        @click="onDataChange">
      <label class="form-check-label" for="integrityCheck">
        <span>Jag har läst <a :href="model.integrityPolicyLink">integritetspolicyn</a></span>
      </label>
    </div>
    <button class="signup-link-button btn btn-standard mt-2" @click="onSubmit" :disabled="v$.$invalid">
      {{ model.submitButtonLabel }}
    </button>
  </div>
</template>
<script setup>
import { computed, reactive, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers, sameAs } from '@vuelidate/validators';
import { postAddrecipienttoemailery } from '../../Scripts/providers/customerApiProvider';
import helperTexts from '../../../../json/helperTexts.json';

// TODO this block is currently not in use and is a candidate for removal both in regards of controller, model, cshml (in .net), provider and vue in js.
// The purpose of this block is to provide easy way of prompting user with joining mailing list
const props = defineProps({
  model: Object,
});

const dataChanged = ref(false);
const formModel = {
  email: {
    label: 'E-post',
    placeholder: helperTexts.placeholderEmail,
    ariaLabel: 'emailHelp',
  },
  integritycheck: {
    ariaLabel: 'policyHelp',
  },
};
const form = reactive({
  email: '',
  integritycheck: false,
});
const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
      email: helpers.withMessage(helperTexts.validationMessageEmail, email),
    },
    integritycheck: {
      sameAs: sameAs(true),
    },
  }
})

const v$ = useVuelidate(rules, form);

const onDataChange = () => {
  if (!v$.value.$error) {
    dataChanged.value = true;
  } else {
    dataChanged.value = false;
  }
};

const onSubmit = (e) => {
  e.preventDefault();
  postAddrecipienttoemailery({ mailAddress: form.email, mailingList: props.model.mailingList }).then(() => {
    return window.location.href = props.model.landingPageUrl;
  });
}
</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_breakpoints.scss";
@import "../../../../styles/abstracts/_mixins.scss";

.signuplink {
  gap: .5rem;
  min-height: 90px;
  height: 100%;

  @include breakpoint(max-tablet-portrait) {
    flex-direction: column;
  }

  .signuplink-form {
    flex-grow: 1;


    .input-label {
      font-size: 12px;
      width: 100%;
      @include truncate();
      margin: 0;
    }

    .signuplink-content {
      padding-top: 1px;
    }

    .form-control {
      border-radius: 6px;
      border: none;
    }
  }

  button {
    top: 32px;
    position: relative;
    height: 40px;
    @include truncate();
  }
}
</style>
