<template>
  <div v-if="productPackageInformationPending" class="align-items-center justify-content-center d-flex">
    <NdsLoader />
  </div>
  <Reloader v-else-if="productPackageInformationFailed" @reload="getProductPackages" />
  <div v-else-if="productPackageInformationLoaded" class="checkout-page" data-testid="checkout-page">
    <CheckoutSteperControl :model="formatedModel" />
    <div v-if="activeTab !== ACTIVE_TAB.COMPLETE_TAB" class="container pb-5 py-md-5"
      data-testid="checkout-page-stepper-container">
      <div id="checkoutStepsContent" class="tab-content">
        <div id="checkout-checkoutSelectProduct" class="tab-pane fade"
          :class="activeTab === ACTIVE_TAB.SELECT_PRODUCT_TAB ? 'show active' : ''" role="tabpanel"
          aria-labelledby="checkout-checkoutSelectProduct-tab">
          <CheckoutSelectProduct :model="formatedModel" :products="productsModel.products" />
        </div>
        <div id="checkout-CheckoutEnterInformation" class="tab-pane fade"
          :class="activeTab === ACTIVE_TAB.ENTER_INFORMATION_TAB ? 'show active' : ''" role="tabpanel"
          aria-labelledby="checkout-CheckoutEnterInformation-tab">
          <CheckoutEnterInformation v-if="selectedProduct" :model="formatedModel" />
        </div>
        <div id="checkoutPayment" class="tab-pane fade"
          :class="activeTab === ACTIVE_TAB.PAYMENT_TAB ? 'show active' : ''" role="tabpanel"
          aria-labelledby="checkoutPayment-tab">
          <CheckoutPayment v-if="hasProvidedAndConfirmedCustomer" :model="formatedModel" />
        </div>
      </div>
    </div>
    <div v-if="activeTab === ACTIVE_TAB.COMPLETE_TAB" class="container pb-3 py-md-5">
      <CheckoutConfirmation :model="formatedModel" />
    </div>
    <div v-if="!(activeTab === ACTIVE_TAB.PAYMENT_TAB || activeTab === ACTIVE_TAB.SELECT_PRODUCT_TAB)"
      class="col-12 pb-5 text-center">
      <p class="body-small">
        Säkrat köpflöde med
      </p>
      <img v-if="!formatedModel.hideSwishAsPaymentIcon" :src="paymentCards[0].path" class="payment__card-img mr-1"
        :alt="paymentCards[0].altText">
      <img v-for="(card, index) in paymentCards.slice(1)" :key="`consumer_properites_image_${index}`" :src="card.path"
        class="payment__card-img mr-1" :alt="card.altText">
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, ref, onMounted, nextTick, watch, reactive } from 'vue';
import { NdsLoader } from '@nds/vue';
import Reloader from '../components/Reloader.vue';
import CheckoutSelectProduct from '../components/checkout/CheckoutSelectProduct.vue';
import CheckoutEnterInformation from '../components/checkout/CheckoutEnterInformation.vue';
import CheckoutPayment from '../components/checkout/CheckoutPayment.vue';
import CheckoutConfirmation from '../components/checkout/CheckoutConfirmation.vue';
import CheckoutSteperControl from '../components/checkout/CheckoutSteperControl.vue';
import {
  ACTIVE_TAB,
  CHECKOUT_SELECT_STEP,
  CHECKOUT_STEP,
  CHECKOUT_SELECT_PRODUCT,
  CHECKOUT_INIT,
} from '../Scripts/store/modules/checkoutContext';
import { getCheckoutProductPackages, formatCheckoutPageModel, getPathnameFromUrl } from '../components/checkout/checkoutUtils';

//TODO do we need productPackage for selecting products since it is not used currently...
const props = defineProps({
  model: Object,
});
const store = useStore();

const step = computed(() => store.state.checkoutContext.step);
const activeTab = computed(() => store.state.checkoutContext.activeTab);
const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);
const hasProvidedAndConfirmedCustomer = computed(() => store.state.checkoutContext.hasProvidedAndConfirmedCustomer);
const productPackageInformation = computed(() => store.state.checkoutContext.productPackageInformation);
const productPackageInformationLoaded = computed(() => store.state.checkoutContext.productPackageInformationLoaded);
const productPackageInformationFailed = computed(() => store.state.checkoutContext.productPackageInformationFailed);
const productPackageInformationPending = computed(() => store.state.checkoutContext.productPackageInformationPending);
const productsModel = reactive({
  products: [],
});
const formatedModel = computed(() => formatCheckoutPageModel(props.model));

const paymentCards = ref([
  { path: '../Static/img/cards/Swish.png', altText: 'Swish Payment Method' },
  { path: '../Static/img/cards/VisaBackground.png', altText: 'Visa Payment Card' },
  { path: '../Static/img/cards/MastercardBackground.png', altText: 'Mastercard Payment Card' },
  { path: '../Static/img/cards/NetsBackground.png', altText: 'Nets Payment Card' },
]);

const updateProductsModel = () => {
  if (productPackageInformationLoaded.value) {
    productsModel.products = getCheckoutProductPackages({ model: props.model, productPackageInformation: productPackageInformation.value });
  }
}

onMounted(() => {
  document.title = formatedModel.value.name || "Checkout";
  getProductPackages();
  updateProductsModel();
  if (step.value === CHECKOUT_STEP.COMPLETE) {
    if (productsModel.products?.length === 1) {
      store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION });
    } else {
      store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.SELECT_PRODUCT });
    }
  }
});

watch(step, () => {
  nextTick().then(() => {
    window.scrollTo(0, 0);
  });
});

watch(productPackageInformationLoaded, () => {
  if (productPackageInformationLoaded.value) {
    updateProductsModel();
    store.dispatch(CHECKOUT_SELECT_PRODUCT, { product: productsModel.products[0] });
    if (productsModel.products?.length === 1) {
      store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION });
    }
    else {
      store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.SELECT_STEP });
    }
  }
});

const getProductPackages = () => {
  store.dispatch(CHECKOUT_INIT, {
    productPackageId: props.model.productPackage.value,
    relatedProductsIds: props.model.relatedProducts?.value ? props.model.relatedProducts.expandedValue.map(({ productPackage }) => productPackage.value) : [props.model.productPackage.value], // todo send empty
    relatedProducts: props.model.relatedProducts?.value ? props.model.relatedProducts.expandedValue.map(({ productPackage, contentLink }) => ({ id: productPackage.value, url: getPathnameFromUrl(contentLink.url) })) : [{ id: props.model.productPackage.value, url: getPathnameFromUrl(props.model.contentLink.url) }], // todo send empty
  });
};
</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_mixins.scss";
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";

.checkout-page {
  background-color: white;
}
</style>