<template>
  <NdsAccordion :accordions="csnAccordions">
    <template v-for="accordion in csnAccordions" #[accordion.id] :key="accordion.id">
      <NdsList :items="accordion.items" variant="borderless" />
    </template>
  </NdsAccordion>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import { FormatDate, formatToSek } from '../_helpers.js';
import { NdsAccordion, NdsList } from '@nds/vue';

const props = defineProps({
  ctiCsnLoan: Object,
});

const ctiCsnLoan = props.ctiCsnLoan;

const csnAccordions = computed(() => {
  if (!ctiCsnLoan?.csnLoanElement || ctiCsnLoan.csnLoanElement.length === 0) return [];

  const formattedCsnAccordionData = (id, title, loanType) => {
    const firstElement = ctiCsnLoan.csnLoanElement[0]?.[loanType];
    const repaymentElement = ctiCsnLoan.csnLoanElement[1]?.[loanType];
    return {
      id,
      title,
      subtitle: firstElement?.creditAmount != null ? formatToSek(firstElement.creditAmount) : "-",
      items: [
        repaymentElement && {
          id: `repayment-period`,
          title: "Återbetalningstid",
          subtitle: repaymentElement.repaymentPeriod === 0 ? "-" : `${repaymentElement.repaymentPeriod} år`
        },
        ...ctiCsnLoan.csnLoanElement.map((loanElement, index) => ({
          id: `loan-element-${index + 1}`,
          title: loanElement?.date?.timeStamp?.value
            ? FormatDate(loanElement.date.timeStamp.value)
            : "-",
          subtitle: loanElement?.[loanType]?.creditAmount != null
            ? formatToSek(loanElement[loanType].creditAmount)
            : "-"
        }))
      ].filter(Boolean)
    };
  };

  return [
    ctiCsnLoan.hasInstallmentLoan &&
    formattedCsnAccordionData("1", "Annuitetslån", "installmentLoan"),
    ctiCsnLoan.hasInstallmentLoan2022 &&
    formattedCsnAccordionData("2", "Annuitetslån 2022", "installmentLoan2022"),
    ctiCsnLoan.hasStudentLoan &&
    formattedCsnAccordionData("3", "Studielån", "studentLoan"),
    ctiCsnLoan.hasStudentMeans &&
    formattedCsnAccordionData("4", "Studiemedelslån", "studentMeans")
  ].filter(Boolean);
});

</script>