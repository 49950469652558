const hasNoEntries = (entries) => entries.map(({ value }) => !value).every(Boolean);
const sortSummaryByTimestamp = (summary) => (summary.sort((a, b) => a.date.timeStamp.value - b.date.timeStamp.value));
const formatChartDataReturnValue = (entries) => {
    const isEmpty = hasNoEntries(entries);
    return { entries: isEmpty ? [] : entries, isEmpty: isEmpty };
}
const emptyEntriesReturnValue = { entries: [], isEmpty: true };

export const getCreditChartData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditOnAccountCredits,
            total: chartData.limitAccountCredits,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getMortgagesChartData = (creditReport) => {
    if (creditReport?.ctiMortgagesSummary?.mortgagesSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiMortgagesSummary.mortgagesSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditPropertyMortgage + chartData.usedCreditTenantOwnershipMortgage,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getHirePurchaseData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditHirePurchase,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getUnsecuredLoansChartData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditUnsecured,
        })));
    }
    return emptyEntriesReturnValue;
}

const sortHistoricalDebtDetailsByTimestamp = (summary) => (summary.sort((a, b) => a.debtDate.timeStamp.value - b.debtDate.timeStamp.value));

export const getHistoricalDebtDetailsChartData = (creditReport) => {
    if (creditReport?.ctiConsumerPaymentInformation?.historicalDebtDetails) {
        return formatChartDataReturnValue(sortHistoricalDebtDetailsByTimestamp(creditReport?.ctiConsumerPaymentInformation?.historicalDebtDetails).map((chartData) => ({
            timestamp: chartData.debtDate.timeStamp.value,
            value: chartData.publicClaims + chartData.privateClaims,
        })));
    }
    return emptyEntriesReturnValue;
}