import axios from 'axios';

const post = ({ url, data, headers, responseType }) => {
    return axios({
        method: 'post',
        url,
        data,
        headers,
        responseType
    });
};

const basePath = '/mina-tjanster';
const printPdfPath = `${basePath}/print-pdf`

export const postPrintPdf = ({
    filter: {
        generalInfo,
        remarks,
        inquiries,
        credits,
        incomeStatement,
        consumerProperties
    },
    csrfToken }) => {
    const formData = new FormData();
    formData.append('__RequestVerificationToken', csrfToken);
    formData.append('generalInfo', generalInfo);
    formData.append('remarks', remarks);
    formData.append('inquiries', inquiries);
    formData.append('credits', credits);
    formData.append('incomeStatement', incomeStatement);
    formData.append('consumerProperties', consumerProperties);
    return post({
        url: printPdfPath,
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/x-www-form-urlencoded',            
        },
        data: formData,
        responseType: 'blob',
    })
};
