<template>
  <div id="remarks-kfm-table">
    <template v-if="complaints.consumerPaymentDebtWithSESData.debtDate">
      <div class="mb-4">
        <NdsHeading title="Aktuell skuld rapporterad från Kronofogdemyndigheten (KFM)" type="h4" tight />
        <div class="d-flex align-items-center mb-2">
          <span>Datum:</span>
          <span class="ml-auto">
            {{ FormatDate(complaints.consumerPaymentDebtWithSESData.debtDate.timeStamp.value) }}
          </span>
        </div>
        <div class="d-flex align-items-center mb-2">
          <span>
            Allmänna mål ({{ complaints.consumerPaymentDebtWithSESData.numberOfPublicClaims }} st):
          </span>
          <span class="font-weight-medium ml-auto">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.publicClaims) }} kr
          </span>
        </div>
        <div class="d-flex align-items-center mb-0">
          <span>
            Enskilda mål ({{ complaints.consumerPaymentDebtWithSESData.numberOfPrivateClaims }} st):
          </span>
          <span class="font-weight-medium ml-auto">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.privateClaims) }} kr
          </span>
        </div>
        <hr>
        <div class="d-flex align-items-center">
          <span class="mb-2 font-weight-medium">
            Antal mål totalt:
          </span>
          <span class="mb-2 font-weight-medium ml-auto">
            {{ complaints.consumerPaymentDebtWithSESData.sumOfClaims }} st
          </span>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-weight-medium color-ocean">
            Belopp total:
          </span>
          <span class="font-weight-medium ml-auto color-ocean">
            {{ NumberFormat(complaints.consumerPaymentDebtWithSESData.sumClaims) }} kr
          </span>
        </div>
        <div v-if="complaints.historicalDebtDetails.length > 0 && complaints.historicalDebtDetails[0].sumDebtText"
          class="align-items-center">
          <p v-html="complaints.historicalDebtDetails[0].sumDebtText" />
          <p>Beloppen ovan avser oreglerade skulder hos kronofogden. Observera att dessa skulder ibland omfattar
            fordringar som även finns redovisade som betalningsanmärkningar.</p>
        </div>
      </div>
    </template>
    <slot/>
    <template v-if="paginatedHistoricalDebtDetails.length > 0">
      <NdsHeading title="Historiskt skuldsaldo rapporterat från Kronofogdemyndigheten (KFM)" type="h4" tight />
      <NdsText type="p" tight text="Skuld hos kronofogden de senaste 24 månaderna. Beloppen i kr visar det högsta beloppet som rapporterats till UC
        under månaden. För period som är utelämnad, saknar UC kännedom om skuldsaldot." />

      <NdsAccordion v-model="activeAccordionId" :accordions="paginatedHistoricalDebtDetails">
        <template v-for="(complaint, index) in paginatedHistoricalDebtDetails" #[complaint.id] :key="index">
          <div class="d-flex flex-column p-3">
            <span class="d-flex mb-1 align-items-center">
              <span class="mr-auto">Allmänna mål:</span>
              <span class="text-right">{{ complaint.publicClaims }} kr</span>
            </span>

            <span class="d-flex mb-3 align-items-center">
              <span class="mr-auto">Enskilda mål:</span>
              <span class="text-right">{{ complaint.privateClaims }} kr</span>
            </span>

            <span class="d-flex mb-1 align-items-center">
              <span class="mr-auto">Antal mål:</span>
              <span class="text-right">{{ complaint.sumClaims }} st</span>
            </span>

            <span class="d-flex align-items-center primary-text">
              <span class="mr-auto">Belopp total:</span>
              <span class="text-right">{{ complaint.totalClaims }} kr</span>
            </span>
          </div>
        </template>
      </NdsAccordion>

      <div v-if="totalPages > 1" class="mt-4">
        <NdsPagination :length="totalPages" v-model="currentPage" />
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { FormatDate, NumberFormat, formatToSek } from '../_helpers.js';
import { NdsAccordion, NdsPagination, NdsHeading, NdsText, getLocalizedMonthAndYear, Locale, usePagination } from "@nds/vue";

const props = defineProps({
  complaints: Object,
});

const formatDateSwedishMonthYear = (timestamp) => {
  const { month, year } = getLocalizedMonthAndYear({
    timestamp,
    locale: Locale.sv_SE,
    monthOptions: { month: "long" },
  });

  const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

  return `${capitalizedMonth} ${year}`;
}

const getTotalClaimsOfComplaint = (complaint) => {
  return complaint.privateClaims + complaint.publicClaims;
};

const historicalDebtDetails = computed(() => {
  const details = props.complaints.historicalDebtDetails || [];
  return details.slice().reverse();
});

const formattedHistoricalDebtDetails = computed(() => historicalDebtDetails.value.map((complaint, index) => ({
  id: `Complaint ${index + 1}`,
  title: formatDateSwedishMonthYear(complaint.debtDate.timeStamp.value),
  subtitle: `Belopp total: ${formatToSek(getTotalClaimsOfComplaint(complaint))}`,
  publicClaims: NumberFormat(complaint.publicClaims),
  privateClaims: NumberFormat(complaint.privateClaims),
  sumClaims: complaint.sumClaims,
  totalClaims: NumberFormat(getTotalClaimsOfComplaint(complaint)),
})));

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedHistoricalDebtDetails,
} = usePagination(formattedHistoricalDebtDetails);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/variables.scss";

.primary-text {
  color: $ocean;
}
</style>
