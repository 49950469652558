<template>
  <VThemeProvider theme="enento" with-background>
    <NotificationsBanner
      v-if="loggedInNotificationMessage?.showNotifications && isLoggedin && showLoggedinNavigation && !isCascadeMenuOpen"
      :model="loggedInNotificationMessage" />
    <NotificationsBanner
      v-else-if="notificationMessage?.showNotifications && !isLoggedin && !showLoggedinNavigation && !isCascadeMenuOpen"
      :model="notificationMessage" />
    <SiteHeader :title="pageMeta.title" :iconName="pageMeta.iconName" :prefix="pageMeta.prefix" />
    <div id="top-nav-app" class="container-fluid site-header position-relative pl-0">
      <CascadeMenu />
    </div>
    <div class="site-content">
      <div v-if="showLoggedinNavigation && isLoggedin" class="container-xl">
        <div class="row dashboard__wrapper d-flex">
          <div class="col-md-4 col-lg-3 sidebar-container">
            <DashboardSideBar />
          </div>
          <PageTemplate :showTabs="pageMeta.hasTabs" />
        </div>
      </div>
      <div v-else>
        <RouterView />
      </div>
    </div>
    <VerifyAccountModal v-if="isLoggedin && isOnMyServicesOrSettings" />
  </VThemeProvider>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { VThemeProvider } from 'vuetify/components/VThemeProvider';
import PageTemplate from '../templates/PageTemplate.vue';
import CascadeMenu from '../CascadeMenu.vue';
import VerifyAccountModal from '../modals/VerifyAccountModal.vue';
import NotificationsBanner from '../NotificationsBanner.vue';
import SiteHeader from '../SiteHeader.vue';
import DashboardSideBar from '../DashboardSideBar.vue';
import { CUSTOMER_INFO_FETCH } from '../../Scripts/store/modules/customerInfoContex';

const store = useStore();
const route = useRoute();

const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const showLoggedinNavigation = computed(() => store.state.appContext.showLoggedinNavigation);
const isOnMyServicesOrSettings = computed(() => store.state.appContext.isOnMyServicesOrSettings);
const loggedInNotificationMessage = computed(() => store.state.appContext.menu?.notificationsMessages?.loggedInNotificationMessage);
const notificationMessage = computed(() => store.state.appContext.menu?.notificationsMessages?.notificationMessage);
const isCascadeMenuOpen = computed(() => store.state.appContext.isCascadeMenuOpen);


const pageMeta = computed(() => {
  if (route.fullPath.includes('/kreditkollen')) {
    return {
      title: 'Kreditkollen',
      iconName: 'analytics',
      prefix: 'fas',
      hasTabs: true,
    };
  }
  if (route.fullPath.includes('/id-skydd')) {
    return {
      title: 'ID-Skydd',
      iconName: 'user-shield',
      prefix: 'far',
      hasTabs: false,
    };
  }
  if (route.fullPath.includes('/min-upplysning')) {
    return {
      title: 'Min upplysning',
      iconName: 'lightbulb',
      prefix: 'far',
      hasTabs: false,
    };
  }
  if (route.fullPath.includes('/mitt-kreditbetyg')) {
    return {
      title: 'Mitt kreditbetyg',
      iconName: 'tachometer',
      prefix: 'far',
      hasTabs: false,
    };
  }
  return {
    title: '',
    iconName: '',
    prefix: '',
    hasTabs: false,
  };
});


onMounted(() => {
  if (store.state.appContext.isLoggedin) {
    if (!store.state.customerInfoContex.loaded) {
      store.dispatch(CUSTOMER_INFO_FETCH, { reFetch: false });
    }
  }
});

</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.sidebar-container {
  background: $night-rider;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }
}
</style>