<template>
  <VThemeProvider theme="enento" with-background>
    <SiteHeader />
    <div id="top-nav-app" class="container-fluid site-header position-relative pl-0">
      <CascadeMenu />
    </div>
    <NotificationsBanner v-if="notificationMessage?.showNotifications && !isCascadeMenuOpen" :model="notificationMessage" />
  </VThemeProvider>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { VThemeProvider } from 'vuetify/components/VThemeProvider';
import CascadeMenu from '../CascadeMenu.vue';
import NotificationsBanner from '../NotificationsBanner.vue';
import SiteHeader from '../SiteHeader.vue';

const store = useStore();
const notificationMessage = computed(() => store.state.appContext.menu?.notificationsMessages?.notificationMessage);
const isCascadeMenuOpen = computed(() => store.state.appContext.isCascadeMenuOpen);

</script>