<template>
  <div class="dashboard" data-testid="dashboard-page">
    <div class="DashboardPage row" scoped>
      <div class="col-12" data-testid="heading-welcome">
        <NdsHeading :title="`Välkommen ${customerEssentials.customerFullName}`" type="h2" />
      </div>
      <div class="col-lg-6 col-12">
        <div v-if="customerEssentials.displayIdProtectionProduct ||
          customerEssentials.displayCreditWatchProduct ||
          customerEssentials.displayDigitalMailbox" class="mb-3">
          <NdsCard :heading="{ title: 'Händelser', type: 'h3' }" tight>
            <template #default>
              <div class="eventlist p-0">
                <ul class="mb-0">
                  <RouterLink v-if="customerEssentials.displayIdProtectionProduct" to="/mina-tjanster/id-skydd"
                    :class="customerEssentials.newEventsCount > 0 ? 'not-read' : ''"
                    class="eventlist__item input-group-text position-relative border-0">
                    <li>
                      <i class="far fa-user-shield fa-fw" />
                      <span class="position-relative">ID-Skydd:
                        <span v-if="customerEssentials.newEventsCount > 0" class="font-weight-medium">{{
                          customerEssentials.newEventsCount }} nya händelser</span>
                        <span v-else class="font-weight-medium">0 nya händelser</span>
                      </span>
                      <i class="far fa-chevron-right" />
                    </li>
                  </RouterLink>
                  <RouterLink v-if="customerEssentials.displayCreditWatchProduct"
                    to="/mina-tjanster/kreditkollen/krediter"
                    :class="customerCreditNotifications.length > 0 ? 'not-read' : ''"
                    class="eventlist__item input-group-text position-relative border-0">
                    <li>
                      <i class="fas fa-analytics fa-fw" />
                      <span class="position-relative">Kreditkollen:
                        <span v-if="customerCreditNotifications.length > 0" class="font-weight-medium">{{
                          customerCreditNotifications.length }} nya händelser</span>
                        <span v-else class="font-weight-medium">0 nya händelser</span>
                      </span>
                      <i class="far fa-chevron-right" />
                    </li>
                  </RouterLink>
                  <RouterLink v-if="customerEssentials.displayDigitalMailbox" to="/mina-tjanster/digital-brevlada"
                    :class="customerEssentials.numberOfNewItems > 0 ? 'not-read' : ''"
                    class="eventlist__item input-group-text position-relative border-0">
                    <li>
                      <i class="far fa-envelope fa-fw" /><span class="position-relative">Digital Brevlåda:<span
                          class="font-weight-medium">{{ customerEssentials.numberOfNewItems }} nya händelser</span>
                      </span> <i class="far fa-chevron-right" />
                    </li>
                  </RouterLink>
                </ul>
              </div>
            </template>
          </NdsCard>
        </div>
        <div class="mb-3">
          <NdsCard :heading="{ title: 'Mina Tjänster', type: 'h3' }" tight>
            <template #controls>
              <RouterLink to="/mina-installningar#payments-tab" v-slot="{ href, navigate, isActive }" custom>
                <NdsButton :href="href" :active="isActive" size="small" variant="text" width="full" @click="navigate">
                  Gå till betalningar
                </NdsButton>
              </RouterLink>
            </template>
            <template #default>
              <div class="row mx-0 my-2" data-testid="dashboard-product-link-ball-cards">
                <div class="col-6 d-flex justify-content-center ">
                  <div v-if="customerEssentials.displayIdProtectionProduct"
                    class="product__circle position-relative d-flex justify-content-center align-items-center"
                    data-testid="dashboard-ball-bought-idskydd-button">
                    <div class="items">
                      <span><i class="far fa-user-shield fa-2x" /></span>
                      <RouterLink to="/mina-tjanster/id-skydd" v-slot="{ href, navigate, isActive }" custom>
                        <NdsButton :href="href" :active="isActive" theme="minuc" size="small" variant="text"
                          @click="navigate">
                          ID-Skydd
                        </NdsButton>
                      </RouterLink>
                    </div>
                  </div>
                  <div v-else
                    class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                    data-testid="dashboard-ball-not-bought-idskydd-button">
                    <div class="items">
                      <span><i class="far fa-user-shield fa-2x" /></span>
                      <NdsButton theme="minuc" size="small" variant="text" disabled>ID-Skydd</NdsButton>
                    </div>
                    <NdsButton href="/id-skydd" theme="minuc" size="small" variant="primary"
                      class="position-absolute more-info">Läs mer</NdsButton>
                  </div>
                </div>
                <div class="col-6 d-flex justify-content-center ">
                  <div v-if="customerEssentials.displayCreditWatchProduct"
                    class="product__circle position-relative d-flex justify-content-center align-items-center"
                    data-testid="dashboard-ball-bought-kreditkollen-button">
                    <div class="items">
                      <span><i class="fas fa-analytics fa-fw fa-2x" /></span>
                      <RouterLink to="/mina-tjanster/kreditkollen/krediter" v-slot="{ href, navigate, isActive }"
                        custom>
                        <NdsButton :href="href" :active="isActive" theme="minuc" size="small" variant="text"
                          @click="navigate">
                          Kreditkollen
                        </NdsButton>
                      </RouterLink>
                    </div>
                  </div>
                  <div v-else
                    class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                    data-testid="dashboard-ball-not-bought-kreditkollen-button">
                    <div class="items">
                      <span><i class="fas fa-analytics fa-fw fa-2x" /></span>
                      <NdsButton theme="minuc" size="small" variant="text" disabled>Kreditkollen</NdsButton>
                    </div>
                    <NdsButton href="/kreditkollen" theme="minuc" size="small" variant="primary"
                      class="position-absolute more-info">Läs mer</NdsButton>
                  </div>
                </div>
                <div class="col-6 pt-4 pt-md-4 d-flex justify-content-center">
                  <div v-if="customerEssentials.displayCustomerReportProduct"
                    class="product__circle position-relative d-flex justify-content-center align-items-center"
                    data-testid="dashboard-ball-bought-min-upplysning-button">
                    <div
                      v-if="customerEssentials.purchasedProductPackages !== null && !customerEssentials.displayCreditWatchProduct"
                      class="daysLeftLabel position-absolute color-white text-center">
                      <span class="body-xsmall" data-testid="days-left-text">
                        {{ getEndDateForProduct({
                          productName: 'Min Upplysning',
                          purchasedProductPackages: customerEssentials.purchasedProductPackages
                        }) }}
                        dagar
                      </span>
                    </div>
                    <div class="items">
                      <span><i class="far fa-lightbulb fa-fw fa-2x" /></span>
                      <RouterLink to="/mina-tjanster/min-upplysning" v-slot="{ href, navigate, isActive }" custom>
                        <NdsButton :href="href" :active="isActive" theme="minuc" size="small" variant="text"
                          @click="navigate">
                          Min Upplysning
                        </NdsButton>
                      </RouterLink>
                    </div>
                  </div>
                  <div v-else
                    class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                    data-testid="dashboard-ball-not-bought-min-upplysning-button">
                    <div class="items">
                      <span><i class="far fa-lightbulb fa-fw fa-2x" /></span>
                      <NdsButton theme="minuc" size="small" variant="text" disabled>Min Upplysning</NdsButton>
                    </div>
                    <NdsButton href="/min-upplysning" theme="minuc" size="small" variant="primary"
                      class="position-absolute more-info">Läs mer</NdsButton>
                  </div>
                </div>
                <div class="col-6 pt-4 pt-md-4 d-flex justify-content-center">
                  <div v-if="customerEssentials.displayCreditScoreProduct"
                    class="product__circle position-relative d-flex justify-content-center align-items-center"
                    data-testid="dashboard-ball-bought-mitt-kreditbetyg-button">
                    <div class="items">
                      <span><i class="far fa-tachometer fa-fw fa-2x" /></span>
                      <RouterLink to="/mina-tjanster/mitt-kreditbetyg" v-slot="{ href, navigate, isActive }" custom>
                        <NdsButton :href="href" :active="isActive" theme="minuc" size="small" variant="text"
                          @click="navigate">
                          Mitt Kreditbetyg
                        </NdsButton>
                      </RouterLink>
                    </div>
                  </div>
                  <div v-else
                    class="product__circle position-relative d-flex justify-content-center align-items-center disabled"
                    data-testid="dashboard-ball-not-bought-mitt-kreditbetyg-button">
                    <div class="items">
                      <span><i class="far fa-tachometer fa-fw fa-2x" /></span>
                      <NdsButton theme="minuc" size="small" variant="text" disabled>Mitt Kreditbetyg</NdsButton>
                    </div>
                    <NdsButton href="/mitt-kreditbetyg" theme="minuc" size="small" variant="primary"
                      class="position-absolute more-info">Läs mer</NdsButton>
                  </div>
                </div>
              </div>
              <div class="col p-0 no-gutters row-sm">
                <div class="col-12 mb-2">
                  <NdsHeading title="Gratistjänster" type="h4" class="pb-0" />
                  <RouterLink to="/mina-tjanster/bedragerisparr" v-slot="{ href, navigate, isActive }" custom>
                    <NdsButton :href="href" :active="isActive" variant="text"
                      data-testid="dashboard-gratistjanster-bedragerisparr-btn"
                      :prefix="{ prefix: 'far', iconName: 'shield-alt' }" @click="navigate">
                      Bedrägerispärr
                    </NdsButton>
                  </RouterLink>
                  <div v-if="customerEssentials.displayDigitalMailboxRegistered">
                    <RouterLink to="/mina-tjanster/digital-brevlada" v-slot="{ href, navigate, isActive }" custom>
                      <NdsButton :href="href" :active="isActive" variant="text"
                        data-testid="dashboard-gratistjanster-digital-brevlada-btn"
                        :prefix="{ prefix: 'far', iconName: 'envelope' }" @click="navigate">
                        Digital Brevlåda
                      </NdsButton>
                    </RouterLink>
                  </div>
                  <div v-else>
                    <NdsButton href="/digital-brevlada" variant="text" :prefix="{ prefix: 'far', iconName: 'envelope' }"
                      data-testid="dashboard-gratistjanster-digital-brevlada-btn">
                      Registrera Digital Brevlåda
                    </NdsButton>
                  </div>
                </div>
                <div class="col-12 mb-2">
                  <NdsHeading title="Engångsköp" type="h4" class="pb-0" />
                  <NdsButton href="/forlustanmalan" variant="text"
                    :prefix="{ prefix: 'far', iconName: 'exclamation-circle' }"
                    data-testid="dashboard-one-time-purchase-loss-notification">
                    Förlustanmälan
                  </NdsButton>
                </div>
              </div>
            </template>
          </NdsCard>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <NdsCardSpacing :gap="3" direction="column" center>
          <NdsCard v-if="customerEssentials.displayIdProtectionProduct"
            :heading="{ title: 'UC Bevakning', type: 'h3', prefix: { prefix: 'far', iconName: 'eye' } }">
            <template #controls>
              <RouterLink to="/mina-tjanster/id-skydd" v-slot="{ href, navigate, isActive }" custom>
                <NdsButton :href="href" :active="isActive" size="small" variant="text" width="full" @click="navigate">
                  Gå till ID-Skydd
                </NdsButton>
              </RouterLink>
            </template>
            <template #default>
              <CustomerEvents :items-per-page="5" :paging-enabled="false" :search-enabled="false" />
              <div v-if="customerEvents !== null && customerEvents.length > 0" class="mt-3 text-center">
                <RouterLink to="/mina-tjanster/id-skydd?all=true" class="btn btn-enento-text">
                  Visa alla händelser
                </RouterLink>
              </div>
            </template>
          </NdsCard>
          <NdsCard v-if="customerEssentials.displayCreditWatchProduct"
            :heading="{ title: 'UC Score', type: 'h3', prefix: { prefix: 'fas', iconName: 'analytics' } }"
            variant="primary" :backgroundImageSrc="creditWatchBackgroundImageLoggedInSrc">
            <template #controls>
              <RouterLink to="/mina-tjanster/kreditkollen/krediter" v-slot="{ href, navigate, isActive }" custom>
                <NdsButton :href="href" :active="isActive" size="small" variant="text" onBg="dark" width="full"
                  @click="navigate">
                  Gå till Kreditkollen
                </NdsButton>
              </RouterLink>
            </template>
            <template #default>
              <UcScore v-if="showUcScore" :marker-radius="15" :height="260" :report="customerCreditInfo" />
              <div v-else class="uc-score-not-loaded">
                <RouterLink v-slot="{ href, navigate, isActive }" :to="creditWatchRelativeUrl" custom>
                  <NdsButton :href="href" :active="isActive" variant="secondary" onBg="dark" size="large" width="full"
                    class="uc-score-link" @click="navigate">
                    {{ props?.model?.lockedCreditWatch?.linkTextLoggedIn?.value }}
                  </NdsButton>
                </RouterLink>
              </div>
            </template>
          </NdsCard>
          <template
            v-if="!customerEssentials.displayIdProtectionProduct && !customerEssentials.displayCreditWatchProduct">
            <ProductLockedBlock v-if="!customerEssentials.displayCreditWatchProduct" :model="model.lockedCreditWatch"
              :prefix="{ prefix: 'fas', iconName: 'analytics' }" />
            <ProductLockedBlock v-if="!customerEssentials.displayIdProtectionProduct" :model="model.lockedIdProtection"
              :prefix="{ prefix: 'far', iconName: 'user-shield' }" />
          </template>
          <template v-else>
            <ProductLockedBlock v-if="!customerEssentials.displayIdProtectionProduct" :model="model.lockedIdProtection"
              :prefix="{ prefix: 'far', iconName: 'user-shield' }" />
            <ProductLockedBlock v-if="!customerEssentials.displayCreditWatchProduct" :model="model.lockedCreditWatch"
              :prefix="{ prefix: 'fas', iconName: 'analytics' }" />
          </template>
        </NdsCardSpacing>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="onCloseErrorModal"
    @onCancel="onCloseErrorModal" />
</template>
<script setup>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { NdsButton, NdsConfirmModal, NdsCard, NdsHeading, NdsCardSpacing, useConfirmModal } from '@nds/vue';
import UcScore from '../components/charts/UcScore.vue';
import ProductLockedBlock from '../components/ProductLockedBlock.vue';
import CustomerEvents from '../components/CustomerEvents.vue';
import { getEndDateForProduct } from '../utils/productUtils';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH } from '../Scripts/store/modules/customerCreditNotificationsContex';
import { getPathnameFromUrl } from '../components/checkout/checkoutUtils';
import { CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL } from '../Scripts/store/modules/customerCreditInfoContex';
import { creditReportError } from '../components/modals/modalsUtils';

const props = defineProps(['model']);
const store = useStore();

const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerEvents = computed(() => store.state.customerInfoContex.customerEvents);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const showErrorModal = computed(() => store.state.customerCreditInfoContex.showErrorModal);
const customerCreditNotifications = computed(() => store.state.customerCreditNotificationsContex.customerCreditNotifications);

const creditWatchRelativeUrl = computed(() => props.model.lockedCreditWatch?.linkLoggedIn?.value?.url ? getPathnameFromUrl(props.model.lockedCreditWatch?.linkLoggedIn?.value?.url) : '');
const showUcScore = computed(() => customerCreditInfo.value.creditReport?.creditwatchScoreList && store.state.customerCreditInfoContex.loaded && !store.state.customerCreditInfoContex.failed);
const creditWatchBackgroundImageLoggedInSrc = computed(() => props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url && !showUcScore.value ? props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url : '');

watch(showErrorModal, (value) => {
  if (value) {
    errorModal.openModal();
  }
})

const errorModal = useConfirmModal(creditReportError);
const onCloseErrorModal = () => {
  errorModal.closeModal();
  store.dispatch(CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL);
}

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH);
  if (store.state.customerCreditInfoContex.showErrorModal) {
    errorModal.openModal();
  }
});
</script>
<style scoped lang="scss">
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";
@import "../../../styles/abstracts/_mixins.scss";

.daysLeftLabel {
  background-color: $gray;
  border-radius: 6px;
  padding: 0 5px;
  top: 20px;
  right: -15px;
}

.product__circle {
  min-width: 146px;
  min-height: 146px;
  text-align: center;
  border-radius: 50%;
  background-color: $beach;
  border: 2px solid $rodeo-dust;

  .items {
    display: flex;
    flex-direction: column;
  }

  .more-info {
    bottom: 8px;
  }

  &.disabled {
    background-color: $gallery;

    i {
      color: $silver;
    }
  }

  span {
    display: block;
    width: 100%;
    text-align: center;

    i {
      color: $orangegold;
    }
  }
}

.uc-score-not-loaded {
  min-height: 228px;
  position: relative;

  .uc-score-link {
    position: absolute;
    bottom: 0;
  }
}

.eventlist {
  ul {
    list-style-type: none;
    padding-left: 0;

    a {
      background-color: transparent;
      color: $black;
      padding: 0.75rem;

      @include attention() {
        background-color: $light-smoke;
        text-decoration: none;
      }

      &.not-read {
        background-color: $rodeo-dust;

        @include attention() {
          background-color: $rodeo-dust-hover;
        }
      }

      li {
        span {
          padding: 4px 6px;
        }

        i {
          color: $orangegold;

          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            position: absolute;
            top: calc(50% - 8px);
            right: 16px;
            color: $dark-ocean;
          }
        }
      }
    }
  }
}
</style>
