<template>
  <div id="mycreditscorepage" class="dashboard" data-testid="dashboard-my-credit-score-page">
    <div class="row">
      <div class="col-12 mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate"
            :prefix="{ prefix: 'far', iconName: 'home-alt' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
        <NdsLoader />
      </div>
      <template v-else>
        <div class="col-lg-6">
          <NdsCard :heading="{ title: 'Kreditbetyg', type: 'h3',  prefix: { prefix: 'far', iconName: 'tachometer' } }">
            <template #default>
              <div class="information-container">
                <p v-html="model.description.value" />
                <template v-if="customerCreditInfo.creditReport.creditScore && loaded">
                  <CreditScoreChart :report="customerCreditInfo" />
                  <p v-if="customerCreditInfo.creditReport.creditScore.riskGrade < 0.2"
                    v-html="model.excellentCreditScore.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 1"
                    v-html="model.veryGoodCreditScore.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 4.1"
                    v-html="model.goodCreditScore.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 25.1"
                    v-html="model.lessGoodCreditScore.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade > 25"
                    v-html="model.weakCreditScore.value" />
                </template>
              </div>
            </template>
          </NdsCard>
        </div>
        <div class="col-lg-6 mt-lg-0 mt-3">
          <NdsCard>
            <template #default>
              <div class="information-container">
                <template v-if="customerCreditInfo.creditReport.creditScore && loaded">
                  <p v-if="customerCreditInfo.creditReport.creditScore.riskGrade < 0.2"
                    v-html="model.creditScoreStatisticsUTMARKT.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 1"
                    v-html="model.creditScoreStatisticsMYCKET_BRA.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 4.1"
                    v-html="model.creditScoreStatisticsBRA.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade < 25.1"
                    v-html="model.creditScoreStatisticsMINDRE_BRA.value" />
                  <p v-else-if="customerCreditInfo.creditReport.creditScore.riskGrade > 25"
                    v-html="model.creditScoreStatisticsSVAG.value" />
                </template>
                <p v-else v-html="model.noCreditScoreAvailable.value" />
              </div>
            </template>
          </NdsCard>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { NdsLoader, NdsLink, NdsCard } from '@nds/vue';
import CreditScoreChart from '../components/charts/CreditScoreChart.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';

const props = defineProps({
  model: Object
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const loaded = computed(() => store.state.customerCreditInfoContex.loaded);
const pending = computed(() => store.state.customerCreditInfoContex.pending);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});
</script>
