<template>
  <div data-testid="minupplysning-remarks-tab">
    <NdsHeading title="Anmärkningar" type="h3" :prefix="{ prefix: 'far', iconName: 'comment-exclamation' }" tight />
    <div v-if="introText" v-html="introText" />
    <p
      v-if="report.creditReport.ctiConsumerPaymentInformation && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length > 0">
      Det finns <span class="font-weight-medium" data-testid="min-upplysning-inquiry-noTotal">{{
        report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length
      }} st</span>
      anmärkningar på totalt <span class="font-weight-medium" data-testid="min-upplysning-inquiry-noTotal-amount">
        {{ complaintAmount }}</span>
      registrerade.
    </p>
    <div v-if="report.creditReport.ctiConsumerPaymentInformation != null" class="mb-4">
      <Remarks :show-end-date="false"
        :complaints="report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified" />
    </div>
    <RemarksKfm v-if="report.creditReport.ctiConsumerPaymentInformation != null"
      :complaints="report.creditReport.ctiConsumerPaymentInformation" :model="model" />
    <ZeroState v-else icon="far fa-comment-exclamation" text="Ingen anmärkning registrerad" />
    <NdsFooter class="pt-3" :prefix="{ prefix: 'far', iconName: 'book' }"
      text="Källa: Kronofogden, tingsrätt och kreditgivare" tight />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { NdsHeading, NdsFooter } from '@nds/vue';
import Remarks from '../Remarks.vue';
import RemarksKfm from '../RemarksKfm.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import { formatToSek } from '../../_helpers.js';

const props = defineProps({
  report: Object,
  introText: String,
  model: Object
});

const complaintAmount = computed(() => {
  if (props.report.creditReport?.ctiConsumerPaymentInformation?.historicalComplaintsSpecified) {
    return formatToSek(props.report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.map(({ complaintAmount }) => complaintAmount).reduce((a, b) => a + b, 0))
  }
  return formatToSek(0);
});

</script>