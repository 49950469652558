<template>
  <div data-testid="minupplysning-income-tab">
    <NdsHeading title="Inkomstuppgifter" type="h3" :prefix="{ prefix: 'far', iconName: 'money-bill' }" tight />
    <IncomeAndTaxation v-if="creditReport?.ctiConsumerIncomeAndTaxation"
      :cti-consumer-income-and-taxation="creditReport.ctiConsumerIncomeAndTaxation"
      :sole-trader="creditReport.extendedConsumerInformation?.soleTrader" />
    <ZeroState v-else icon="far fa-money-bill" text="Inga inkomstuppgifter registrerade" />
    <NdsFooter :text="incomeBottomText" tight />
    <NdsFooter class="w-100" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Skatteverket"
      :rightNote="rightNote" tight />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { NdsHeading, NdsFooter } from '@nds/vue';
import { FormatDate } from '../../_helpers.js';
import IncomeAndTaxation from '../../components/incomeAndTaxation/IncomeAndTaxation.vue';
import ZeroState from "../../components/myinformation/ZeroState.vue";

const props = defineProps({
  creditReport: Object,
  incomeBottomText: String,
});

const currentYearIncomeAndTaxationDate = computed(() => {
  if (props.creditReport.ctiConsumerIncomeAndTaxation?.incomeAndTaxation[0].updateDate) {
    return FormatDate(props.creditReport.ctiConsumerIncomeAndTaxation.incomeAndTaxation[0].updateDate.timeStamp.value)
  }
  return false;
});

const rightNote = computed(() => currentYearIncomeAndTaxationDate.value && ({
  text: `Senast uppdaterad: ${currentYearIncomeAndTaxationDate.value}`,
  prefix: {
    prefix: 'far',
    iconName: 'far fa-clock',
  },
}));
</script>