import $ from 'jquery';
// Resize Hero Section height to fit viewport size, [33%, 50%, 75%, 100%]
/*(function ($) {*/
function HeroSection() {
    this.heroSectionBlocks = $('.hero-section');
    this.viewportHeight = $(window).height();
}

HeroSection.defaultOptions = {
    HERO_SECTION_CONTENT_TOP_POSITION: 50 // Unit = %
};

HeroSection.consts = {
    // Resize Hero Section height to fit viewport size, [33%, 50%, 75%, 100%]
    HERO_SECTION_HEIGHT_RATIOS: {
        HEIGHT_33: 33,
        HEIGHT_50: 50,
        HEIGHT_75: 75,
        HEIGHT_100: 100
    }
};

HeroSection.prototype = {
    setHeight: function () {
        var self = this;
        this.heroSectionBlocks
            .map(function (i, heroSection) {
                var viewportHeightRatio = self.getViewportHeightRatio(heroSection);
                var currentHeight = $(heroSection).height();
                var newHeight = self.viewportHeight * viewportHeightRatio;
                var heightRatio = (newHeight / currentHeight);
                var newSectionContentTop = heightRatio * HeroSection.defaultOptions.HERO_SECTION_CONTENT_TOP_POSITION;
                self.adjustSectionContainerToNewHeight(heroSection, newHeight);
                self.adjustSectionContentToNewHeight(heroSection, newHeight, newSectionContentTop);
            })
    },

    //Set new Hero section height
    adjustSectionContainerToNewHeight: function (heroSection, height) {
        $(heroSection).height(height);
    },

    // Adjust Hero Section position to the new height
    adjustSectionContentToNewHeight: function (heroSection, height, top) {
        var heroSectionContentContainer = $(heroSection).find('.hero-section__container');
        var heroSectionContentElementCssClasses = this.getContentElementCssClasses(heroSection);

        if (this.isAlignBottom(heroSectionContentElementCssClasses)) {
            heroSectionContentContainer.css('min-height', height);
        } else if (this.isAlignCenter(heroSectionContentElementCssClasses)) {
            $(heroSection).find('.hero-section__text').css('top', top + '%');
        }
    },

    getContentElementCssClasses: function (heroSectionElement) {
        var heroSectionContent = $(heroSectionElement).find('.hero-section__text');
        var heroSectionContentElement = heroSectionContent[0];

        return heroSectionContentElement.className;
    },

    isAlignBottom: function (cssClasses) {
        return cssClasses.indexOf('vertical-align-bottom') !== -1;
    },

    isAlignCenter: function (cssClasses) {
        return cssClasses.indexOf('vertical-align-middle') !== -1;
    },

    getViewportHeightRatio: function (heroSection) {
        var ratio = 1;
        var cssClasses = $(heroSection).attr('class').trim().split(' ');
        var heroSectionRatios = HeroSection.consts.HERO_SECTION_HEIGHT_RATIOS;

        if (cssClasses.indexOf('height-' + heroSectionRatios.HEIGHT_33) !== -1) {
            ratio = heroSectionRatios.HEIGHT_33 / heroSectionRatios.HEIGHT_100;
        } else if (cssClasses.indexOf('height-' + heroSectionRatios.HEIGHT_50) !== -1) {
            ratio = heroSectionRatios.HEIGHT_50 / heroSectionRatios.HEIGHT_100;
        } else if (cssClasses.indexOf('height-' + heroSectionRatios.HEIGHT_75) !== -1) {
            ratio = heroSectionRatios.HEIGHT_75 / heroSectionRatios.HEIGHT_100;
        }
        return ratio;
    }
};

var heroSection = new HeroSection();
heroSection.setHeight();
