<template>
  <div :class="mobileTabsClasses" data-testid="mobile-menu">
    <template v-if="hasTabs">
      <div class="page-template-tabs">
        <div class="align-with-sidenav" />
        <NdsTabsWithRouter v-model="tabModel" :tabs="pageTabs" @back="onBack" @forward="onForward" onDarkBg tight
          :stickyFromTop="stickyFromTop" testId="page-tabs" />
      </div>
    </template>
    <div v-else-if="modelPending" class="dashboard">
      <NdsShimmer
        :shimmers="[{ type: 'button' }, { type: 'card' }, { type: 'card' }, { type: 'card' }, { type: 'card' }]"
        :gap="3" direction="column" center />
    </div>
    <RouterView v-else />
  </div>
</template>
<script setup>
import { NdsTabsWithRouter, useTabsWithRoutes, NdsShimmer } from '@nds/vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { useSiteBreakpoints } from '../../utils/breakpointsUtils';

const props = defineProps({
  showTabs: Boolean,
});

const store = useStore();
const router = useRouter();
const { isMobile } = useSiteBreakpoints();
const pageTabs = computed(() => {
  if (store.state.appContext.menu.creditWatchTabs.length > 0) {
    return store.state.appContext.menu.creditWatchTabs.map((item, index) => ({
      id: `tab_id_${index}`,
      name: item.Name || `Missing page name: ${index}`,
      route: item.Url || '/',
    }));
  }
  return [];
});
const { tabModel, onBack, onForward } = useTabsWithRoutes({
  tabs: pageTabs,
  defaultTabId: 'tab_id_0',
  router,
});
const modelPending = computed(() => store.state.epiDataModelContext.pending);
const mobileTabsClasses = computed(() => isMobile.value ? 'col-12 px-0' : 'col-md-8 col-lg-9 px-0');
const hasTabs = computed(() => (props.showTabs && pageTabs.value.length > 0));
const stickyFromTop = computed(() => isMobile.value ? 90 : undefined);

</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.page-template-tabs {
  background: $light-smoke;
  min-height: 1000px;

  .align-with-sidenav {
    background: $night-rider;
    height: 16px;

    @include breakpoint(max-tablet-portrait) {
      height: 0px;
    }
  }
}
</style>
