<template>
  <NdsCard :heading="{ title: 'Senaste frågor hos UC', type: 'h3', prefix: { prefix: 'far', iconName: 'comments' } }"
    testId="minupplysning-mobile-inquiries-tab">
    <template #controls>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle" @click="infoModal.openModal"
        aria-label="Senaste frågor hos UC förklaring" />
    </template>
    <template #default>
      <p v-if="report.creditReport.ctiConsumerInquires">
        Antal registrerade förfrågningar hos UC de senaste 12 månaderna: <span class="font-weight-bold"
          data-testid="min-upplysning-inquiries-noTotal">{{ inquiriesAmount }}</span>
      </p>
      <ConsumerInquiries v-if="report.creditReport.ctiConsumerInquires"
        :consumer-inquiries="report.creditReport.ctiConsumerInquires" />
      <template
        v-if="report.creditReport.ctiConsumerInquires && report.creditReport.ctiConsumerInquires.inquiriesMinucOnly && report.creditReport.ctiConsumerInquires.inquiriesMinucOnly.length > 0">
        <div class="d-flex align-items-center px-2 py-3">
          {{ report.creditReport.ctiConsumerInquires.inquiriesMinucOnly.length }} frågor har registrerats hos UC utan
          att frågorna redovisas i upplysningen
        </div>
        <ConsumerInquiriesMinucOnly v-if="report.creditReport.ctiConsumerInquires"
          :consumer-inquiries="report.creditReport.ctiConsumerInquires" />
      </template>
      <ZeroState v-if="!report.creditReport.ctiConsumerInquires" icon="far fa-comments"
        text="Ingen förfrågan registrerad" />
      <NdsFooter class="pt-2" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: UC" tight />
    </template>
  </NdsCard>
  <NdsConfirmModal v-bind="infoModal.props" v-model="infoModal.isOpen.value" @onConfirm="infoModal.closeModal"
    @onCancel="infoModal.closeModal" />
</template>
<script setup>
import { computed } from 'vue';
import { NdsIconButton, NdsCard, NdsConfirmModal, NdsFooter, useConfirmModal } from '@nds/vue';
import { formatToAmount } from '../../_helpers.js';
import ConsumerInquiries from '../../components/ConsumerInquiries.vue';
import ConsumerInquiriesMinucOnly from '../../components/ConsumerInquiriesMinucOnly.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';

const props = defineProps({
  report: Object,
  introText: String
});

const inquiriesAmount = computed(() => {
  if (props.report.creditReport?.ctiConsumerInquires) {
    const today = new Date(); // Get today's date
    const twelveMonthsAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()); // Get the date 12 months ago
    // Filter the inquiries that were made in the last 12 months
    const inquiriesLast12Months = props.report.creditReport.ctiConsumerInquires.inquiries.filter(inquiry => {
      const inquiryDate = new Date(inquiry.dateOfInquiry.timeStamp.value);
      return inquiryDate >= twelveMonthsAgo && inquiryDate <= today;
    });

    // return the count of inquiries made in the last 12 months formated
    return formatToAmount(inquiriesLast12Months.length);
  }
  return formatToAmount(0);
});

const infoModal = useConfirmModal({
  title: 'Senaste frågor hos UC',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.introText,
    isRichText: true,
  },
});

</script>