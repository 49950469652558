<template>
  <div data-testid="minupplysning-inquiries-tab">
    <NdsHeading title="Senaste frågor hos UC" type="h3" :prefix="{ prefix: 'far', iconName: 'comments' }" tight />
    <div v-if="introText" v-html="introText" />
    <p v-if="report.creditReport.ctiConsumerInquires">
      Antal registrerade förfrågningar hos UC de senaste 12
      månaderna: <span class="font-weight-bold" data-testid="min-upplysning-inquiries-noTotal">{{
        inquiriesAmount }}</span>
    </p>

    <ConsumerInquiries v-if="report.creditReport.ctiConsumerInquires"
      :consumer-inquiries="report.creditReport.ctiConsumerInquires" />
    <template
      v-if="report.creditReport.ctiConsumerInquires && report.creditReport.ctiConsumerInquires.inquiriesMinucOnly && report.creditReport.ctiConsumerInquires.inquiriesMinucOnly.length > 0">
      <div class="py-4">
        <p>
          {{ report.creditReport.ctiConsumerInquires.inquiriesMinucOnly.length }} frågor har registrerats hos
          UC utan att frågorna redovisas i upplysningen
        </p>
      </div>
      <ConsumerInquiriesMinucOnly v-if="report.creditReport.ctiConsumerInquires"
        :consumer-inquiries="report.creditReport.ctiConsumerInquires" />
    </template>
    <ZeroState v-if="!report.creditReport.ctiConsumerInquires" icon="far fa-comments"
      text="Ingen förfrågan registrerad" />
    <NdsFooter class="pt-3" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: UC" tight />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { NdsHeading, NdsFooter } from '@nds/vue';
import {
  formatToAmount
} from '../../_helpers.js';
import ConsumerInquiries from '../../components/ConsumerInquiries.vue';
import ConsumerInquiriesMinucOnly from '../../components/ConsumerInquiriesMinucOnly.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';

const props = defineProps({
  report: Object,
  introText: String
});

const inquiriesAmount = computed(() => {
  if (props.report.creditReport?.ctiConsumerInquires) {
    const today = new Date(); // Get today's date
    const twelveMonthsAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()); // Get the date 12 months ago
    // Filter the inquiries that were made in the last 12 months
    const inquiriesLast12Months = props.report.creditReport.ctiConsumerInquires.inquiries.filter(inquiry => {
      const inquiryDate = new Date(inquiry.dateOfInquiry.timeStamp.value);
      return inquiryDate >= twelveMonthsAgo && inquiryDate <= today;
    });

    // return the count of inquiries made in the last 12 months formated
    return formatToAmount(inquiriesLast12Months.length);
  }
  return formatToAmount(0);
});
</script>