import { getMenu } from '../../providers/frontendApiControllerProviders';
import { clearPersistedStorage } from '../storeUtils';

const APP = 'APP';
export const APP_HIDE_MOD = `${APP}/HIDE_MODAL`;
export const APP_SHOW_MODAL = `${APP}/SHOW_MODAL`;
export const APP_HIDE_MODAL = `${APP}/HIDE_MODAL`;
export const APP_SHOW_REPORT = `${APP}/SHOW_REPORT`;
export const APP_HIDE_REPORT = `${APP}/HIDE_REPORT`;
export const APP_SHOW_SIDE_NAVIGATION = `${APP}/SHOW_SIDE_NAVIGATION`;
export const APP_HIDE_SIDE_NAVIGATION = `${APP}/HIDE_SIDE_NAVIGATION`;
export const APP_FETCH_MENU = `${APP}/FETCH_MENU`;
export const APP_IS_LOGGED_IN = `${APP}/IS_LOGGED_IN`;
export const APP_IS_LOGGED_OUT = `${APP}/IS_LOGGED_OUT`;
export const APP_TOGGLE_CASCADE_MENU = `${APP}/TOGGLE_MOBILE_MENU`;
export const APP_CLOSE_CASCADE_MENU = `${APP}/CLOSE_MOBILE_MENU`;
export const APP_IS_ON_MY_SERVICES_OR_SETTINGS = `${APP}/IS_ON_MY_SERVICES_OR_SETTINGS`;
export const APP_IS_NOT_ON_MY_SERVICES_OR_SETTINGS = `${APP}/IS_NOT_ON_MY_SERVICES_OR_SETTINGS`;
const APP_UPDATE = `${APP}/UPDATE`;

const APP_UPDATE_MENU = `${APP}/UPDATE_MENU`;
const APP_UPDATE_MENU_FAILED = `${APP}/UPDATE_MENU_FAILED`;
const APP_UPDATE_MENU_PENDING = `${APP}/UPDATE_MENU_PENDING`;

const defaultMenu = {
    coreMenu: {},
    notificationsMessages: {},
    myServicesMenu: [],
    creditWatchTabs: [],
    verificationModal: {
        confirmContactInformation: {
            cancelLabel: '',
            moreInformationText: '',
            moreInformationTitle: '',
            submitLabel: '', 
            title: '',
        },
        provideContactInformation: {
            cancelLabel: '',
            moreInformationText: '',
            moreInformationTitle: '',
            submitLabel: '',
            title: '',
        },
        enabled: false,
    },
}

const hasExpired = (iat) => {
    const date = new Date(iat);
    const halfHour = 1000 * 60 * 30;
    const halfHourAgo = Date.now() - halfHour;

    return date.valueOf() < halfHourAgo;
};

const state = {
    modalShowing: false,
    menu: defaultMenu,
    menuLoaded: false,
    menuFailed: false,
    menuPending: false,
    isLoggedin: false,
    iat: new Date().toJSON(),
    showLoggedinNavigation: false,
    isInMyServices: false,
    isCascadeMenuOpen: false,
    isOnMyServicesOrSettings: false,
};

const formatMenu = (menu) => menu.myServicesMenu.length > 0 ? ({
    ...menu,
    myServicesMenu: menu.myServicesMenu.map((item) => ({
        category: item.Category || '',
        icon: item.FontAwesomeClass || '',
        iconContainer: item.IconContainerClass || '',
        name: item.Name || '',
        title: item.Title || '',
        url: item.Url || '',
        dataTestId: item.Name.split(' ').join('-').toLowerCase() || '',
    }))
}) : menu;

const mutations = {
    [APP_UPDATE](state, newModel) {
        if (newModel.modalShowing) {
            state.modalShowing = newModel.modalShowing.newValue;
        }
        if (newModel.isLoggedIn) {
            state.isLoggedin = newModel.isLoggedIn.newValue;
        }
        if (newModel.showLoggedinNavigation) {
            state.showLoggedinNavigation = newModel.showLoggedinNavigation.newValue;
        }
        if (newModel.menu) {
            state.menu = formatMenu(newModel.menu.newValue);
            state.menuLoaded = true;
            state.menuFailed = false;
            state.menuPending = false;
        }
        if (newModel.isCascadeMenuOpen) {
            state.isCascadeMenuOpen = newModel.isCascadeMenuOpen.newValue;
        }
        if (newModel.isOnMyServicesOrSettings) {
            state.isOnMyServicesOrSettings = newModel.isOnMyServicesOrSettings.newValue;
        }
    },
    [APP_UPDATE_MENU](state, newModel) {
        state.menu = formatMenu(newModel);
        state.menuLoaded = true;
        state.menuFailed = false;
        state.menuPending = false;
    },
    [APP_UPDATE_MENU_FAILED](state) {
        state.menu = defaultMenu;
        state.menuLoaded = true;
        state.menuFailed = true;
        state.menuPending = false;
    },
    [APP_UPDATE_MENU_PENDING](state) {
        state.menu = defaultMenu;
        state.menuLoaded = false;
        state.menuFailed = false;
        state.menuPending = true;
    },
};

const actions = {
    [APP_FETCH_MENU]({ commit }) {
        commit(APP_UPDATE_MENU_PENDING);
        return getMenu().then(response => {
            commit(APP_UPDATE_MENU, response.data);
        }).catch(() => {
            commit(APP_UPDATE_MENU_FAILED);
        });
    },
    [APP_SHOW_MODAL]({ commit }) {
        return commit(APP_UPDATE, { modalShowing: { newValue: true } });
    },
    [APP_HIDE_MODAL]({ commit }) {
        return commit(APP_UPDATE, { modalShowing: { newValue: false } });
    },
    [APP_SHOW_SIDE_NAVIGATION]({ commit }) {
        return commit(APP_UPDATE, { showLoggedinNavigation: { newValue: true } });
    },
    [APP_HIDE_SIDE_NAVIGATION]({ commit }) {
        return commit(APP_UPDATE, { showLoggedinNavigation: { newValue: false } });
    },
    [APP_TOGGLE_CASCADE_MENU]({ commit, state }) {
        return commit(APP_UPDATE, { isCascadeMenuOpen: { newValue: !state.isCascadeMenuOpen } });
    },
    [APP_CLOSE_CASCADE_MENU]({ commit }) {
        return commit(APP_UPDATE, { isCascadeMenuOpen: { newValue: false } });
    },
    [APP_IS_ON_MY_SERVICES_OR_SETTINGS]({ commit }) {
        return commit(APP_UPDATE, { isOnMyServicesOrSettings: { newValue: true } });
    },
    [APP_IS_NOT_ON_MY_SERVICES_OR_SETTINGS]({ commit }) {
        return commit(APP_UPDATE, { isOnMyServicesOrSettings: { newValue: false } });
    },
    [APP_IS_LOGGED_IN]({ commit, state, dispatch }, { serversideRenderedMenues }) {
        if (hasExpired(state.iat) || state.isLoggedin === false) {
            clearPersistedStorage();
        }
        if (!serversideRenderedMenues) {
            dispatch(APP_FETCH_MENU);
            return commit(APP_UPDATE, { isLoggedIn: { newValue: true } });
        }
        return commit(APP_UPDATE, { isLoggedIn: { newValue: true }, menu: { newValue: serversideRenderedMenues } });
    },
    [APP_IS_LOGGED_OUT]({ commit, state, dispatch }, { serversideRenderedMenues }) {
        if (hasExpired(state.iat) || state.isLoggedin === true) {
            clearPersistedStorage();
        }
        if (!serversideRenderedMenues) {
            dispatch(APP_FETCH_MENU);
            return commit(APP_UPDATE, { isLoggedIn: { newValue: false } });
        }
        return commit(APP_UPDATE, { isLoggedIn: { newValue: false }, menu: { newValue: serversideRenderedMenues } });
    },
};

export const appContext = {
    state,
    mutations,
    actions
};
