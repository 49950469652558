import $ from 'jquery';
$(document).ready(function () {
    
var $overlayToggle = $('.js-toggle-overlay');
var $overlayLink;
var $overlay = $('.overlay');
var $overlayContent = $('.overlay-content');
var $overlayVideo = $('.overlay-video');

var $headerClose = $('.site-header__close');

var $siteSearchText = $('.site-search__text');
var pppi,ppenddate,ppname;

// OVERLAY SHOW/HIDE
function hideOverlays() {
    $headerClose.hide();
    $($overlayContent, $overlay).hide();
}
hideOverlays();

function fadeInOverlay() {
    $headerClose.show();
    $('html').addClass('overlay-visible');
    $overlay.show();
    $('#' + $overlayLink).fadeIn();

    if ($overlayLink === 'search-layer') {
        $siteSearchText.focus();
    }
}

function fadeOutOverlay() {
    $overlayContent.hide();
    $headerClose.hide();
    $('html').removeClass('overlay-visible');
    $overlay.hide();
    if ($('#search-layer').is(':visible')) {
        $('.js-sitesearch-autocomplete').remove();
        $('.js-sitesearch-commonqueries').show();
        $siteSearchText.val('');
    }

}
$overlayToggle.on('click', function (event) {
    event.preventDefault();
    $overlayLink = $(this).attr('data-id');
    fadeInOverlay();
});
$headerClose.on('click', function () {
    fadeOutOverlay();
});

$(document).keyup(function (e) {
    if (e.keyCode === 27 && $('html').hasClass('overlay-visible')) {
        fadeOutOverlay();
    } else if (e.keyCode === 27 && $overlayVideo.is(':visible')) {
        closeVideo();
    }
});

// VIDEO
$('.js-videotrigger').on('click', function (e) {
    $(this).next($overlayVideo).show(250);
    $(this).next($overlayVideo).children('.overlay__videowrapper').append("<iframe src='https://www.youtube.com/embed/' + youtubeID + '?rel=0&autoplay=1' frameborder='0' allowfullscreen></iframe>");
    $overlay.show();
    e.preventDefault();
});
$('.js-closevideo').on('click', function (e) {
    e.preventDefault();
    closeVideo();
});
function closeVideo() {
    $('.overlay__video:visible .overlay__videowrapper iframe').remove();
    $('.overlay__video:visible').hide(250);
    $overlay.hide();
}
$('.btn-close').on('click', function (e) {
    e.preventDefault();
    $('.new-overlay').hide();
});
    //btn-terminate-step-1
    $('.btn-terminate-step-1').on('click', function (e) {
        e.preventDefault();
        pppi = $(this).attr('data-target-pppi');
        ppenddate = $(this).attr('data-target-enddate');
        ppname = $(this).attr('data-target-ppname');
        $('.btn-feedback').attr('data-ppname',ppname)
        $("#ppenddate").html(ppenddate);
        $("#ppnametitle").html(ppname);
        $('.new-overlay').hide();
        $('.cancel-overlay').show();
    });
    $('.btn-terminate').on('click', function (e) {
        e.preventDefault();
        $('.new-overlay').hide();
        
        $.post('/ajax/CustomerApi/TerminateProductPackage', { pppid: pppi }, function () {
            $('.confirmation-overlay').show();
        });
        
    });
    $('.btn-change-step-1').on('click', function (e) {
        e.preventDefault();
        $("#checkout-container-div").html("");
        pppi = $(this).attr('data-target-pppi');
        ppenddate = $(this).attr('data-target-enddate');
        ppname = $(this).attr('data-target-ppname');
        $('.btn-feedback').attr('data-ppname', ppname)
        $("#ppenddate").html(ppenddate);
        $("#ppnametitle").html(ppname);
        $('.new-overlay').hide();
        $('.change-card-overlay').show();
        $.post('/ajax/CustomerApi/GetNeteasyChangeCardPaymentId', { pppid: pppi }, function (response) {
            var paymentId = response.paymentId;
            if (paymentId) {
                $('.change-card-overlay').show();
                const checkoutOptions = {

                    checkoutKey: $('#netscheckoutkey').val(),
                    paymentId: paymentId,
                    containerId: "checkout-container-div",
                    language: "sv-SE"
                };
                const checkout = new window.Dibs.Checkout(checkoutOptions);
                checkout.on('payment-completed', function () {
                    $('.change-card-overlay').hide();
                    //window.location = '/bekraftelse?ppid=' + parseInt(ppi) + '&pid=' + paymentId;
                });
            }


        }).fail(function (response) {
            window.loader.hide();
            window.showErrorToast(response.responseText.replace('""', ''));
        });
    });

    $('.btn-change').on('click', function (e) {
        e.preventDefault();
        $('.new-overlay').hide();

        $.post('/ajax/CustomerApi/GetNeteasyChangeCardPaymentId', { pppid: pppi }, function (response) {
            var paymentId = response.paymentId;
            if (paymentId) {
                $('.change-card-overlay').show();
                const checkoutOptions = {
                    
                    checkoutKey: $('#netscheckoutkey').val(),
                    paymentId: paymentId,
                    containerId: "checkout-container-div",
                    language: "sv-SE"
                };
                const checkout = new window.Dibs.Checkout(checkoutOptions);
                checkout.on('payment-completed', function () {
                });
            }
            

        }).fail(function (response) {
            window.loader.hide();
            window.showErrorToast(response.responseText.replace('""', ''));
        });

    });

    $('.btn-approve').on('click', function (e) {
        e.preventDefault();
        $('.new-overlay').hide();

        document.location.reload();

    });

    

});
