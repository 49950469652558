<template>
  <NdsCard :heading="{ title: 'Bedrägerispärr', type: 'h3', prefix: { prefix: 'far', iconName: 'shield-alt' } }">
    <template #controls v-if="fraudBarrier.IsFraudBarrierOpen">
      <input id="switch" v-model="fraudBarrier.FraudBarrierOn" type="checkbox" class="enento__toggle-input ml-auto"
        @click="toggleFraudBarrierStatusModalOpen">
      <label class="enento__toggle mb-0" for="switch">
        <i class="far fa-check fade-in-half" />
        <span class="enento__toggle-on">På</span>
        <span class="enento__toggle-off">Av</span>
      </label>
    </template>
    <template #default>
      <div v-if="fraudBarrierPending || fraudBarrierModelPending"
        class="align-items-center justify-content-center d-flex">
        <NdsLoader />
      </div>
      <Reloader v-else-if="fraudBarrierFailed || fraudBarrierModelFailed" @reload="getfraudBarrier" />
      <template v-else-if="fraudBarrierLoaded && fraudBarrierModelLoaded">
        <p v-if="fraudBarrier.IsFraudBarrierOpen" class="pt-1" v-html="fraudBarrierModel.toggleText.value" />
        <p v-else class="px-3" v-html="fraudBarrierModel.erroR_PROTECTION_CLOSED.value" />
      </template>
    </template>
  </NdsCard>
  <NdsConfirmModal v-bind="fraudBarrierInfoModal.props" v-model="fraudBarrierInfoModal.isOpen.value"
    @onConfirm="fraudBarrierInfoModal.closeModal" @onCancel="fraudBarrierInfoModal.closeModal" />
  <NdsConfirmModal v-bind="enableFraudBarrierModal.props" v-model="enableFraudBarrierModal.isOpen.value"
    @onConfirm="enableFraudBarrierStatus" @onCancel="enableFraudBarrierModal.closeModal" />
  <NdsConfirmModal v-bind="disableFraudBarrierModal.props" v-model="disableFraudBarrierModal.isOpen.value"
    @onConfirm="disableFraudBarrierStatus" @onCancel="disableFraudBarrierModal.closeModal" />
</template>
<script setup>
import { useStore } from 'vuex';
import { NdsLoader, NdsConfirmModal, NdsCard, useConfirmModal } from '@nds/vue';
import { computed, onMounted, watch } from 'vue';
import Reloader from '../components/Reloader.vue';
import { FRAUD_BARRIER_FETCH, FRAUD_BARRIER_MODEL_FETCH, FRAUD_BARRIER_POST } from '../Scripts/store/modules/fraudBarrierContex';
import { getRequestVerificationToken } from '../utils/authUtils';

const store = useStore();
const fraudBarrier = computed(() => store.state.fraudBarrierContex.fraudBarrier);
const fraudBarrierFailed = computed(() => store.state.fraudBarrierContex.failed);
const fraudBarrierPending = computed(() => store.state.fraudBarrierContex.pending);
const fraudBarrierLoaded = computed(() => store.state.fraudBarrierContex.loaded);
const fraudBarrierModel = computed(() => store.state.fraudBarrierContex.fraudBarrierModel);
const fraudBarrierModelFailed = computed(() => store.state.fraudBarrierContex.modelFailed);
const fraudBarrierModelPending = computed(() => store.state.fraudBarrierContex.modelPending);
const fraudBarrierModelLoaded = computed(() => store.state.fraudBarrierContex.modelLoaded);

onMounted(() => {
  if (!store.state.fraudBarrierContex.loaded) {
    store.dispatch(FRAUD_BARRIER_FETCH);
  }
  if (!store.state.fraudBarrierContex.modelLoaded) {
    store.dispatch(FRAUD_BARRIER_MODEL_FETCH);
  }
});

const getfraudBarrier = () => {
  store.dispatch(FRAUD_BARRIER_FETCH);
  store.dispatch(FRAUD_BARRIER_MODEL_FETCH);
}

const disableFraudBarrierModal = useConfirmModal({
  title: fraudBarrierModel.value.protectionDisableHeadline?.value || 'Inaktivera Bedrägerispärr',
  variant: 'warning',
  prefix: { prefix: "far", iconName: "exclamation-triangle" },
  cancelBtnLabel: "Stäng",
  confirmBtnLabel: "Inaktivera",
  body: {
    text: fraudBarrierModel.value.protectionDisableText?.value,
    isRichText: true,
  },
});

const enableFraudBarrierModal = useConfirmModal({
  title: fraudBarrierModel.value.protectionEnableHeadline?.value || 'Aktivera Bedrägerispärr',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "shield-alt" },
  cancelBtnLabel: "Stäng",
  confirmBtnLabel: "Aktivera",
  body: {
    text: fraudBarrierModel.value.protectionEnableText?.value,
    isRichText: true,
  },
});

const fraudBarrierInfoModal = useConfirmModal({
  title: 'Så här fungerar det',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: fraudBarrierModel.value.howItWorks?.value,
    isRichText: true,
  },
});

const toggleFraudBarrierStatusModalOpen = (e) => {
  e.preventDefault();
  if (fraudBarrier.value.FraudBarrierOn) {
    return disableFraudBarrierModal.openModal();
  }
  return enableFraudBarrierModal.openModal();
};

const enableFraudBarrierStatus = () => {
  const csrfToken = getRequestVerificationToken();
  store.dispatch(FRAUD_BARRIER_POST, { newStatus: 1, csrfToken });
  enableFraudBarrierModal.closeModal();
}

const disableFraudBarrierStatus = () => {
  const csrfToken = getRequestVerificationToken();
  store.dispatch(FRAUD_BARRIER_POST, { newStatus: 0, csrfToken });
  disableFraudBarrierModal.closeModal();
}

watch(fraudBarrierModelLoaded, (value) => {
  if (value) {
    fraudBarrierInfoModal.setProps({
      title: 'Så här fungerar det',
      variant: 'primary',
      prefix: { prefix: "far", iconName: "question-circle" },
      confirmBtnLabel: "Stäng",
      body: {
        text: fraudBarrierModel.value.howItWorks?.value,
        isRichText: true,
      },
    });
    enableFraudBarrierModal.setProps({
      title: fraudBarrierModel.value.protectionEnableHeadline?.value || 'Aktivera Bedrägerispärr',
      variant: 'primary',
      prefix: { prefix: "far", iconName: "shield-alt" },
      cancelBtnLabel: "Stäng",
      confirmBtnLabel: "Aktivera",
      body: {
        text: fraudBarrierModel.value.protectionEnableText?.value,
        isRichText: true,
      },
    });
    disableFraudBarrierModal.setProps({
      title: fraudBarrierModel.value.protectionDisableHeadline?.value || 'Inaktivera Bedrägerispärr',
      variant: 'warning',
      prefix: { prefix: "far", iconName: "exclamation-triangle" },
      cancelBtnLabel: "Stäng",
      confirmBtnLabel: "Inaktivera",
      body: {
        text: fraudBarrierModel.value.protectionDisableText?.value,
        isRichText: true,
      },
    });
  }
})

</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";

.fraudbarrier__upload-wrapper i {
  left: 11px;
  /* top: calc(50% - 9px); */
  top: 20px;
}

#generalErrorMessage {

  strong,
  i.alert-icon {
    color: $feedback-error;
  }

  p {
    color: $emperor;
  }

  strong,
  p {
    padding-left: 35px;
  }

  &.hide {
    display: none;
  }
}

.alert-icon {
  left: 10px;
}

.alert-danger {
  border-radius: 0;
}

.enento__toggle {
  margin-right: 30px;
}
</style>