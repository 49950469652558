<template>
  <TabbedPageTemplate testId="dashboard-csn-page">
    <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'graduation-cap' } }">
      <template #default>
        <NdsText isRichText tight :text="model.description.value" />
        <NdsHeading tight title="Ditt/dina CSN-lån" type="h4" />
        <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
          <NdsLoader />
        </div>
        <div v-else-if="failed" class="col-12">
          <Reloader @reload="getCreditInfo" />
        </div>
        <template v-else>
          <div class="d-none d-md-block">
            <Csn v-if="creditReport.ctiCsnLoan" :cti-csn-loan="creditReport.ctiCsnLoan" />
          </div>
          <div class="d-block d-md-none">
            <CsnMobile v-if="creditReport.ctiCsnLoan" :cti-csn-loan="creditReport.ctiCsnLoan" />
          </div>
          <NdsCard v-if="hasNoCsn" outlined>
            <ZeroState icon="far fa-graduation-cap" :text="model.noCsnLoan.value || 'Inget CSN-lån registrerat'"
              isRichText />
          </NdsCard>
          <NdsFooter class="pt-2" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: CSN" tight />
        </template>
      </template>
    </NdsCard>
  </TabbedPageTemplate>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { NdsLoader, NdsCard, NdsHeading, NdsFooter, NdsText } from '@nds/vue';
import Csn from '../components/Csn.vue';
import CsnMobile from '../components/CsnMobile.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import Reloader from '../components/Reloader.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';

const props = defineProps({
  model: Object
});

const store = useStore();
const creditReport = computed(() => store.state.customerCreditInfoContex.customerCreditInfo.creditReport);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const hasNoCsn = computed(() => !creditReport.value.ctiCsnLoan || (!creditReport.value.ctiCsnLoan?.hasInstallmentLoan && !creditReport.value.ctiCsnLoan?.hasStudentLoan && !creditReport.value.ctiCsnLoan?.hasStudentMeans));

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});
const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
