import { getContentByFriendlyUrl, getContentByContentLink } from '../../providers/epiContentProvider';

const EPI_DATA_MODEL = "EPI_DATA_MODEL";
export const EPI_DATA_MODEL_UPDATE_MODEL_BY_URL = `${EPI_DATA_MODEL}/UPDATE_MODEL_BY_URL`;
export const EPI_DATA_MODEL_UPDATE_MODEL_BY_CONTENT_LINK = `${EPI_DATA_MODEL}/UPDATE_MODEL_BY_CONTENT_LINK`;
const EPI_DATA_MODEL_UPDATE = `${EPI_DATA_MODEL}/UPDATE`;
const EPI_DATA_MODEL_PENDING = `${EPI_DATA_MODEL}/PENDING`;
const EPI_DATA_MODEL_RESET = `${EPI_DATA_MODEL}/RESET`;
const state = {
    model: {},
    modelLoaded: false,
    pending: false,
};

const mutations = {
    [EPI_DATA_MODEL_UPDATE](state, newModel) {
        state.model = newModel;
        state.modelLoaded = true;
        state.pending = false;
    },
    [EPI_DATA_MODEL_RESET](state) {
        state.model = {};
        state.modelLoaded = false;
    },
    [EPI_DATA_MODEL_PENDING](state, url) {
        state.pending = true;
        state.lastCallUrl = url;
    },
};

const parameters = {
    expand: '*'
};

const containsCheckoutUrlSegment = RegExp(/\/checkout\//);

const actions = {
    [EPI_DATA_MODEL_UPDATE_MODEL_BY_URL]({ commit }, friendlyUrl) {
        /**
         * When updating a model by friendly URL we assume that the friendly URL
         * contains every querystring parameter that we might need on the server.
         */
        commit(EPI_DATA_MODEL_PENDING, friendlyUrl);
        //TODO fix this issiue that tabs/links inside my settings mobile should use vue router rather than href, so for now we can just run setting model to pending in checkout
        if (containsCheckoutUrlSegment.test(friendlyUrl)) {
            commit(EPI_DATA_MODEL_RESET);
        }
        return getContentByFriendlyUrl(friendlyUrl, parameters).then(response => {
            commit(EPI_DATA_MODEL_UPDATE, response.data);
        });
    },
    [EPI_DATA_MODEL_UPDATE_MODEL_BY_CONTENT_LINK]({ commit, rootState }, contentLink) {
        /**
         * Updating a model by content link is done when something is being
         * edited and when viewing a block. In order to be sure that we get the
         * correct model, we need to keep any previously existing query string
         * from the friendly URL.
         *
         * See the implementation of ExtendedContentModelMapper.GetContextMode
         * for additional details.
         */

        const params = Object.assign({},
            parameters,
            rootState.route.query
        );
        commit(EPI_DATA_MODEL_PENDING);
        return getContentByContentLink(contentLink, params).then(response => {
            commit(EPI_DATA_MODEL_UPDATE, response.data);
        });
    }
};

export const epiDataModelContext = {
    state,
    mutations,
    actions
};
