<template>
  <TabbedPageTemplate testId="dashboard-income-and-taxation-page">
    <NdsCard :heading="{
      title: model.name,
      type: 'h3',
      prefix: { prefix: 'far', iconName: 'money-bill' }
    }">
      <template #default>
        <NdsText isRichText tight :text="model.description.value" />
        <div v-if="pending" class="d-flex flex-fill justify-content-center align-items-center">
          <NdsLoader />
        </div>
        <div v-else-if="failed" class="col-12">
          <Reloader @reload="getCreditInfo" />
        </div>
        <template v-if="creditReport?.ctiConsumerIncomeAndTaxation">
          <div class="d-none d-lg-block mb-3">
            <NdsTable :items="incomeTableItems" :headers="incomeTableHeaders" alternateRowColors />
          </div>
          <div class="d-lg-none mb-3">
            <IncomeAndTaxationMobile :cti-consumer-income-and-taxation="creditReport.ctiConsumerIncomeAndTaxation"
              :sole-trader="creditReport.extendedConsumerInformation.soleTrader" />
          </div>
        </template>
        <NdsCard v-else outlined>
          <ZeroState icon="far fa-money-bill"
            :text="model.noIncomeAndTaxation.value || 'Inga inkomstuppgifter registrerade'" isRichText />
        </NdsCard>
        <NdsFooter :text="model.incomeBottomText.value" tight />
        <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Skatteverket" :rightNote="rightNote"
          tight />
      </template>
    </NdsCard>
  </TabbedPageTemplate>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import {
  NdsLoader,
  NdsCard,
  NdsFooter,
  NdsText,
  NdsTable
} from '@nds/vue';
import { FormatDate } from '../_helpers.js';
import IncomeAndTaxationMobile from '../components/incomeAndTaxation/IncomeAndTaxationMobile.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import Reloader from '../components/Reloader.vue';
import ZeroState from '../components/myinformation/ZeroState.vue';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import { getIncomeAndTaxationModel } from '../components/incomeAndTaxation/incomeAndTaxationUtils.js';

const props = defineProps({ model: Object });
const store = useStore();
const creditReport = computed(() => store.state.customerCreditInfoContex.customerCreditInfo.creditReport);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const lastUpdatedDate = computed(() => {
  const timestamp = creditReport.value?.ctiConsumerIncomeAndTaxation?.incomeAndTaxation?.[0]?.updateDate?.timeStamp?.value;
  return timestamp ? FormatDate(timestamp) : false;
});

const rightNote = computed(() =>
  lastUpdatedDate.value
    ? {
      text: `Senast uppdaterad: ${lastUpdatedDate.value}`,
      prefix: { prefix: 'far', iconName: 'far fa-clock' }
    }
    : null
);

const isSoleTrader = computed(() => creditReport.value?.extendedConsumerInformation?.soleTrader);
const incomeModels = computed(() => ({
  current: getIncomeAndTaxationModel({
    incomeAndTaxation: creditReport.value?.ctiConsumerIncomeAndTaxation?.incomeAndTaxation?.[0],
    showMissingValue: true
  }),
  last: getIncomeAndTaxationModel({
    incomeAndTaxation: creditReport.value?.ctiConsumerIncomeAndTaxation?.incomeAndTaxation?.[1],
    showMissingValue: false
  })
}));

const incomeTableHeaders = computed(() => [
  { title: 'Inkomst', value: 'incomeValue' },
  { title: incomeModels.value.current.incomeYearDate, value: 'currentYearIncomeValue', align: 'end' },
  { title: incomeModels.value.last.incomeYearDate, value: 'lastYearIncomeValue', align: 'end' }
]);

const incomeTableItems = computed(() => {
  const currentFields = incomeModels.value.current.fields;
  const lastFields = incomeModels.value.last.fields;

  return [
    {
      incomeValue: currentFields.employmentIncome.label,
      currentYearIncomeValue: currentFields.employmentIncome.value,
      lastYearIncomeValue: lastFields.employmentIncome.value
    },
    isSoleTrader.value && {
      incomeValue: currentFields.activeBusinessActivityIncome.label,
      currentYearIncomeValue: currentFields.activeBusinessActivityIncome.value,
      lastYearIncomeValue: lastFields.activeBusinessActivityIncome.value
    },
    isSoleTrader.value && {
      incomeValue: currentFields.passiveBusinessActivityIncome.label,
      currentYearIncomeValue: currentFields.passiveBusinessActivityIncome.value,
      lastYearIncomeValue: lastFields.passiveBusinessActivityIncome.value
    },
    {
      incomeValue: currentFields.assessedEarnedIncome.label,
      currentYearIncomeValue: currentFields.assessedEarnedIncome.value,
      lastYearIncomeValue: lastFields.assessedEarnedIncome.value
    },
    {
      incomeValue: currentFields.capitalIncome.label,
      currentYearIncomeValue: currentFields.capitalIncome.value,
      lastYearIncomeValue: lastFields.capitalIncome.value
    },
    {
      incomeValue: currentFields.capitalDeficit.label,
      currentYearIncomeValue: currentFields.capitalDeficit.value,
      lastYearIncomeValue: lastFields.capitalDeficit.value
    },
    {
      incomeValue: currentFields.aggregateIncomeAfterDeductionBeforeTax.label,
      currentYearIncomeValue: currentFields.aggregateIncomeAfterDeductionBeforeTax.value,
      lastYearIncomeValue: lastFields.aggregateIncomeAfterDeductionBeforeTax.value
    },
    {
      incomeValue: currentFields.finalTax.label,
      currentYearIncomeValue: currentFields.finalTax.value,
      lastYearIncomeValue: lastFields.finalTax.value
    },
    isSoleTrader.value && {
      incomeValue: currentFields.activeBusinessActivityDeficiency.label,
      currentYearIncomeValue: currentFields.activeBusinessActivityDeficiency.value,
      lastYearIncomeValue: lastFields.activeBusinessActivityDeficiency.value
    },
    isSoleTrader.value && {
      incomeValue: currentFields.passiveBusinessIncome.label,
      currentYearIncomeValue: currentFields.passiveBusinessIncome.value,
      lastYearIncomeValue: lastFields.passiveBusinessIncome.value
    }
  ].filter(Boolean);
});

onMounted(() => {
  document.title = props.model.name || 'Mina tjänster';
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});
const getCreditInfo = () => store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
</script>
