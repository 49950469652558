<template>
  <NdsCard :heading="{ title: 'Kreditengagemang', type: 'h3', prefix: { prefix: 'far', iconName: 'user' } }"
    testId="minupplysning-mobile-credits-tab">
    <template #controls>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle" @click="infoModal.openModal"
        aria-label="Kreditengagemang förklaring" />
    </template>
    <template #default>
      <div v-if="report.creditReport.dailyCreditSummary" class="d-lg-none">
        <div class="d-flex align-items-center p-2">
          <p class="mb-0">
            Totalt beviljat
          </p>
          <p class="font-weight-medium mb-0 color-ocean ml-auto">
            {{
              formatToSek(report.creditReport.dailyCreditSummary.creditSummaries[0].grantedCredits) }}
          </p>
        </div>
        <div class="d-flex align-items-center p-2">
          <p class="mb-0">
            Totalt utnyttjat
          </p>
          <p class="font-weight-medium mb-0 color-ocean ml-auto">
            {{
              formatToSek(report.creditReport.dailyCreditSummary.creditSummaries[0].usedCredits) }}
          </p>
        </div>
        <hr class="color-gallery">
        <div class="d-flex align-items-center p-2">
          <p class="mb-0">
            Antal krediter
          </p>
          <p class="font-weight-medium mb-0 ml-auto">
            {{
              formatToAmount(report.creditReport.dailyCreditSummary.creditSummaries[0].numberOfCredits) }}
          </p>
        </div>
        <div class="d-flex align-items-center p-2 pb-3">
          <p class="mb-0">
            Antal kreditgivare
          </p>
          <p class="font-weight-medium mb-0 ml-auto">
            {{
              formatToAmount(report.creditReport.dailyCreditSummary.creditSummaries[0].numberOfCreditors) }}
          </p>
        </div>
      </div>
      <div v-if="report.creditReport.dailyCreditSummary" id="credits-mobile-table" class="dashboard__table">
        <Accordion id="credits-blanco-accordion" title="Blanco"
          :sub-title="getCreditInfoText(report.creditReport.dailyUnsecuredCreditSummary.unsecureCreditSummaries[0].usedCreditUnsecured)"
          :sub-title-amount="formatToSek(report.creditReport.dailyUnsecuredCreditSummary.unsecureCreditSummaries[0].usedCreditUnsecured)"
          :credit-type="5" :is-empty="!hasCredit(5)" :report="report" />
        <Accordion id="credits-downpayment-accordion" title="Avbetalningar"
          :sub-title="getCreditInfoText(report.creditReport.dailyUnsecuredCreditSummary.unsecureCreditSummaries[0].usedCreditHirePurchase)"
          :sub-title-amount="formatToSek(report.creditReport.dailyUnsecuredCreditSummary.unsecureCreditSummaries[0].usedCreditHirePurchase)"
          :credit-type="4" :is-empty="!hasCredit(4)" :report="report" />
        <Accordion id="credits-creditcard-accordion" title="Kontokredit"
          :sub-title="getCreditInfoText(report.creditReport.dailyUnsecuredCreditSummary.unsecureCreditSummaries[0].usedCreditOnAccountCredits)"
          :sub-title-amount="formatToSek(report.creditReport.dailyUnsecuredCreditSummary.unsecureCreditSummaries[0].usedCreditOnAccountCredits)"
          :credit-type="1" :report="report" :is-empty="!hasCredit(1)" />
        <Accordion id="credits-property-accordion" title="Fastighet"
          :sub-title="getCreditInfoText(report.creditReport.dailyMortgageSummary.mortgagesSummaries[0].usedCreditPropertyMortgage)"
          :sub-title-amount="formatToSek(report.creditReport.dailyMortgageSummary.mortgagesSummaries[0].usedCreditPropertyMortgage)"
          :credit-type="7" :report="report" :is-empty="!hasCredit(7)" />
        <Accordion id="credits-tenantownership-accordion" title="Bostadsrätt"
          :sub-title="getCreditInfoText(report.creditReport.dailyMortgageSummary.mortgagesSummaries[0].usedCreditTenantOwnershipMortgage)"
          :sub-title-amount="formatToSek(report.creditReport.dailyMortgageSummary.mortgagesSummaries[0].usedCreditTenantOwnershipMortgage)"
          :credit-type="9" :report="report" :is-empty="!hasCredit(9)" />
      </div>
      <ZeroState v-else icon="far fa-sack-dollar" text="Ingen kredit registrerad" />
      <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige" tight />
    </template>
  </NdsCard>
  <NdsConfirmModal v-bind="infoModal.props" v-model="infoModal.isOpen.value" @onConfirm="infoModal.closeModal"
    @onCancel="infoModal.closeModal" />
</template>
<script setup>
import { NdsIconButton, NdsCard, NdsConfirmModal, NdsFooter, useConfirmModal } from '@nds/vue';
import { formatToSek, formatToAmount } from '../../_helpers.js';
import Accordion from '../../components/myinformation/Accordion.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';

const props = defineProps({
  report: Object,
  introText: String
});

const getCreditInfoText = (num) => {
  if (num) {
    return "Totalt utnyttjad kredit: ";
  }
  return "Ingen kredit registrerad";
};

const hasCredit = (creditType) => {
  return Boolean(props.report?.creditReport?.ctiCreditsWithInquirer?.creditInformations?.filter((item) => props.report?.creditReport?.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === creditType && item.toDate === null).length);
};

const infoModal = useConfirmModal({
  title: 'Kreditengagemang',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.introText,
    isRichText: true,
  },
});

</script>