<template>
  <NdsCard :heading="{ title: 'Inkomstuppgifter', type: 'h3', prefix: { prefix: 'far', iconName: 'money-bill' } }"
    testId="minupplysning-mobile-income-tab">
    <template #default>
      <div v-if="creditReport.ctiConsumerIncomeAndTaxation" class="mb-3">
        <IncomeAndTaxationMobile :cti-consumer-income-and-taxation="creditReport.ctiConsumerIncomeAndTaxation"
          :sole-trader="creditReport.extendedConsumerInformation.soleTrader" />
      </div>
      <ZeroState v-else icon="far fa-money-bill" text="Inga inkomstuppgifter registrerade" />
      <NdsFooter :text="incomeBottomText" tight />
      <NdsFooter class="w-100" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Skatteverket"
        :rightNote="rightNote" tight />
    </template>
  </NdsCard>
</template>
<script setup>
import { computed } from 'vue';
import { NdsCard, NdsFooter } from '@nds/vue';
import { FormatDate } from '../../_helpers.js';
import IncomeAndTaxationMobile from '../../components/incomeAndTaxation/IncomeAndTaxationMobile.vue';
import ZeroState from "../../components/myinformation/ZeroState.vue";

const props = defineProps({
  creditReport: Object,
  incomeBottomText: String,
});

const currentYearIncomeAndTaxationDate = computed(() => {
  if (props.creditReport.ctiConsumerIncomeAndTaxation?.incomeAndTaxation[0].updateDate) {
    return FormatDate(props.creditReport.ctiConsumerIncomeAndTaxation.incomeAndTaxation[0].updateDate.timeStamp.value)
  }
  return false;
});

const rightNote = computed(() => currentYearIncomeAndTaxationDate.value && ({
  text: `Senast uppdaterad: ${currentYearIncomeAndTaxationDate.value}`,
  prefix: {
    prefix: 'far',
    iconName: 'far fa-clock',
  },
}));
</script>