/**
 * Wraps the calls to the ContentDeliveryAPI. It's used by the vuex
 * `epiDataModel` module and the `ArtistContainerPage` component.
 */
import axios from 'axios';

const get = ({ baseURL, url, params, headers }) => {
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
            // TODO investigate if we want to do this, previously any forbidden call will redirect user, even saleforce...
            window.location.href = '/';
        }
        return Promise.reject(error);
    });

    return axios({
        method: 'get',
        baseURL,
        url,
        params,
        headers
    });
};

const applicationPath = document.documentElement.dataset.applicationPath;

/**
 * Getting content with the content link is the default way of calling the ContentDeliveryAPI.
 * It is used in MusicFestival to get:
 *  - block data
 *  - updated data after a `contentSaved` message, which has the content link
 */
export const getContentByContentLink = (contentLink, params) =>
        get({ baseURL: `${applicationPath}api/episerver/v2.0/`, url: `content/${contentLink}`, params });

/**
 * Getting data from ContentDeliveryAPI through regular routing (friendly URLs) was added in ContentDeliveryAPI 2.3.0.
 * It is used in MusicFestival to get:
 *  - page data, through the vuex `epiDataModel` module
 */
export const getContentByFriendlyUrl = (url, params) =>
        get({ baseURL: '/', url, params, headers: { Accept: 'application/json'} });

/**
 * Getting the children of the page with ContentDeliveryAPI is enabled by
 * the extensions in Infrastructure/ContentDeliveryExtendedRouting.
 * It is used in MusicFestival to get:
 *  - artist list in ArtistContainerPage.vue
 */
// TODO investigate if used
export const getChildren = (friendlyUrl, parameters) => {
    // Split URL into path and queries
    const urlPieces = friendlyUrl.split('?');
    // In View mode we might visit the URL with or without a trailing / (i.e. "http://localhost:56312/en/artists" or "http://localhost:56312/en/artists/")
    const pathname = (urlPieces[0].endsWith('/') ? urlPieces[0] : urlPieces[0] + '/');
    // In Edit mode we'll have URL queries (i.e. "/EPiServer/CMS/Content/en/artists,,6/?epieditmode=True")
    const queries = urlPieces[1] ? '?' + urlPieces[1] : '';

    // Concatenate the friendly URL with "/children" for the Content API
    const callUrl = pathname + 'children' + queries;

    return this.getContentByFriendlyUrl(callUrl, parameters);
};

/**
 * Will check the content's content type against the inheritance chain list in
 * components.
 *
 * Used to get the Vue component matching the loaded content's type by
 * `BlockComponentSelector` and `PageComponentSelector`.
 *
 * @param {*} content The content object that has a contentType property, which
 * holds the inheritance chain from the C# models for the content with the last
 * item being the actual implementation.
 * @param {Array} components The list of registered Vue components.
 * @returns The matching content type, or `null`.
 */
export const getComponentTypeForContent = (content, components) => {
    // Here we will try to find a component that matches the content type name.
    for (let i = (content.contentType.length - 1); i >= 0; i--) {
        if (components[content.contentType[i]]) {
            return content.contentType[i];
        }
    }
    return null;
};
