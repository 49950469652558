/**
 * Registers all of the content components representing pages and blocks. They
 * need to be globally registered because they are dynamically rendered by the
 * component selectors (`PageComponentSelector` and `BlockComponentSelector`).
 */
import { createApp } from 'vue';
import router from './router';
import store from './store';
import VuePopper from "vue3-popper";
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import '../_navigation.js';
import { APP_IS_LOGGED_IN, APP_IS_LOGGED_OUT } from './store/modules/appContext';
import DefaultLayout from '../components/layout/DefaultLayout.vue';
import LoggedinLayout from '../components/layout/LoggedinLayout.vue';
import CreditWatchRiskForecastPage from '../pages/CreditWatchRiskForecastPage.vue';
import MyInformationPage from '../pages/MyInformationPage.vue';
import CSNPage from '../pages/CSNPage.vue';
import RemarksPage from '../pages/RemarksPage.vue';
import ConsumerPropertiesPage from '../pages/ConsumerPropertiesPage.vue';
import MortgagesPage from '../pages/MortgagesPage.vue';
import MyUCScorePage from '../pages/MyUCScorePage.vue';
import IncomeAndTaxationPage from '../pages/IncomeAndTaxationPage.vue';
import CreditsPage from "../pages/CreditsPage.vue";
import DigitalMailboxPage from "../pages/DigitalMailboxPage.vue";
import FraudBarrierPage from "../pages/FraudBarrierPage.vue";
import IdProtectionPage from "../pages/IdProtectionPage.vue";
import MyCreditScorePage from "../pages/MyCreditScorePage.vue";
import DashboardPage from '../pages/DashboardPage.vue';
import CheckoutPage from '../pages/CheckoutPage.vue';
import MySettingsPage from '../pages/MySettingsPage.vue';
import DashboardSideBar from '../components/DashboardSideBar.vue';
import CascadeMenu from '../components/CascadeMenu.vue';
import ArticleList from '../../src/components/ArticleList.vue';
import HeroBlock from '../components/blocks/HeroBlock.vue';
import ContactUs from '../components/ContactUs/ContactUs.vue';
import SignupLinkBlock from '../components/blocks/SignupLinkBlock.vue';
import SignupFormBlock from '../components/blocks/SignupFormBlock.vue';
import OrderFormBlock from '../components/blocks/OrderFormBlock.vue';
import WidgetBottomSticky from '../components/WidgetBottomSticky.vue';
import SiteHeader from '../components/SiteHeader.vue';
import NotificationsBanner from '../components/NotificationsBanner.vue';
import FullImageCardBlock from '../components/blocks/FullImageCardBlock.vue';
import DefaultFooter from '../components/DefaultFooter.vue';
import PageHeader from '../components/templates/PageHeader.vue';
import PageTemplate from '../components/templates/PageTemplate.vue';
import { vuetify } from '@nds/vue';

const app = createApp({
    el: '#dashboard-app',
    store,
    router,
    data() {
        return {
            mobileMenuExpanded: false
        }
    }
});


app.use(router);
app.use(store);
app.use(vuetify);

if (window.isLoggedin) {
    store.dispatch(APP_IS_LOGGED_IN, { serversideRenderedMenues: window.serversideRenderedMenues });
} else {
    store.dispatch(APP_IS_LOGGED_OUT, { serversideRenderedMenues: window.serversideRenderedMenues });
}

app.component("VuePopper", VuePopper);
app.component('LoggedinLayout', LoggedinLayout);
app.component('DefaultLayout', DefaultLayout);
app.component('CreditWatchRiskForecastPage', CreditWatchRiskForecastPage);
app.component('CreditsPage', CreditsPage);
app.component('MortgagesPage', MortgagesPage);
app.component('MyInformationPage', MyInformationPage);
app.component('IncomeAndTaxationPage', IncomeAndTaxationPage);
app.component('CSNPage', CSNPage);
app.component('RemarksPage', RemarksPage);
app.component('MyUCScorePage', MyUCScorePage);
app.component('ConsumerPropertiesPage', ConsumerPropertiesPage);
app.component('DigitalMailboxPage', DigitalMailboxPage);
app.component('FraudBarrierPage', FraudBarrierPage);
app.component('IdProtectionPage', IdProtectionPage);
app.component('MyCreditScorePage', MyCreditScorePage);
app.component('DashboardPage', DashboardPage);
app.component('CascadeMenu', CascadeMenu);
app.component('MySettingsPage', MySettingsPage);
app.component('DashboardSideBar', DashboardSideBar);
app.component('ArticleList', ArticleList);
app.component('CheckoutPage', CheckoutPage);
app.component('HeroBlock', HeroBlock);
app.component('ContactUs', ContactUs);
app.component('SignupLinkBlock', SignupLinkBlock);
app.component('SignupFormBlock', SignupFormBlock);
app.component('OrderFormBlock', OrderFormBlock);
app.component('WidgetBottomSticky', WidgetBottomSticky);
app.component('SiteHeader', SiteHeader);
app.component('NotificationsBanner', NotificationsBanner);
app.component('FullImageCardBlock', FullImageCardBlock);
app.component('DefaultFooter', DefaultFooter);
app.component('PageHeader', PageHeader);
app.component('PageTemplate', PageTemplate);

window.components = {};
window.components.DefaultLayout = DefaultLayout;
window.components.LoggedinLayout = LoggedinLayout;
window.components.CreditWatchRiskForecastPage = CreditWatchRiskForecastPage;
window.components.CreditsPage = CreditsPage;
window.components.MortgagesPage = MortgagesPage;
window.components.MyInformationPage = MyInformationPage;
window.components.IncomeAndTaxationPage = IncomeAndTaxationPage;
window.components.CSNPage = CSNPage;
window.components.RemarksPage = RemarksPage;
window.components.MyUCScorePage = MyUCScorePage;
window.components.DigitalMailboxPage = DigitalMailboxPage;
window.components.ConsumerPropertiesPage = ConsumerPropertiesPage;
window.components.DashboardPage = DashboardPage;
window.components.FraudBarrierPage = FraudBarrierPage;
window.components.IdProtectionPage = IdProtectionPage;
window.components.MyCreditScorePage = MyCreditScorePage;
window.components.MySettingsPage = MySettingsPage;
window.components.CascadeMenu = CascadeMenu;
window.components.ArticleList = ArticleList;
window.components.CheckoutPage = CheckoutPage;
window.components.HeroBlock = HeroBlock;
window.components.WidgetBottomSticky = WidgetBottomSticky;
window.components.OrderFormBlock = OrderFormBlock;
window.components.DashboardSideBar = DashboardSideBar;
window.components.SiteHeader = SiteHeader;
window.components.NotificationsBanner = NotificationsBanner;
window.components.FullImageCardBlock = FullImageCardBlock;
window.components.DefaultFooter = DefaultFooter;
window.components.PageHeader = PageHeader;
window.components.PageTemplate = PageTemplate;


try {
    app.mount('#dashboard-app');
    document.getElementById("server-side-pre-rendered-header").style.display = "none";
} catch (error) {
    console.log('failed to mount on dashboard app', error);
}
