<template>
  <div v-if="modelPending" class="dashboard">
    <NdsShimmer :shimmers="[{ type: 'button' }, { type: 'card' }, { type: 'card' }, { type: 'card' }, { type: 'card' }]" :gap="3" direction="column" center />
  </div>
    <div v-else class="dashboard" :data-testid="testId">
    <div class="pt-1 pb-3 mobile-return-overview-btn d-md-none">
      <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
        <NdsLink :href="href" :active="isActive" @click="navigate"
          :prefix="{ prefix: 'far', iconName: 'home-alt' }">
          Översikt
        </NdsLink>
      </RouterLink>
    </div>
    <slot />
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { NdsLink, NdsShimmer } from '@nds/vue';

defineProps({
  testId: String,
});

const store = useStore();
const modelPending = computed(() => store.state.epiDataModelContext.pending);
</script>