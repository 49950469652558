<template>
  <NdsCard :heading="cardHeading" :variant="cardVariant" :backgroundImageSrc="backgroundImageSrc">
    <template #default>
      <NdsText tight="bottom" v-if="model.SectionText" :text="sectionText" isRichText :onDarkBg="onDarkBg" />
      <ButtonBlock v-if="model.Button" :model="model.Button" is-single-block />
    </template>
  </NdsCard>
</template>
<script setup>
import { computed } from 'vue';
import { NdsCard, NdsText } from '@nds/vue';
import ButtonBlock from '../blocks/ButtonBlock.vue';
import useCookieConsent from '../../utils/cookieConsentUtils';

const props = defineProps({
  model: Object,
});

const { getCookieConsenXHTML } = useCookieConsent();
const cardHeading = computed(() => {
  if (props.model.Heading) {
    return {
      title: props.model.Heading,
      type: 'h3',
      prefix: props.model.FACode ? { prefix: 'fas', iconName: props.model.FACode } : null,
    }
  }
  return null;
});
const cardVariant = computed(() => {
  if (props.model.BackgroundTheme === 'white') {
    return 'plain'
  }
  if (props.model.BackgroundTheme === 'rodedust') {
    return 'secondary'
  }
  return 'primary'
})
const onDarkBg = computed(() => props.model.BackgroundTheme !== 'white');
const cookieConsenXTML = getCookieConsenXHTML({ id: 'fullImageCard', classes: 'cookie-consent-link-button' });

const backgroundImageSrc = computed(() => props.model.BackgroundImage ? props.model.BackgroundImage : '');

const sectionText = computed(() => props.model.SectionText?.replace(/\{consent\}/i, cookieConsenXTML));

</script>
