<template>
  <NdsAccordion :accordions="combinedAccordions">
    <template v-for="accordion in combinedAccordions" #[accordion.id] :key="accordion.id">
      <NdsList :items="accordion.items" variant="borderless" />
    </template>
  </NdsAccordion>
</template>

<script setup>
import { computed } from 'vue';
import { getIncomeAndTaxationModel } from './incomeAndTaxationUtils';
import { NdsAccordion, NdsList } from '@nds/vue';

const props = defineProps({
  ctiConsumerIncomeAndTaxation: Object,
  soleTrader: Boolean,
});

const currentYearIncomeAndTaxation = computed(() =>
  getIncomeAndTaxationModel({
    incomeAndTaxation: props.ctiConsumerIncomeAndTaxation.incomeAndTaxation[0],
    showMissingValue: true
  })
);

const lastYearIncomeAndTaxation = computed(() =>
  getIncomeAndTaxationModel({
    incomeAndTaxation: props.ctiConsumerIncomeAndTaxation.incomeAndTaxation[1],
    showMissingValue: false
  })
);

const formatYearlyIncomeDetailsList = (fields) => [
  { id: 'employmentIncome', title: fields.employmentIncome.label, subtitle: fields.employmentIncome.value },
  props.soleTrader && { id: 'activeBusinessActivityIncome', title: fields.activeBusinessActivityIncome.label, subtitle: fields.activeBusinessActivityIncome.value },
  props.soleTrader && { id: 'passiveBusinessActivityIncome', title: fields.passiveBusinessActivityIncome.label, subtitle: fields.passiveBusinessActivityIncome.value },
  { id: 'assessedEarnedIncome', title: fields.assessedEarnedIncome.label, subtitle: fields.assessedEarnedIncome.value },
  { id: 'capitalIncome', title: fields.capitalIncome.label, subtitle: fields.capitalIncome.value },
  { id: 'capitalDeficit', title: fields.capitalDeficit.label, subtitle: fields.capitalDeficit.value },
  { id: 'aggregateIncomeAfterDeductionBeforeTax', title: fields.aggregateIncomeAfterDeductionBeforeTax.label, subtitle: fields.aggregateIncomeAfterDeductionBeforeTax.value },
  { id: 'finalTax', title: fields.finalTax.label, subtitle: fields.finalTax.value },
  props.soleTrader && { id: 'activeBusinessActivityDeficiency', title: fields.activeBusinessActivityDeficiency.label, subtitle: fields.activeBusinessActivityDeficiency.value },
  props.soleTrader && { id: 'passiveBusinessIncome', title: fields.passiveBusinessIncome.label, subtitle: fields.passiveBusinessIncome.value },
].filter(Boolean);

const combinedAccordions = computed(() => [
  {
    id: "Income 1",
    title: `Inkomst ${currentYearIncomeAndTaxation.value.incomeYearDate}`,
    items: formatYearlyIncomeDetailsList(currentYearIncomeAndTaxation.value.fields),
  },
  {
    id: "Income 2",
    title: `Inkomst ${lastYearIncomeAndTaxation.value.incomeYearDate}`,
    items: formatYearlyIncomeDetailsList(lastYearIncomeAndTaxation.value.fields),
  },
]);
</script>
