<template>
  <div class="new-creditwatch">
    <div class="site-content myucscorepage">
      <div id="uc-score" class="row uc-score-graph-container">
        <div v-if="loadedFailed || !loaded" class="col-12 dark-background">
          <Reloader v-if="loadedFailed" theme="dark" @reload="GetNewChartData" />
          <div v-else-if="!loaded" class="align-items-center justify-content-center d-flex">
            <NdsLoader />
          </div>
        </div>
        <div class="col-12">
          <div class="usergraph__hc-nav" style="display: none;">
            <span class="usergraph__hc-navprev" @click="updateUCScoreChartPrev(1)"><i
                class="fas fa-chevron-left" /></span> <span class="usergraph__hc-current">Månad -
              Månad</span> <span class="usergraph__hc-navnext" @click="updateUCScoreChartNext(1)"><i
                class="fas fa-chevron-right" /></span>
          </div>
          <div id="graph-uc-score" class="uc-score-graph first-row" />
          <div v-if="report.creditReport.creditwatchScoreList.length > 0" class="risk-score-container">
            <div class="slider-pin">
              <div class="pin-container"
                :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }">
                <span>{{ report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length
                  - 1].scoreValue }}</span><i class="fas fa-chevron-down" />
              </div>
            </div>
            <div class="risk-uc-score-slider" />
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 1 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 454"
              class="risk-grade-label-container row">
              <div class="col-12">
                <div class="risk-grade-label weak"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-svag">
                  Svag
                </div>
              </div>
              <div class="col-12">
                <div class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }">
                  1-454
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 455 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 558"
              class="risk-grade-label-container row">
              <div class="col-12">
                <div class="risk-grade-label less-good"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-mindre-bra">
                  Mindre bra
                </div>
              </div>
              <div class="col-12">
                <div class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }">
                  455-558
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 559 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 634"
              class="risk-grade-label-container row">
              <div class="col-12">
                <div class="risk-grade-label good"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-bra">
                  Bra
                </div>
              </div>
              <div class="col-12">
                <div class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }">
                  559-634
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 635 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 744"
              class="risk-grade-label-container row">
              <div class="col-12">
                <div class="risk-grade-label very-good"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-mycket-bra">
                  Mycket bra
                </div>
              </div>
              <div class="col-12">
                <div class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }">
                  635-744
                </div>
              </div>
            </div>
            <div
              v-if="report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue >= 745 && report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue <= 999"
              class="risk-grade-label-container row">
              <div class="col-12">
                <div class="risk-grade-label"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + '%' }"
                  data-testid="risk-grade-utmarkt">
                  Utmärkt
                </div>
              </div>
              <div class="col-12">
                <div class="risk-grade-intervall"
                  :style="{ 'right': ((999 - report.creditReport.creditwatchScoreList[report.creditReport.creditwatchScoreList.length - 1].scoreValue) / 999) * 100 + 1 + '%' }">
                  745-999
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { NdsLoader } from '@nds/vue';
import $ from 'jquery';
import Highcharts from 'highcharts';
import Reloader from '../Reloader.vue';

const props = defineProps({
  chartData: Object,
  report: Object,
  height: Number,
  width: Number,
  markerRadius: Number,
  size: String
});

const creditWatch = ref({
  ucScoreChartMaxItems: 6,
  chartMaxItems: 6,
  totalResData: {},
  resData: {},
  chartIndexMax: 0,
  chartIndexMin: 0
});
const ucscoreChart = ref(null);
const loaded = ref(false);
const loadedFailed = ref(false);

onMounted(() => {
  chartRedraw();
  loaded.value = true;
  loadedFailed.value = false;
  setUCScoreChartData(props.report.ucscoreChartData);
  updateUCScoreChartNavigator();
  ucscoreChart.value = new Highcharts.Chart(getNewChartOptions());
});

const chartRedraw = () => {
  creditWatch.value.ucScoreChartMaxItems = 6;

  if ($(window).width() < 480 || $(window).width() > 768) {
    creditWatch.value.chartMaxItems = 5;
    creditWatch.value.markerRadius = props.markerRadius;
    creditWatch.value.showAxis = false;
    creditWatch.value.reportChartMaxItems = 3;
  }
  else {
    creditWatch.value.chartMaxItems = 10;
    if (props.size === "lg") {
      creditWatch.value.markerRadius = props.markerRadius;
    }
    else {
      creditWatch.value.markerRadius = props.markerRadius + 10;
    }
    creditWatch.value.showAxis = true;
    creditWatch.value.reportChartMaxItems = 6;
  }
}

const filterNewChartData = () => {
  creditWatch.value.clonedResData = JSON.parse(JSON.stringify(creditWatch.value.resData));
  creditWatch.value.filteredResData = {};
  creditWatch.value.filteredResData.Categories = [];
  creditWatch.value.filteredResData.PlotLines = [];
  creditWatch.value.filteredResData.SeriesLine = [];
  creditWatch.value.filteredResData.SeriesWeak = [];
  creditWatch.value.filteredResData.SeriesLessGood = [];
  creditWatch.value.filteredResData.SeriesGood = [];
  creditWatch.value.filteredResData.SeriesVeryGood = [];
  creditWatch.value.filteredResData.SeriesExcellent = [];
  let i = 0;
  let oldVal = {};

  for (i = 0; i < creditWatch.value.clonedResData.Categories.length; i++) {
    if (i <= creditWatch.value.chartIndexMax && i >= creditWatch.value.chartIndexMin)
      creditWatch.value.filteredResData.Categories.splice(creditWatch.value.filteredResData.Categories.length, 0, creditWatch.value.clonedResData.Categories[i]);
  }

  // PlotLines
  for (i = 0; i < creditWatch.value.clonedResData.PlotLines.length; i++) {
    if (i <= creditWatch.value.chartIndexMax && i >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.PlotLines[i];
      oldVal.value = oldVal.value - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.PlotLines.splice(creditWatch.value.filteredResData.PlotLines.length, 0, oldVal);
    }
  }

  // SeriesLine
  for (i = 0; i < creditWatch.value.clonedResData.SeriesLine.length; i++) {
    if (i <= creditWatch.value.chartIndexMax && i >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.SeriesLine[i];
      oldVal.x = oldVal.x - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.SeriesLine.splice(creditWatch.value.filteredResData.SeriesLine.length, 0, oldVal);
    }
  }

  // SeriesWeak
  for (i = 0; i < creditWatch.value.clonedResData.SeriesWeak.length; i++) {
    if (creditWatch.value.clonedResData.SeriesWeak[i].x <= creditWatch.value.chartIndexMax && creditWatch.value.clonedResData.SeriesWeak[i].x >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.SeriesWeak[i];
      oldVal.x = oldVal.x - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.SeriesWeak.splice(creditWatch.value.filteredResData.SeriesWeak.length, 0, oldVal);
    }
  }

  // SeriesLessGood
  for (i = 0; i < creditWatch.value.clonedResData.SeriesLessGood.length; i++) {
    if (creditWatch.value.clonedResData.SeriesLessGood[i].x <= creditWatch.value.chartIndexMax && creditWatch.value.clonedResData.SeriesLessGood[i].x >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.SeriesLessGood[i];
      oldVal.x = oldVal.x - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.SeriesLessGood.splice(creditWatch.value.filteredResData.SeriesLessGood.length, 0, oldVal);
    }
  }

  // SeriesGood
  for (i = 0; i < creditWatch.value.clonedResData.SeriesGood.length; i++) {
    if (creditWatch.value.clonedResData.SeriesGood[i].x <= creditWatch.value.chartIndexMax && creditWatch.value.clonedResData.SeriesGood[i].x >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.SeriesGood[i];
      oldVal.x = oldVal.x - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.SeriesGood.splice(creditWatch.value.filteredResData.SeriesGood.length, 0, oldVal);
    }
  }
  // SeriesVeryGood
  for (i = 0; i < creditWatch.value.clonedResData.SeriesVeryGood.length; i++) {
    if (creditWatch.value.clonedResData.SeriesVeryGood[i].x <= creditWatch.value.chartIndexMax && creditWatch.value.clonedResData.SeriesVeryGood[i].x >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.SeriesVeryGood[i];
      oldVal.x = oldVal.x - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.SeriesVeryGood.splice(creditWatch.value.filteredResData.SeriesVeryGood.length, 0, oldVal);
    }
  }

  // SeriesExcellent
  for (i = 0; i < creditWatch.value.clonedResData.SeriesExcellent.length; i++) {
    if (creditWatch.value.clonedResData.SeriesExcellent[i].x <= creditWatch.value.chartIndexMax && creditWatch.value.clonedResData.SeriesExcellent[i].x >= creditWatch.value.chartIndexMin) {
      oldVal = creditWatch.value.clonedResData.SeriesExcellent[i];
      oldVal.x = oldVal.x - creditWatch.value.chartIndexMin;
      creditWatch.value.filteredResData.SeriesExcellent.splice(creditWatch.value.filteredResData.SeriesExcellent.length, 0, oldVal);
    }
  }
};

const updateUCScoreChartNavigator = () => {
  if (creditWatch.value.resData.MonthNames.length > 1) {
    $(".usergraph__hc-current").html(creditWatch.value.resData.MonthNames[creditWatch.value.chartIndexMin] + " - " + creditWatch.value.resData.MonthNames[creditWatch.value.chartIndexMax]);
    $(".usergraph__hc-nav").show();
  } else {
    $(".usergraph__hc-nav").hide();
  }

  if (creditWatch.value.chartIndexMax < creditWatch.value.resData.Categories.length - 1)
    $(".usergraph__hc-navnext").show();
  else
    $(".usergraph__hc-navnext").hide();

  if (creditWatch.value.chartIndexMin > 0)
    $(".usergraph__hc-navprev").show();
  else
    $(".usergraph__hc-navprev").hide();
}

const updateUCScoreChartNext = (i) => {
  creditWatch.value.chartIndexMax = creditWatch.value.chartIndexMax + i;
  creditWatch.value.chartIndexMin = creditWatch.value.chartIndexMin + i;

  // blockera att man går längre än max
  if (creditWatch.value.chartIndexMax > creditWatch.value.resData.Categories.length - 1) {
    creditWatch.value.chartIndexMax = creditWatch.value.resData.Categories.length - 1;
    creditWatch.value.chartIndexMin = creditWatch.value.chartIndexMax - creditWatch.value.chartMaxItems + 1;
  }

  filterNewChartData();
  updateUCScoreChartNavigator();
  ucscoreChart.value?.update(getNewChartOptions());
}

const updateUCScoreChartPrev = (i) => {
  creditWatch.value.chartIndexMax = creditWatch.value.chartIndexMax - i;
  creditWatch.value.chartIndexMin = creditWatch.value.chartIndexMin - i;

  // blockera att man går längre än min
  if (creditWatch.value.chartIndexMin < 0) {
    creditWatch.value.chartIndexMin = 0;
    creditWatch.value.chartIndexMax = creditWatch.value.chartMaxItems - 1;
  }

  filterNewChartData();
  updateUCScoreChartNavigator();
  ucscoreChart.value?.update(getNewChartOptions());
}

const setUCScoreChartData = (res) => {
  for (const category in res) {
    if (Object.prototype.hasOwnProperty.call(res, category)) {
      $(".category-show-" + category).show();
    }
  }
  creditWatch.value.totalResData = res;
  creditWatch.value.resData = creditWatch.value.totalResData['UC_CREDIT_SCORE'];
  creditWatch.value.chartIndexMax = creditWatch.value.resData.Categories.length - 1;
  if (creditWatch.value.resData.Categories.length > creditWatch.value.ucScoreChartMaxItems) {
    creditWatch.value.chartIndexMin = creditWatch.value.chartIndexMax - creditWatch.value.ucScoreChartMaxItems + 1;
  }
  filterNewChartData();
}

const getNewChartOptions = () => {
  return {
    chart: {
      renderTo: 'graph-uc-score',
      backgroundColor: 'rgba(0,0,0,0)',
      styledMode: true,
      height: props.height
    },
    accessibility: {
      enabled: false
    },
    title: {
      text: ''
    },

    style: {
      fontFamily: 'Gilroy-Bold',
      filter: 'alpha(opacity=10)',
      opacity: 10,
      background: 'transparent'
    },

    xAxis: {
      categories: creditWatch.value.filteredResData.Categories,
      tickmarkPlacement: 'on',
      startOnTick: true,
      endOnTick: true,
      labels: {
        y: 25
      },
      plotLines: creditWatch.value.filteredResData.PlotLines,
      tickAmount: 6
    },

    yAxis: {
      visible: false
    },
    tooltip: {
      enabled: false
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [
      {
        zIndex: 10,
        dateFormat: 'YYYY-mm-dd',
        className: 'series-line',
        type: 'line',
        lineWidth: 2,
        tooltip: false,
        marker: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          useHTML: true,
          verticalAlign: 'middle',
          padding: 0,
          y: -4,
          formatter: function () {
            if (this.y > 10000)
              return (this.round(props.y / 1000)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "k";
            else if (this.y === 0)
              return ("<span class='position-absolute' style='top:-13px; left:-4px;'>0</span>")
            return (this.y).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
        },
        data: creditWatch.value.filteredResData.SeriesLine

      }, {
        zIndex: 20,
        className: 'series-weak',
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: creditWatch.value.markerRadius
        },
        states: {
          hover: {
            enabled: false
          }
        },
        data: creditWatch.value.filteredResData.SeriesWeak
      }, {
        zIndex: 20,
        className: 'series-less-good',
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: creditWatch.value.markerRadius
        },
        states: {
          hover: {
            enabled: false
          }
        },
        data: creditWatch.value.filteredResData.SeriesLessGood
      }, {
        zIndex: 20,
        className: 'series-good',
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: creditWatch.value.markerRadius
        },
        states: {
          hover: {
            enabled: false
          }
        },
        data: creditWatch.value.filteredResData.SeriesGood
      }, {
        zIndex: 20,
        className: 'series-very-good',
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: creditWatch.value.markerRadius
        },
        states: {
          hover: {
            enabled: false
          }
        },
        data: creditWatch.value.filteredResData.SeriesVeryGood
      }, {
        zIndex: 20,
        className: 'series-excellent',
        type: 'scatter',
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: creditWatch.value.markerRadius
        },
        states: {
          hover: {
            enabled: false
          }
        },
        data: creditWatch.value.filteredResData.SeriesExcellent
      }
    ]
  };
}
</script>
