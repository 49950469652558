<template>
  <div id="csn-table" class="col-12 dashboard__table mb-2">
    <div class="row dashboard__table-header">
      <div class="col-3 dashboard__table-column">
        <h5 class="mb-0">
          Typ av lån
        </h5>
      </div>
      <div class="col-3 dashboard__table-column">
        <h5 class="mb-0">
          Återbetalningstid
        </h5>
      </div>
      <div v-for="(header, index) in loanHeaders" :key="`header_${index}`" class="col-2 dashboard__table-column"
        :class="getCurrentClass(index)">
        <h5 class="mb-0">
          {{ header }}
        </h5>
      </div>
    </div>

    <div v-for="(loan, index) in formattedCsnLoans" :key="`loan-${index}`" class="row dashboard__table-clear">
      <div class="col-3 dashboard__table-column">
        {{ loan.title }}
      </div>
      <div class="col-3 dashboard__table-column font-weight-medium">
        <span>{{ loan.repaymentPeriod }}</span>
      </div>

      <div v-for="(loanElement, index) in loan.elements" :key="`loan-element-${index}`"
        class="col-2 dashboard__table-column font-weight-medium" :class="getCurrentClass(index)">
        <span>{{ loanElement.formattedCreditAmount }}</span>
      </div>
    </div>

  </div>
</template>
<script setup>
import { FormatDate, formatToSek } from '../_helpers.js';
import { computed } from 'vue';

const props = defineProps({
  ctiCsnLoan: Object,
});

const getCurrentClass = (index) => index === 0 ? 'current' : '';

const loanHeaders = computed(() => {
  const yearsOfHistoricalCsnData = props.ctiCsnLoan?.csnLoanElement?.length || 0;
  const firstTimestamp = props.ctiCsnLoan?.csnLoanElement[0]?.date.timeStamp.value;
  if (!firstTimestamp) {
    return [];
  }
  let currentYear;
  let twoYearMissingLoanHeaders;
  let oneYearMissingLoanHeaders;

  try {
    currentYear = new Date(firstTimestamp).getFullYear();
    twoYearMissingLoanHeaders = [`${currentYear - 1}-12-31`, `${currentYear - 2}-12-31`];
    oneYearMissingLoanHeaders = [`${currentYear - 1}-12-31`];
  } catch {
    return [];
  }

  if (yearsOfHistoricalCsnData === 1) {
    return [FormatDate(firstTimestamp), ...twoYearMissingLoanHeaders];
  }

  if (yearsOfHistoricalCsnData === 2) {
    return [FormatDate(firstTimestamp), ...oneYearMissingLoanHeaders];
  }

  return props.ctiCsnLoan?.csnLoanElement.map((loan) => loan.date.timeStamp.value && FormatDate(loan.date.timeStamp.value)).filter(Boolean);
});


const formatCsnLoan = (title, loanType) => {
  const csnLoanElement = props.ctiCsnLoan?.csnLoanElement[1];
  const repaymentPeriod = csnLoanElement && csnLoanElement[loanType] && csnLoanElement[loanType].repaymentPeriod
    ? `${csnLoanElement[loanType].repaymentPeriod} år`
    : '-';

  const elements = [0, 1, 2].map((i) => {
    const loanElement = props.ctiCsnLoan.csnLoanElement[i];
    const creditAmount = loanElement?.[loanType]?.creditAmount;
    return {
      creditAmount: creditAmount || '-',
      formattedCreditAmount: creditAmount ? formatToSek(creditAmount) : '-',
    };
  });

  return { title, repaymentPeriod, elements };
};

const formattedCsnLoans = computed(() => {
  const data = [
    props.ctiCsnLoan?.hasInstallmentLoan && formatCsnLoan("Annuitetslån", "installmentLoan"),
    props.ctiCsnLoan?.hasInstallmentLoan2022 && formatCsnLoan("Annuitetslån 2022", "installmentLoan2022"),
    props.ctiCsnLoan?.hasStudentLoan && formatCsnLoan("Studielån", "studentLoan"),
    props.ctiCsnLoan?.hasStudentMeans && formatCsnLoan("Studiemedelslån", "studentMeans"),
  ].filter(Boolean);

  return data;
});
</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_mixins.scss";

.current {
  background-color: $ice-blue;
}
</style>