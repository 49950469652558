<template>
  <NdsHeading title="Blancolån" type="h4" tight>
    <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
      @click="unsecuredLoanQuestionModal.openModal" aria-label="Blancolån förklaring" />
  </NdsHeading>
  <div v-if="hasTerminatedUnsecuredLoans" class="d-flex justify-content-end my-3">
    <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
      variant="secondary" @click="showTerminated = !showTerminated">
      {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
    </NdsButton>
  </div>
  <NdsAccordion v-if="paginatedUnsecuredLoans.length > 0" v-model="activeAccordionId"
    :accordions="paginatedUnsecuredLoans">
    <template v-for="(loan, index) in paginatedUnsecuredLoans" #[loan.id] :key="index">
      <div class="p-3">
        <div class="row pb-3">
          <div class="col-8">Kreditlimit:</div>
          <div class="col-4 pl-0 text-right">{{ loan.grantedCredit }}</div>
          <div class="col-8 color-ocean">Utnyttjad kredit:</div>
          <div class="col-4 pl-0 color-ocean text-right">{{ loan.usedCredit }}</div>
        </div>
        <div class="row">
          <template v-if="loan.startDate">
            <div class="col-8">Startdatum:</div>
            <div class="col-4 pl-0 text-right">{{ loan.startDate }}</div>
          </template>
            <template v-if="!loan.endDate || loan.usedCredit > 0">
            <div class="col-8">Senast uppdaterad:</div>
            <div class="col-4 pl-0 text-right">{{ loan.updatedAt }}</div>
          </template>
          <template v-if="loan.endDate">
            <div class="col-8 color-red">Avslutad:</div>
            <div class="col-4 pl-0 color-red text-right">{{ loan.endDate }}</div>
          </template>
        </div>
      </div>
    </template>
  </NdsAccordion>
  <ZeroState v-else icon="far fa-sack-dollar"
    :text="model.descriptionNoUnsecuredLoanDevelopment.value || 'Inga blancolån registrerade'" isRichText />
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
  <div class="mt-4 mb-3">
    <NdsHeading title="Blancolånutveckling" type="h4" tight>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
        @click="unsecuredLoanDevelopmentQuestionModal.openModal" aria-label="Blancolånutveckling förklaring" />
    </NdsHeading>
    <div class="ndsCreditChartContainer">
      <NdsCreditPlotChart title="" emptyTitle="Ingen blancolånutveckling är registrerad" :chartData="chartData" />
    </div>
  </div>
  <NdsFooter class="mt-5" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige" tight />
  <NdsConfirmModal v-bind="unsecuredLoanQuestionModal.props" v-model="unsecuredLoanQuestionModal.isOpen.value"
    @onConfirm="unsecuredLoanQuestionModal.closeModal" @onCancel="unsecuredLoanQuestionModal.closeModal" />
  <NdsConfirmModal v-bind="unsecuredLoanDevelopmentQuestionModal.props"
    v-model="unsecuredLoanDevelopmentQuestionModal.isOpen.value"
    @onConfirm="unsecuredLoanDevelopmentQuestionModal.closeModal"
    @onCancel="unsecuredLoanDevelopmentQuestionModal.closeModal" />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { NdsIconButton, NdsButton, NdsCreditPlotChart, NdsAccordion, NdsConfirmModal, NdsHeading, NdsFooter, NdsPagination, usePagination, useConfirmModal } from '@nds/vue';
import { formatToSek, FormatDate } from '../_helpers.js';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
  report: Object,
});

const showTerminated = ref(true);
const hasTerminatedUnsecuredLoans = ref(false);

const unsecuredLoans = computed(() => {
  if (props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
    return props.report.creditReport.ctiCreditsWithInquirer.creditInformations.map((creditInformation) => {
      if (props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[creditInformation.creditNumber] === 5) {
        if (creditInformation.toDate && !hasTerminatedUnsecuredLoans.value) {
          hasTerminatedUnsecuredLoans.value = true;
        }
        return creditInformation;
      }
      return null;
    }).filter(Boolean);
  }
  return [];
});

const formattedUnsecuredLoans = computed(() => (unsecuredLoans.value.map((loan, index) => ({
  id: `unsecuredLoan ${index + 1}`,
  title: loan.creditor ?? "Okänd långivare",
  grantedCredit: loan.grantedCredit != null ? formatToSek(loan.grantedCredit) : null,
  usedCredit: loan.usedCredit != null ? formatToSek(loan.usedCredit) : null,
  startDate: loan.fromDate?.timeStamp.value ? FormatDate(loan.fromDate.timeStamp.value) : null,
  updatedAt: loan.updatedAtDate?.timeStamp.value ? FormatDate(loan.updatedAtDate.timeStamp.value) : null,
  endDate: loan.toDate?.timeStamp.value ? FormatDate(loan.toDate.timeStamp.value) : null,
}))));

const filteredFormattedUnsecuredLoans = computed(() => (formattedUnsecuredLoans.value.filter(loan => (showTerminated.value ? true : !loan.endDate))));

const chartData = computed(() => ({
  snapshotLabel: 'Nuläge',
  hasSnapshot: true,
  label: 'Utnyttjad kredit',
  entries: props.report.unsecuredLoansChartData.entries,
}));

const unsecuredLoanQuestionModal = useConfirmModal({
  title: 'Blancolån',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionUnsecuredLoan.value,
    isRichText: true,
  },
});

const unsecuredLoanDevelopmentQuestionModal = useConfirmModal({
  title: 'Blancolånutveckling',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionUnsecuredLoanDevelopment.value,
    isRichText: true,
  },
});

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedUnsecuredLoans
} = usePagination(filteredFormattedUnsecuredLoans);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>

<style scoped>
.ndsCreditChartContainer {
  margin-top: 8px;
  width: 100%;
  height: 320px;
}
</style>
