<template>
  <TabbedPageTemplate testId="dashboard-mortgages-page">
    <NdsCard tight :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'sack-dollar' } }">
      <template #default>
        <NdsText isRichText :text="model.description.value" class="pb-0" />
        <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
          <NdsLoader />
        </div>
        <div v-else-if="failed" class="col-12">
          <Reloader @reload="getCreditInfo" />
        </div>
        <NdsTabs v-else v-model="mortagesTabModel" :tabs="mortagesTabs" @back="onBack" @forward="onForward">
          <template #mortages_tab1>
            <Mortgages :model="model" :report="customerCreditInfo" />
          </template>
          <template #mortages_tab2>
            <UnsecuredLoans :model="model" :report="customerCreditInfo" />
          </template>
        </NdsTabs>
      </template>
    </NdsCard>
  </TabbedPageTemplate>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { NdsLoader, NdsCard, NdsTabs, NdsText, useTabs } from '@nds/vue';
import Mortgages from '../components/Mortgages.vue';
import UnsecuredLoans from '../components/UnsecuredLoans.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import Reloader from '../components/Reloader.vue';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';

const props = defineProps({
  model: Object
});
const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const mortagesTabs = ref([{
  id: 'mortages_tab1',
  name: 'Bolån'
}, {
  id: 'mortages_tab2',
  name: 'Blancolån'
}]);

const { tabModel: mortagesTabModel, onBack, onForward } = useTabs({
  tabs: mortagesTabs,
  defaultTabId: "mortages_tab1",
});

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
