export const getPathnameFromUrl = (url) => {
    if (url === '') {
        return '/';
    }
    if (url.charAt(0) !== '/') {
        return new URL(url).pathname
    }
    return url;
}

const getContentFriendlyUrl = (content) => {
    if (content && content.propertyDataType === "PropertyContentReference" && content.value?.url) {
        return getPathnameFromUrl(content.value?.url);
    }
    return '';
}

const getContentHtmlString = (content) => {
    if (content && content.propertyDataType === "PropertyXhtmlString" && content.value) {
        return content.value;
    }
    return '';
}

const getContentText = (content) => {
    if (content && content.propertyDataType === "PropertyLongString" && content.value) {
        return content.value;
    }
    return '';
}

const getContentBoolean = (content) => {
    if (content && content.propertyDataType === "PropertyBoolean" && content.value) {
        return content.value;
    }
    return false;
}

const getContentNumber = (content) => {
    if (content && content.propertyDataType === "PropertyNumber" && content.value) {
        return content.value;
    }
    return null;
}

const getContentArea = (content) => {
    if (content && content.propertyDataType === "PropertyContentArea" && content.expandedValue) {
        return content.expandedValue;
    }
    return [];
}

const getCheckoutPaymentCheckoutDescriptionBlocks = (content) => {
    if (content.propertyDataType === "PropertyContentArea" && content?.expandedValue?.length) {
        return content.expandedValue.map((block) => ({
            faCode: getContentText(block.faCode),
            heading: getContentText(block.heading),
            description: getContentText(block.description),
        }));
    }
    return [];
}
const getButtonVariantFromEnum = (enumValue) => {
    switch (enumValue) {
        case 1:
            return 'secondary';
        case 2:
            return 'custom';
        case 3:
            return 'text';
        case 4:
            return 'critical';
        case 0:
        default:
            return 'primary';
    }
}
const getButtonSizeFromEnum = (enumValue) => {
    switch (enumValue) {
        case 1:
            return 'small';
        case 2:
            return 'large';
        case 0:
        default:
            return 'default';
    }
}
const getSingleButtonBlock = (content) => {
    if (content.propertyDataType === "PropertyBlock") {
        return {
            CtaUrl: content.ctaUrl.value || "",
            ButtonText: content.heading?.value || "", 
            NDSOnBg: content.ndsOnBg?.value,
            NDSButtonSize: getButtonSizeFromEnum(content.ndsButtonSize?.value),
            NDSButtonVariant: getButtonVariantFromEnum(content.ndsButtonVariant?.value),
        };
    }
    return null;
}

// TODO refacacture fullimagecardblock to handle props that is not starting with uppercase...
const getFullImageCardBlockModel = (content) => {
    if (content.propertyDataType === "PropertyContentArea" && content?.expandedValue?.length) {
        return content.expandedValue.map((block) => ({
            BackgroundImage: getContentFriendlyUrl(block.backgroundImage),
            Button: block.useCheckoutButton?.value === true ? getSingleButtonBlock(block.singleButtonBlock) : null,
            BackgroundTheme: getContentNumber(block.backgroundTheme),
            CardSize: getContentNumber(block.cardSize),
            TextColorTheme: getContentNumber(block.textColorTheme),
            Heading: getContentText(block.heading),
            SectionText: getContentHtmlString(block.sectionText),
        }));
    }
    return [];
}

export const getCheckoutProductPackages = ({ model, productPackageInformation }) => {
    const { productPackage, relatedProductPackages } = productPackageInformation;
    const products = [];
    if (productPackage) {
        products.push(
            {
                productPackage,
                checkoutSelectProductTagText: getContentText(model.checkoutSelectProductTagText),
                checkoutSelectProductFACode: getContentText(model.checkoutSelectProductFACode),
                checkoutSelectProductProductDescription: getContentText(model.checkoutSelectProductProductDescription),
                productPackagePriceDescription: getContentText(model.productPackagePriceDescription),
                checkoutEnterInformationImage: getContentFriendlyUrl(model.checkoutEnterInformationImage),
                checkoutEnterInformationSelectedProductInfo: getContentHtmlString(model.checkoutEnterInformationSelectedProductInfo),
                checkoutEnterInformationAccordionReadMoreText: getContentHtmlString(model.checkoutEnterInformationAccordionReadMoreText),
            }
        );
    }
    const relatedProductsFromModel = getContentArea(model.relatedProducts);
    if (relatedProductPackages && relatedProductPackages.length && relatedProductsFromModel.length) {
        relatedProductsFromModel.forEach((relatedProductPackageModel, index) => {
            products.push(
                {
                    productPageUrl: getContentFriendlyUrl(relatedProductPackageModel.contentLink),
                    productPackage: relatedProductPackages[index],
                    checkoutSelectProductTagText: getContentText(relatedProductPackageModel.checkoutSelectProductTagText),
                    checkoutSelectProductFACode: getContentText(relatedProductPackageModel.checkoutSelectProductFACode),
                    checkoutSelectProductProductDescription: getContentText(relatedProductPackageModel.checkoutSelectProductProductDescription),
                    productPackagePriceDescription: getContentText(relatedProductPackageModel.productPackagePriceDescription),
                    checkoutEnterInformationImage: getContentFriendlyUrl(relatedProductPackageModel.checkoutEnterInformationImage),
                    checkoutEnterInformationSelectedProductInfo: getContentHtmlString(relatedProductPackageModel.checkoutEnterInformationSelectedProductInfo),
                    checkoutEnterInformationAccordionReadMoreText: getContentHtmlString(relatedProductPackageModel.checkoutEnterInformationAccordionReadMoreText),
                }
            );
        });

    }
    return products;
}

export const formatCheckoutPageModel = (model) => {
    return {
        name: model.name,
        productPageUrl: model.productPageUrl,
        hideSwishAsPaymentIcon: getContentBoolean(model.hideSwishAsPaymentIcon),
        checkoutSelectProductTitle: getContentText(model.checkoutSelectProductTitle),
        checkoutSelectProductExtraButtonText: getContentText(model.checkoutSelectProductExtraButtonText),
        checkoutSelectProductButtonText: getContentText(model.checkoutSelectProductButtonText),
        checkoutSelectProductImage: getContentFriendlyUrl(model.checkoutSelectProductImage),
        checkoutEnterInformationTitle: getContentText(model.checkoutEnterInformationTitle),
        checkoutEnterInformationButtonText: getContentText(model.checkoutEnterInformationButtonText),
        checkoutEnterInformationXtraButtonText: getContentText(model.checkoutEnterInformationXtraButtonText),
        checkoutConfirmationHelpText: getContentHtmlString(model.checkoutConfirmationHelpText),
        checkoutPaymentCheckoutDescriptionBlocks: getCheckoutPaymentCheckoutDescriptionBlocks(model.checkoutPaymentContentArea),
        checkoutConfirmationTitle: getContentText(model.checkoutConfirmationTitle),
        checkoutConfirmationDescription: getContentHtmlString(model.checkoutConfirmationDescription),
        checkoutConfirmationAlsoInterestedInTitle: getContentText(model.checkoutConfirmationAlsoInterestedInTitle),
        checkoutConfirmationAlsoInterestedIn: getFullImageCardBlockModel(model.checkoutConfirmationAlsoInterestedIn).shift(),
    }
}