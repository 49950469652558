<template>
  <TabbedPageTemplate testId="dashboard-consumer-properties-page">
    <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'building' } }">
      <template #default>
        <NdsText isRichText tight :text="model.description.value" />
        <div v-if="pending" class="align-items-center justify-content-center d-flex flex-fill">
          <NdsLoader />
        </div>
        <div v-else-if="failed" class="col-12">
          <Reloader @reload="getCreditInfo" />
        </div>
        <template v-else>
          <div v-if="creditReport.ctiConsumerProperties">
            <ConsumerProperties :cti-consumer-properties="creditReport.ctiConsumerProperties" />
          </div>
          <NdsCard v-else outlined>
            <ZeroState icon="far fa-building" :text="model.noPropertiesLoan.value || 'Ingen fastighet registrerad'"
              data-testid="consumer-properties-page-zerostate" isRichText />
          </NdsCard>
          <NdsFooter class="pt-3" :prefix="{ prefix: 'far', iconName: 'book' }"
            text="Källa: Lantmäteriet och Skatteverket" tight />
        </template>
      </template>
    </NdsCard>
  </TabbedPageTemplate>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { NdsLoader, NdsCard, NdsFooter, NdsText } from '@nds/vue';
import ConsumerProperties from '../components/ConsumerProperties.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import Reloader from '../components/Reloader.vue';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
});
const store = useStore();
const creditReport = computed(() => store.state.customerCreditInfoContex.customerCreditInfo.creditReport);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}
</script>
