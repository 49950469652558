<template>
  <div
    class="row"
    data-testid="checkout-enter-information"
  >
    <div class="col-12">
      <h2 class="text-center text-md-left mb-4">
        {{ model.checkoutEnterInformationTitle }}
      </h2>
    </div>
    <div class="col-md-6 order-2 order-md-1">
      <p class="body-large font-weight-medium d-none d-md-block">
        Fyll i dina uppgifter nedan för att komma vidare
      </p>
      <div class="personal-information-form">
        <div class="form-group">
          <label for="email">E-post</label>
          <input
            id="email"
            v-model="formCustomer.email"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': v$.email.$error, 'is-valid': !v$.email.$invalid }"
            style="text-transform: lowercase"
            :placeholder="helperTexts.placeholderEmail"
            :disabled="hasDisabledEmailField"
            @blur="v$.email.$touch"
          >
          <template v-if="v$.email.$errors.length > 0">
            <small
              v-if="v$.email.email.$invalid"
              class="is-invalid"
            >
              {{ v$.email.email.$message }}
            </small>
            <small
              v-else-if="v$.email.required.$invalid"
              class="is-invalid"
            >
              {{ v$.email.required.$message }}
            </small>
          </template>
        </div>
        <div class="form-group">
          <label for="personId">Personnummer</label>
          <input
            id="personId"
            v-model="formCustomer.personNumber"
            type="text"
            class="form-control"
            :placeholder="helperTexts.placeholderSSN"
            :class="{ 'is-invalid': v$.personNumber.$error, 'is-valid': !v$.personNumber.$invalid }"
            :disabled="isLoggedin"
            @blur="v$.personNumber.$touch"
          >
          <template v-if="v$.personNumber.$errors.length > 0">
            <small
              v-if="v$.personNumber.required.$invalid"
              class="is-invalid"
            >
              {{ v$.personNumber.required.$message }}
            </small>
            <small
              v-else-if="v$.personNumber.valid_cord.$invalid"
              class="is-invalid"
            >
              {{ v$.personNumber.valid_cord.$message }}
            </small>
            <small
              v-else-if="v$.personNumber.valid_social.$invalid"
              class="is-invalid"
            >
              {{ v$.personNumber.valid_social.$message }}
            </small>
          </template>
        </div>
        <div class="form-group">
          <label for="phone">Mobiltelefon</label>
          <input
            id="phone"
            v-model="formCustomer.phoneNumber"
            type="text"
            class="form-control"
            :placeholder="helperTexts.placeholderPhone"
            :class="{ 'is-invalid': v$.phoneNumber.$error, 'is-valid': !v$.phoneNumber.$invalid }"
            :disabled="hasDisabledPhoneNumber"
            @blur="v$.phoneNumber.$touch"
          >
          <template v-if="v$.phoneNumber.$errors.length > 0">
            <small
              v-if="v$.phoneNumber.required.$invalid"
              class="is-invalid"
            >
              {{ v$.phoneNumber.required.$message }}
            </small>
            <small
              v-else-if="v$.phoneNumber.valid_cellphone.$invalid"
              class="is-invalid"
            >
              {{ v$.phoneNumber.valid_cellphone.$message }}
            </small>
          </template>
        </div>
      </div>
      <div>
        <button
          v-if="model.checkoutEnterInformationButtonText"
          class="submit-button btn btn-default btn-lg btn-wide w-100-md mb-3"
          :disabled="v$.error || (!formCustomer.email || !formCustomer.personNumber || !formCustomer.phoneNumber) || isPending"
          :class="{ 'disabled': v$.error || (!formCustomer.email || !formCustomer.personNumber || !formCustomer.phoneNumber) }"
          data-testid="checkout-checkoutenterinformation-continue-button"
          @click="goToCheckoutPayment"
        >
          <div
            v-if="isPending"
            class="submit-button-content"
          >
          <span>Laddar...</span><NdsLoader size="25" />
          </div>
          <div v-else>
            {{ model.checkoutEnterInformationButtonText }}
          </div>
        </button>
        <div
          v-if="model.checkoutEnterInformationXtraButtonText"
          class="enento-tinymce w-100-md"
          v-html="model.checkoutEnterInformationXtraButtonText"
        />
      </div>
      <Alert
        v-if="isAlertVisible"
        type="error"
        title="Något gick fel!"
        description="Ett oväntat fel uppstod. Försök igen om en liten stund eller kontakta kundtjänst om felet kvarstår."
        @close="onCloseAlert"
      />
    </div>
    <div class="col-md-6 order-1 order-md-2">
      <div class="checkout-productinformation-wrapper rounded-border p-4 bg-beach mb-3 mb-md-0">
        <img :src="selectedProduct.checkoutEnterInformationImage">
        <h3 class="gilroy-bold mb-1">
          {{ selectedProduct.productPackage.name }}
        </h3>
        <h4 class="color-darkocean">
          {{ selectedProduct.productPackage.price }},00 kr
          {{ selectedProduct.productPackagePriceDescription }}
        </h4>
        <div
          class="enento-tinymce"
          v-html="selectedProduct.checkoutEnterInformationSelectedProductInfo"
        />
        <template v-if="selectedProduct.checkoutEnterInformationAccordionReadMoreText">
          <div id="checkoutAccordion">
            <div
              id="headingProduct"
              class="position-relative text-right"
            >
              <a
                href="#"
                class="checkout-accordion-link color-darkocean font-weight-medium p-0 mr-3"
                :class="{ 'collapsed': isCollapsed }"
                data-toggle="collapse"
                data-target="#collapseCheckoutProductInfo"
                aria-expanded="true"
                aria-controls="collapseCheckoutProductInfo"
                @click="isCollapsed = !isCollapsed"
              >
                {{ isCollapsed ? 'Läs mer' : 'Läs mindre' }}
              </a>
            </div>
            <div
              id="collapseCheckoutProductInfo"
              class="collapse mt-3"
              aria-labelledby="headingProduct"
              data-parent="#checkoutAccordion"
            >
              <div
                class="enento-tinymce"
                v-html="selectedProduct.checkoutEnterInformationAccordionReadMoreText"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { NdsLoader } from '@nds/vue';
import Alert from '../../components/Alert.vue';
import { computed, reactive, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators';
import { isValidSwedishCellPhone, isValidSocialSecNumber, isValidCoordinationNumber } from '../../utils/validators.js';
import { postCreateCustomer } from '../../Scripts/providers/customerApiProvider';
import {
  CHECKOUT_SELECT_STEP,
  CHECKOUT_STEP,
  CHECKOUT_SET_CUSTOMER,
  CHECKOUT_SET_CUSTOMER_ID
} from '../../Scripts/store/modules/checkoutContext';
import helperTexts from '../../../../json/helperTexts.json';

defineProps({
  model: Object,
});

const store = useStore();
const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const isCollapsed = ref(true);
const customer = computed(() => store.state.checkoutContext.customer);
const loggedInCustomer = computed(() => store.state.appContext.isLoggedin ? {
  personNumber: store.state.customerInfoContex?.customerEssentials?.customerSocSecNo,
  email: store.state.customerInfoContex?.customerEssentials?.customerEmail,
  phoneNumber: store.state.customerInfoContex?.customerEssentials?.customerPhone
} : null);
const hasDisabledEmailField = computed(() => store.state.appContext.isLoggedin && !!store.state.customerInfoContex?.customerEssentials?.customerEmail);
const hasDisabledPhoneNumber = computed(() =>  store.state.appContext.isLoggedin && !!store.state.customerInfoContex?.customerEssentials?.customerPhone);

const emptyFormCustomer = {
  personNumber: '',
  email:'',
  phoneNumber:''
}
const formCustomer = reactive({
  personNumber: customer.value?.ssn || emptyFormCustomer.personNumber,
  email: customer.value?.email || emptyFormCustomer.email,
  phoneNumber: customer.value?.phoneNumber || emptyFormCustomer.phoneNumber,
});
const isAlertVisible = ref(false);
const isPending = ref(false);

const rules = computed(() => {
  const localRules = {
    phoneNumber: {
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
      valid_cellphone: {
        $validator: isValidSwedishCellPhone,
        $message: helperTexts.validationMessageMobileNumber,
      },
    },
    personNumber: {
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
      valid_social: {
        $validator: isValidSocialSecNumber,
        $message: helperTexts.validationMessageSSN,
      },
      valid_cord: {
        $validator: isValidCoordinationNumber,
        $message: helperTexts.validationMessageSSNCoordination,
      },
    },
    email: {
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
      email: helpers.withMessage(helperTexts.validationMessageEmail, email),
    },
  }
  return localRules
});

const v$ = useVuelidate(rules, formCustomer);

onMounted(() => {
  if (!store.state.appContext.isLoggedin) {
    if (customer.value?.ssn) {
      formCustomer.email = customer.value?.email;
    }
    if (customer.value?.email) {
      formCustomer.personNumber = customer.value.ssn;
    }
    if (customer.value?.phoneNumber) {
      formCustomer.phoneNumber = customer.value?.phoneNumber;
    }
    const hasValuesfromStore = Object.entries(formCustomer).toString() === Object.entries(emptyFormCustomer).toString();
    if(!hasValuesfromStore) {
      v$.value.$validate();
    }
  } else {

    if (loggedInCustomer.value?.personNumber) {
      formCustomer.personNumber = loggedInCustomer.value?.personNumber;
    }
    if (loggedInCustomer.value?.email) {
      formCustomer.email = loggedInCustomer.value?.email;
    }
    if (loggedInCustomer.value?.phoneNumber) {
      formCustomer.phoneNumber = loggedInCustomer.value?.phoneNumber;
    }
  }
});

const goToCheckoutPayment = () => {
  store.dispatch(CHECKOUT_SET_CUSTOMER, { email: formCustomer.email, ssn: formCustomer.personNumber, phoneNumber: formCustomer.phoneNumber });
  // TODO enable for testing locally
  /*   store.dispatch(CHECKOUT_SET_CUSTOMER_ID, { externalCustomerId: 'fake_externalCustomerId' });
    return store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.PAYMENT }); */
  if (!v$.value.$error) {
    if (isLoggedin.value && customerEssentials.value.externalCustomerId) {
      store.dispatch(CHECKOUT_SET_CUSTOMER_ID, { externalCustomerId: customerEssentials.value.externalCustomerId });
      return store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.PAYMENT });
    }
    isPending.value = true;
    postCreateCustomer({
      personNumber: formCustomer.personNumber,
      email: formCustomer.email,
      phoneNumber: formCustomer.phoneNumber,
    }).then((response) => {
      isPending.value = false;
      store.dispatch(CHECKOUT_SET_CUSTOMER_ID, { externalCustomerId: response.data?.externalId });
      return store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.PAYMENT });
    }).catch(() => {
      isAlertVisible.value = true;
      isPending.value = false;
    });
  }
}

const onCloseAlert = () => {
  isAlertVisible.value = false;
}
</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_mixins.scss";
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.personal-information-form {
  .form-group {
    label {
      color: $night-rider;
      font-size: 14px;
      font-family: Gilroy-Bold;
    }
  }
}

.submit-button {
  .submit-button-content {
    display: inline-flex;
    align-items: center;
  }
}

.alert {
  margin-top: 5px;
  padding-left: 65px;

  .alert-icon {
    left: 15px;
    top: calc(50% - 20px);
  }
}

.checkout-productinformation-wrapper {
  img {
    position: absolute;
    top: 6px;
    right: 24px;
    height: 110px;
  }

  .checkout-accordion-link {
    &:after {
      font-family: "Font Awesome 5 Pro";
      font-size: 12px;
      content: '\f077';
      color: $dark-ocean;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.collapsed {
      &:after {
        content: '\f078';
      }
    }
  }
}

@include breakpoint(tablet-landscape) {
  .checkout-productinformation-wrapper {
    img {
      height: auto;
      top: -100px;
      right: 40px;
    }
  }
}
</style>