<template>
  <div
    class="p-0 justify-content-center align-items-center"
    @dragenter.prevent="toggle_active()"
    @dragleave.prevent="toggle_active()"
    @dragover.prevent
    @drop.prevent="drop"
  >
    <div class="col-12 p-0 upload-area justify-content-center text-center">
      <span class="w-100 text-center d-block mt-5 mb-3">
        <i class="far fa-upload fa-3x" />
      </span>
      <p class="body-small d-block">
        Släpp filer här
      </p>
      <p class="body-small d-block mb-2">
        eller
      </p>
      <!-- File input -->
      <label
        for="file"
        class="btn btn-sm btn-enento-secondary d-inline-block mb-4"
      >
        <span><i class="far fa-folder-open mr-2" />Bläddra bland filer</span>
        <input
          id="file"
          type="file"
          name="file"
          class="hidden"
          multiple="multiple"
          accept=".pdf,.tiff,.png,.gif,.jpg,.jpeg,image/*;capture=camera"
          @change="selectedFile"
        >
      </label>
    </div>
    <!-- Dropped file info -->
    <div v-if="droppedFile !== null && droppedFile.length">
      <div class="d-flex align-items-center mt-3">
        <p class="body-small font-weight-bold">
          Filnamn
        </p>
        <p class="body-small font-weight-bold ml-auto">
          Ta bort
        </p>
      </div>
      <ul class="file-list pl-0 mb-4">
        <li
          v-for="(file, index) in droppedFile"
          :key="`drop_zone_file_${index}`"
        >
          <span class="d-flex align-items-center mb-3">
            {{ file.name }}
            <i
              class="fas fa-times fa-fw color-darkocean hover-pointer ml-auto"
              @click="removeFile(file)"
            />
          </span>
        </li>
      </ul>
      <button
        class="w-100 btn btn-primary btn-upload"
        @click="uploadReport"
      >
        Skicka in polisrapport
      </button>
    </div>
  </div>
</template>
<script setup>
import useDropZone from './services/dropZone.js';
import { postUploadProtectionReport } from '../Scripts/providers/customerApiProvider';
import { getRequestVerificationToken } from '../utils/authUtils';

const emit = defineEmits(['failed', 'success']);

const { droppedFile, toggle_active, drop, selectedFile } = useDropZone();

const removeFile = (file) => {
    var filteredFiles = [];
    for (const item of droppedFile.value) {
        if (item.name != file.name) {
            filteredFiles.push(item);
        }
    }
    droppedFile.value = filteredFiles;
}
const uploadReport = () => {
    var csrfToken = getRequestVerificationToken();
    if(csrfToken === 'failed') {
        return emit('failed');
    }
    var formData = new FormData();
    for (const file of droppedFile.value) {
        formData.append(file.name, file);
    }
    postUploadProtectionReport({ csrfToken, formData }).then(({ data }) => {
        if (data.success) {
            return emit('success');
        }
        return emit('failed');

    }).catch(() => {
        return emit('failed');
    });
}
</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";

.btn-enento-secondary {
    border: 1px solid $gray;
    font-weight: 500;
    background-color: inherit;

    span {
        line-height: normal;
    }
}

.btn-upload {
    background-color: $dark-ocean;
}

.file-list {
    list-style-type: none;
}

.fa-upload {
    color: $orangegold;
}
</style>
