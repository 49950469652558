<template>
  <TabbedPageTemplate testId="dashboard-my-uc-score-page">
    <NdsCard v-if="pending" :heading="{ title: model.name, type: 'h3' }">
      <template #default>
        <div class="align-items-center justify-content-center d-flex">
          <NdsLoader />
        </div>
      </template>
    </NdsCard>
    <NdsCard v-else-if="failed" :heading="{ title: model.name, type: 'h3' }">
      <template #default>
        <Reloader @reload="getCreditInfo" />
      </template>
    </NdsCard>
    <template v-else>
      <NdsCardSpacing :gap="3" direction="column" center>
        <NdsCard variant="primary"
          :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'fas', iconName: 'analytics' } }">
          <UcScore v-if="customerCreditInfo.creditReport.creditwatchScoreList" size="lg" :marker-radius="25"
            :report="customerCreditInfo" />
        </NdsCard>
        <NdsCard>
          <div class="question-uc-score-wrapper">
            <i v-if="model.questionUCScore && model.questionUCScore.value !== ''" class="fal fa-lg fa-question-square"
              @click="onQuestionCardClicked" />
            <template v-if="customerCreditInfo.creditReport.creditScore === null">
              <p v-html="model.descriptionNoScoreGrade.value" />
            </template>
          </div>
          <NdsHeading :title="model.headline.value" type="h3" tight />
          <NdsText isRichText tight :text="model.description.value" />
          <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: UC" tight />
        </NdsCard>
      </NdsCardSpacing>
    </template>
  </TabbedPageTemplate>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { NdsLoader, NdsCard, NdsHeading, NdsCardSpacing, NdsFooter, NdsText } from '@nds/vue';
import $ from 'jquery';
import UcScore from '../components/charts/UcScore.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY } from '../Scripts/store/modules/customerCreditNotificationsContex';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import Reloader from '../components/Reloader.vue';

const props = defineProps({
  model: Object,
});

const store = useStore();
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH_AND_CLEAR_CATEGORY, { filterCategory: "UC_SCORE" });
  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}

const onQuestionCardClicked = () => {
  $('.question-uc-score-card-overlay').show();
}

</script>
