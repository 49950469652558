<template>
  <NdsTable :headers="[
    { title: 'Datum', value: 'dateValue', width: 120 },
    { title: 'Frågeställare', value: 'inquirerValue' },
    { title: 'Belopp', value: 'amountValue', width: 120, align: 'end' }
  ]" :items="inquiryTableItems" alternateRowColors compact />
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { NdsPagination, usePagination, NdsTable } from "@nds/vue";
import { formatToSek, FormatDate } from '../_helpers.js';

const props = defineProps({
  consumerInquiries: {
    type: Object,
    required: true
  }
});

const formattedInquiries = computed(() => (props.consumerInquiries.inquiries.map(inquiry => ({
  ...inquiry,
  date: inquiry.dateOfInquiry?.timeStamp.value ? FormatDate(inquiry.dateOfInquiry.timeStamp.value) : null,
  formattedAmount: inquiry.creditAmount === 0 ? '-' : formatToSek(inquiry.creditAmount)
}))));

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedInquiries,
} = usePagination(formattedInquiries);

const inquiryTableItems = computed(() =>
  paginatedInquiries.value.map(inquiry => ({
    dateValue: inquiry.date,
    inquirerValue: inquiry.nameInquirer,
    amountValue: inquiry.formattedAmount
  }))
);
</script>
