<template>
  <NdsHeading title="Bolån" type="h4" tight>
    <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
      @click="propertyLoanQuestionModal.openModal" aria-label="Bolån förklaring" />
  </NdsHeading>
  <div v-if="hasTerminatedMortgages" class="d-flex justify-content-end my-3">
    <NdsButton theme="enento" :prefix="{ prefix: 'far', iconName: showTerminated ? 'eye-slash' : 'eye' }"
      variant="secondary" @click="showTerminated = !showTerminated">
      {{ showTerminated ? 'Dölj avslutade' : 'Visa avslutade' }}
    </NdsButton>
  </div>
  <NdsAccordion v-if="paginatedMortgages.length > 0" v-model="activeAccordionId" :accordions="paginatedMortgages">
    <template v-for="(inquiry, index) in paginatedMortgages" #[inquiry.id] :key="index">
      <div class="p-3">
        <div class="row pb-3">
          <div class="col-8">Kreditlimit:</div>
          <div class="col-4 pl-0 text-right">{{ inquiry.grantedCredit }}</div>
          <div class="col-8 color-ocean">Utnyttjad kredit:</div>
          <div class="col-4 pl-0 color-ocean text-right">{{ inquiry.usedCredit }}</div>
        </div>
        <div class="row">
          <template v-if="inquiry.fromDate">
            <div class="col-8">Startdatum:</div>
            <div class="col-4 pl-0 text-right">{{ inquiry.fromDate }}</div>
          </template>
          <template v-if="!inquiry.toDate || inquiry.usedCredit > 0">
            <div class="col-8">Senast uppdaterad:</div>
            <div class="col-4 pl-0 text-right">{{ inquiry.updatedAtDate }}</div>
          </template>
          <template v-if="inquiry.toDate">
            <div class="col-8 color-red">Avslutad:</div>
            <div class="col-4 pl-0 color-red text-right">{{ inquiry.toDate }}</div>
          </template>
        </div>
      </div>
    </template>
  </NdsAccordion>
  <ZeroState v-else icon="far fa-sack-dollar" :text="model.descriptionNoPropertyLoanDevelopment.value || 'Inga bolån registrerade'" isRichText/>
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
  <div class="mt-4 mb-4">
    <NdsHeading title="Bolåneutveckling" type="h4" tight>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle"
        @click="mortageDevelopmentQuestionModal.openModal" aria-label="Bolåneutveckling förklaring" />
    </NdsHeading>
    <div class="ndsCreditChartContainer">
      <NdsCreditPlotChart v-if="isReady" title="" emptyTitle="Ingen bolåneutveckling är registrerad"
        :chartData="plotChartData" />
    </div>
  </div>
  <NdsFooter class="mt-5" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige" tight />
  <NdsConfirmModal v-bind="propertyLoanQuestionModal.props" v-model="propertyLoanQuestionModal.isOpen.value"
    @onConfirm="propertyLoanQuestionModal.closeModal" @onCancel="propertyLoanQuestionModal.closeModal" />
  <NdsConfirmModal v-bind="mortageDevelopmentQuestionModal.props" v-model="mortageDevelopmentQuestionModal.isOpen.value"
    @onConfirm="mortageDevelopmentQuestionModal.closeModal" @onCancel="mortageDevelopmentQuestionModal.closeModal" />
</template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { formatToSek, FormatDate } from '../_helpers.js';
import { NdsIconButton, NdsButton, NdsCreditPlotChart, NdsAccordion, NdsPagination, NdsConfirmModal, NdsHeading, NdsFooter, useConfirmModal, usePagination } from '@nds/vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
  model: Object,
  report: Object,
});

const showTerminated = ref(true);
const hasTerminatedMortgages = ref(false);
const isReady = ref(false);

onMounted(() => {
  isReady.value = true;
})

const formattedMortgages = computed(() => {
  if (props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
    return props.report.creditReport.ctiCreditsWithInquirer.creditInformations
      .filter((creditInfo) => {
        const isMortgage = [7, 9].includes(props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[creditInfo.creditNumber]);
        if (isMortgage && creditInfo.toDate && !hasTerminatedMortgages.value) {
          hasTerminatedMortgages.value = true;
        }
        return isMortgage;
      })
      .map((mortgage, index) => ({
        id: `mortgage ${index + 1}`,
        title: mortgage.creditor ?? "Okänd långivare",
        grantedCredit: mortgage.grantedCredit != null ? formatToSek(mortgage.grantedCredit) : null,
        usedCredit: mortgage.usedCredit != null ? formatToSek(mortgage.usedCredit) : null,
        fromDate: mortgage.fromDate?.timeStamp.value ? FormatDate(mortgage.fromDate.timeStamp.value) : null,
        toDate: mortgage.toDate?.timeStamp.value ? FormatDate(mortgage.toDate.timeStamp.value) : null,
        updatedAtDate: mortgage.updatedAtDate?.timeStamp.value ? FormatDate(mortgage.updatedAtDate.timeStamp.value) : null,
      }));
  }
  return [];
});

const filteredFormattedMortgages = computed(() => (formattedMortgages.value.filter(mortgage => (showTerminated.value ? true : !mortgage.toDate))));

const plotChartData = computed(() => ({
  snapshotLabel: 'Nuläge',
  hasSnapshot: true,
  label: 'Utnyttjad kredit',
  entries: props.report.mortgagesChartData.entries,
}));

const propertyLoanQuestionModal = useConfirmModal({
  title: props.model.questionPropertyLoanTitle.value || 'Bolån',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionPropertyLoan.value,
    isRichText: true,
  },
});

const mortageDevelopmentQuestionModal = useConfirmModal({
  title: props.model.questionPropertyLoanDevelopmentTitle.value || 'Bolåneutveckling',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.model.questionPropertyLoanDevelopment.value,
    isRichText: true,
  },
});

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedMortgages
} = usePagination(filteredFormattedMortgages);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
<style scoped>
.ndsCreditChartContainer {
  margin-top: 8px;
  width: 100%;
  height: 320px;
}
</style>