<template>
  <VuePopper arrow placement="bottom">
    <template #content="{ close }">
      <div :id="`popover-body-${product.productPackage.id}`" ref="content" class="payment-options-pooper-content">
        <ul class="payment__options-popover">
          <li v-if="product.paymentType === 'CARD'" class="">
            <NdsButton theme="enento" variant="text" @click="onChangeCard(close)">
              Byt kort
            </NdsButton>
          </li>
          <li v-if="product.paymentType == 'INVOICE'">
            <div class="options-link">
              <NdsLink target="_blank" href="https://customer.horizonafs.com/SE/uc"
                :suffix="{ prefix: 'far', iconName: 'external-link' }" @click="close">
                Gå till fakturaportal
              </NdsLink>
            </div>
          </li>
          <li>
            <div class="options-link">
              <NdsLink target="_blank"
                :href="`/minuc/betalningar/createreceiptpdf?pId=${product.productPackage.id}&pDate=${product.purchasedDate}&orderId=${product.id}`"
                :suffix="{ prefix: 'far', iconName: 'file-alt' }" @click="close">
                Kvitto
              </NdsLink>
            </div>
          </li>
          <li>
            <NdsButton theme="enento" variant="text" @click="onTerminateProduct(close)"
              :disabled="canTerminateProduct({ product, isPendingRef: terminateProductPending })">
              Avsluta tjänst
            </NdsButton>
          </li>
        </ul>
      </div>
    </template>
    <NdsIconButton :id="`popover-trigger-${product.id}`" ref="caller" size="large"
    theme="enento" variant="text" prefix="far" iconName="ellipsis-h" aria-label="payment options"/>
  </VuePopper>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { NdsLink, NdsButton, NdsIconButton } from '@nds/vue';
import VuePopper from "vue3-popper";
import { canTerminateProduct } from '../utils/productUtils';

defineProps({
  product: Object,
});

const store = useStore();

const terminateProductPending = computed(() => store.state.customerInfoContex.pending);
const emit = defineEmits(['onChangeCard', 'onTerminateProduct'])

const onChangeCard = (close) => {
  emit('onChangeCard');
  close();
}

const onTerminateProduct = (close) => {
  emit('onTerminateProduct');
  close();
}
</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";

.payment-options-pooper-content {
  font-family: Roboto;

  ul {
    list-style-type: none;
    padding: 12px;
    margin: 0;

    li {
      border-radius: 6px;

      .options-link {
        padding: 6px 16px;
      }

    }
  }

}
</style>