<template>
  <div
    class="signuplink"
    data-testid="signup-link-block"
  >
    <div
      v-if="!isLoggedin"
      class="signuplink-form"
    >
      <label
        class="input-label"
        for="email"
      >{{ formModel.email.label }}</label>
      <div class="signuplink-content">
        <input
          id="email"
          v-model="form.email"
          type="text"
          class="form-control"
          :class="v$.email.$error ? 'is-invalid' : ''"
          :placeholder="formModel.email.placeholder"
          :aria-describedby="formModel.email.ariaLabel"
          @keyup="onDataChange"
        >
        <small
          v-if="v$.email.required.$invalid && dataChanged"
          class="is-invalid"
        >
          {{ v$.email.required.$message }}
        </small>
        <small
          v-else-if="v$.email.email.$invalid && dataChanged"
          class="is-invalid"
        >
          {{ v$.email.email.$message }}
        </small>
      </div>
    </div>
    <button
      class="signup-link-button btn btn-standard"
      @click="onSubmit"
    >
      {{ model.submitButtonLabel }}
    </button>
  </div>
</template>
<script setup>
import { computed, reactive, onMounted, ref, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useStore } from 'vuex';
import { required, email, helpers } from '@vuelidate/validators';
import { CHECKOUT_SET_CUSTOMER } from '../../Scripts/store/modules/checkoutContext';
import helperTexts from '../../../../json/helperTexts.json';

const props = defineProps({
    model: Object,
});

const store = useStore();
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerEssentialsLoaded = computed(() => store.state.customerInfoContex.loaded);
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const signupDisplayCSS = computed(() => store.state.appContext.isLoggedin ? 'block' : 'flex');
const buttonTopMarginCSS = computed(() => store.state.appContext.isLoggedin ? '20px' : '0');
const dataChanged = ref(false);
const formModel = {
    email: {
        label: 'E-post',
        placeholder: helperTexts.placeholderEmail,
        ariaLabel: 'emailHelp',
    },
};
const form = reactive({
    email: '',
});
const rules = computed(() => {
    return {
        email: {
            required: helpers.withMessage(helperTexts.validationMessageRequired, required),
            email: helpers.withMessage(helperTexts.validationMessageEmail, email),
        },
    }
})

const v$ = useVuelidate(rules, form);

onMounted(() => {
    if (store.state.customerInfoContex.loaded) {
        form.email = customerEssentials.value.customerEmail;
    }
});

watch(customerEssentialsLoaded, () => {
    if (customerEssentialsLoaded.value) {
        form.email = customerEssentials.value.customerEmail;
    }
})

const onDataChange = () => {
    if (!v$.value.$error) {
        dataChanged.value = true;
    } else {
        dataChanged.value = false;
    }
};

const onSubmit = (e) => {
    e.preventDefault();
    if (!isLoggedin.value) {
        store.dispatch(CHECKOUT_SET_CUSTOMER, {
            email: form.email,
            ssn: '',
            phoneNumber: '',
        });
    }
    //router.push({ path: props.model.landingPageUrl }); // TODO fix using this when </router-view> is added on root page
    return window.location.href = props.model.landingPageUrl;
}
</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_breakpoints.scss";
@import "../../../../styles/abstracts/_mixins.scss";

.signuplink {
    display: v-bind(signupDisplayCSS);
    gap: .5rem;
    min-height: 90px;
    height: 100%;

    @include breakpoint(max-tablet-portrait) {
        flex-direction: column;
    }

    .signuplink-form {
        flex-grow: 1;


        .input-label {
            font-size: 12px;
            width: 100%;
            @include truncate();
            margin: 0;
        }

        .signuplink-content {
            padding-top: 1px;
        }

        .form-control {
            border-radius: 6px;
            border: none;
        }
    }

    button {
        top: 32px;
        position: relative;
        height: 40px;
        @include truncate();

        @include breakpoint(max-tablet-portrait) {
            top: v-bind(buttonTopMarginCSS);
        }
    }
}
</style>
