<template>
  <footer class="py-20">
    <div class="container">
      <div class="row">
        <div
          v-if="model.questionsAndAnswers"
          class="col-md-7 col-lg-9 pb-20"
        >
          <h3
            v-if="model.questionsAndAnswers.title"
            class="gilroy-bold mb-3"
          >
            {{
              model.questionsAndAnswers.title }}
          </h3>
          <a
            :href="model.questionsAndAnswers.url"
            class="footer__iconlink-wrapper"
          >
            <i class="fal fa-comments color-sand mr-2 fa-fw" />
            <p class="mb-0 d-inline-flex">{{ model.questionsAndAnswers.urlName }}</p>
          </a>
        </div>
        <div class="col-md-5 col-lg-3 pb-20">
          <h3
            v-if="model.socialMediaHeadline"
            class="mb-3"
          >
            {{ model.socialMediaHeadline }}
          </h3>
          <div class="row">
            <div class="col-md-3 d-inline-flex">
              <a
                v-for="(socialMedia, index) in model.listOfSocialMediaBlocks"
                :key="`social_media_${index}`"
                :href="socialMedia.url"
                class="mr-3"
              >
                <div :class="socialMedia.text ? 'footer__icontext-wrapper' : 'footer__icon-wrapper'">
                  <i
                    v-if="socialMedia.fACode"
                    :class="socialMedia.fACode"
                  />
                  <p
                    v-if="socialMedia.text"
                    class="mb-0 d-inline-flex"
                  >{{ socialMedia.text }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="d-none d-md-block col-md-7 col-lg-3 pb-20">
          <h4 class="gilroy-bold mb-3">
            {{ model.productPagesHeadline }}
          </h4>
          <LinkList
            v-if="model.productLinks"
            :model="{ links: model.productLinks, keytitle: 'product_links' }"
          />
        </div>
        <div class="d-none d-md-block col-md-5 col-lg-3 pb-20">
          <h4 class="gilroy-bold mb-3">
            {{ model.companyInformationPagesHeadline }}
          </h4>
          <LinkList
            v-if="model.companyInformationLinks"
            :model="{ links: model.companyInformationLinks, keytitle: 'company_information_links' }"
          />
        </div>
        <div class="d-none d-md-block col-md-7 col-lg-3 pb-20">
          <h4 class="gilroy-bold mb-3">
            {{ model.newsPagesHeadline }}
          </h4>
          <LinkList
            v-if="model.newsPagesLinks"
            :model="{ links: model.newsPagesLinks, keytitle: 'news_pages_links' }"
          />
        </div>
        <div class="d-none d-md-block col-md-5 col-lg-3 pb-20">
          <h4 class="gilroy-bold mb-3">
            {{ model.customerServicePagesHeadline }}
          </h4>
          <LinkList
            v-if="model.customerServiceLinks"
            :model="{ links: model.customerServiceLinks, keytitle: 'customer_service_links' }"
          />
        </div>
      </div>
      <div class="block__enento__accordion block__enento__accordion-footer d-md-none">
        <LinkPanelList
          v-if="model.productLinks"
          :model="{
            title: model.productPagesHeadline,
            links: model.productLinks,
            keytitle: 'product_links_mobile',
            accordianId: 'productpages-accordion',
          }"
        />
        <LinkPanelList
          v-if="model.companyInformationLinks"
          :model="{
            title: model.companyInformationPagesHeadline,
            links: model.companyInformationLinks,
            keytitle: 'company_information_links_mobile',
            accordianId: 'companyinformationpages-accordion',
          }"
        />
        <LinkPanelList
          v-if="model.newsPagesLinks"
          :model="{
            title: model.newsPagesHeadline,
            links: model.newsPagesLinks,
            keytitle: 'news_pages_links_mobile',
            accordianId: 'newspages-accordion',
          }"
        />
        <LinkPanelList
          v-if="model.customerServicePagesHeadline"
          :model="{
            title: model.customerServicePagesHeadline,
            links: model.customerServiceLinks,
            keytitle: 'customer_service_mobile_links',
            accordianId: 'customerservicepages-accordion',
          }"
        />
      </div>
      <div class="text-center pt-5 pt-md-0">
        <template v-if="model.showPaymentOptions">
          <img
            class="payment__card-img mr-1 mr-sm-4"
            src="/Static/img/cards/Swish.png"
          >
          <img
            class="payment__card-img mr-1 mr-sm-4"
            src="/Static/img/cards/MastercardBackground.png"
          >
          <img
            class="payment__card-img mr-1 mr-sm-4"
            src="/Static/img/cards/VisaBackground.png"
          >
          <img
            class="payment__card-img"
            src="/Static/img/cards/NetsBackground.png"
          >
        </template>
        <div
          class="d-flex align-items-center justify-content-center"
          :class="model.showPaymentOptions ? 'py-4' : 'pb-4'"
        >
          <a
            :href="model.logotypeLinkUrl"
            :title="model.logotypeTitle"
            class="d-inline-flex mr-3"
          >
            <img
              class="footer__logo"
              src="/Static/svg/uc-neg.svg"
            >
          </a>
          <p class="footer__logo-text d-inline-flex mb-0 mr-0 mr-md-5">
            © UC AB Sverige, part of Enento Group
          </p>

          <div
            v-if="model.footerLinks || model.handleCookiesButtonHeader"
            class="d-none d-md-flex"
          >
            <button
              v-if="model.handleCookiesButtonHeader"
              id="handleCookiesOpenButton"
              class="footer__bottom-button footer__bottom-link"
              @click="onCookieConsentLinkButtonClicked"
            >
              {{
                model.handleCookiesButtonHeader
              }}
            </button>
            <a
              v-for="(footerLink, index) in model.footerLinks"
              :key="`footer_link_${index}`"
              :href="footerLink.url"
              class="footer__bottom-link"
            >{{ footerLink.text }}</a>
          </div>
        </div>
        <div
          v-if="model.footerLinks || model.handleCookiesButtonHeader"
          class="d-md-none"
        >
          <button
            v-if="model.handleCookiesButtonHeader"
            id="handleCookiesOpenButton"
            class="footer__bottom-button footer__bottom-link"
            @click="onCookieConsentLinkButtonClicked"
          >
            {{
              model.handleCookiesButtonHeader }}
          </button>
          <a
            v-for="(footerLink, index) in model.footerLinks"
            :key="`footer_mobile_link_${index}`"
            :href="footerLink.url"
            class="footer__bottom-link"
          >{{ footerLink.text }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import LinkList from './LinkList.vue';
import LinkPanelList from './LinkPanelList.vue';
import useCookieConsent from '../utils/cookieConsentUtils';

defineProps({
    model: Object,
});

const { onCookieConsentLinkButtonClicked } = useCookieConsent();
</script>
<style lang="scss">
@import "../../../styles/abstracts/_variables.scss";

.cookie-consent-link-button {
    background: none;
    color: $dark-ocean;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    &:hover {
        text-decoration: underline;
    }
}
</style>