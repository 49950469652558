//newcreditwatchapi
import axios from 'axios';

const get = ({ url, headers }) => {
    return axios({
        method: 'get',
        url: url,
        headers: Object.assign({}, headers)
    });
};

const basePath = '/newcreditwatchapi';
const getCreditReportPath = `${basePath}/getcreditreport`;
const getNewNotificationsPath = `${basePath}/getnewnotifications`;
const getFilteredNotificationsPath = `${basePath}/getfilterednotifications`;
const getSetNotificationStatusPath = (id) => `${basePath}/SetNotificationStatus/${id}`;

export const getCreditReport = () => get({ url: getCreditReportPath, headers: { Accept: 'application/json' } });
export const getNewNotifications = () => get({ url: getNewNotificationsPath, headers: { Accept: 'application/json' } });
export const getSetNotificationStatus = (id) => get({ url: getSetNotificationStatusPath(id), headers: { Accept: 'application/json' } });
export const getFilteredNotifications = () => get({ url: getFilteredNotificationsPath, headers: { Accept: 'application/json' } });