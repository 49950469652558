<template>
  <NdsAccordion v-model="activeAccordionId" :accordions="paginatedComplaints">
    <template v-for="(complaint, index) in paginatedComplaints" #[complaint.id] :key="index">
      <div class="d-flex flex-column p-3">
        <div class="d-flex align-items-center mb-1">
          <span class="color-emperor font-weight-medium mr-auto">Datum:</span>
          <span class="text-right">{{ complaint.date }}</span>
        </div>
        <div class="d-flex align-items-center mb-3">
          <span class="color-emperor font-weight-medium mr-auto">Fordringsägare, övrig info:</span>
          <span class="text-right">{{ complaint.creditorInfo }}</span>
        </div>
        <div class="d-flex align-items-center">
          <span class="color-emperor font-weight-medium mr-auto">Belopp:</span>
          <span class="text-right">{{ complaint.amount }}</span>
        </div>
        <div v-if="showEndDate" class="d-flex align-items-center mt-1">
          <span class="color-emperor font-weight-medium mr-auto">Försvinner:</span>
          <span class="text-right">{{ complaint.endDate }}</span>
        </div>
      </div>
    </template>
  </NdsAccordion>

  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { FormatDate, formatToSek } from '../_helpers.js';
import { NdsAccordion, NdsPagination, usePagination } from "@nds/vue";

const props = defineProps({
  complaints: Array,
  showEndDate: Boolean
});

const formattedComplaints = computed(() => (props.complaints.map((complaint, index) => ({
  id: `Complaint ${index + 1}`,
  title: `Anmärkning ${FormatDate(complaint.complaintDate?.timeStamp.value)}`,
  subtitle: complaint.complaintTypeText || '',
  date: complaint.complaintDate?.timeStamp.value ? FormatDate(complaint.complaintDate.timeStamp.value) : '-',
  creditorInfo: complaint.creditor || '-',
  amount: complaint.complaintAmount ? formatToSek(complaint.complaintAmount) : '-',
  endDate: complaint.complaintEndDate?.timeStamp.value ? FormatDate(complaint.complaintEndDate.timeStamp.value) : '-',
}))));

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedComplaints,
} = usePagination(formattedComplaints);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
