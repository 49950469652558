<template>
  <div data-testid="minupplysning-credits-tab">
    <NdsHeading title="Kreditengagemang" type="h3" :prefix="{ prefix: 'far', iconName: 'sack-dollar' }" tight />
    <p v-if="shouldShowIntroText" v-html="introText" />
    <div v-if="shouldShowCreditSummary" class="row px-2 mb-4">
      <div class="col-3 px-3 py-2">Totalt utnyttjat kredit</div>
      <div class="col-3 px-3 py-2 color-ocean font-weight-medium"
        data-testid="min-upplysning-credits-total-used-amount">
        {{ totalUsedCredits }}
      </div>
      <div class="col-3 px-3 py-2">Antal krediter</div>
      <div class="col-3 px-3 py-2 font-weight-medium" data-testid="min-upplysning-credits-nr-of-credits">
        {{ totalNumberOfCredits }}
      </div>
      <div class="col-3 px-3 py-2">Totalt beviljad kredit</div>
      <div class="col-3 px-3 py-2 color-ocean font-weight-medium"
        data-testid="min-upplysning-credits-total-granted-amount">
        {{ totalGrantedCredits }}
      </div>
      <div class="col-3 px-3 py-2">Antal kreditgivare</div>
      <div class="col-3 px-3 py-2 font-weight-medium" data-testid="min-upplysning-credits-nr-of-creditors">
        {{ totalNumberOfCreditors }}
      </div>
    </div>
    <div v-if="shouldShowCreditSummary" class="py-2 pb-3 mb-3 d-none d-xl-block">
      <NdsCard outlined tight>
        <template #default>
          <NdsTabs v-model="creditsTabModel" :tabs="creditsTabs" @back="onBack" @forward="onForward">
            <template #blancoTab>
              <CreditsTab :credit-type="5" :report="report" title="Blanco" />
            </template>
            <template #downpaymentTab>
              <CreditsTab :credit-type="4" :report="report" title="Avbetalningar" />
            </template>
            <template #creditCardTab>
              <CreditsTab :credit-type="1" :report="report" title="Kontokredit" />
            </template>
            <template #propertyTab>
              <CreditsTab :credit-type="7" :report="report" title="Fastighet" />
            </template>
            <template #tenantOwnershipTab>
              <CreditsTab :credit-type="9" :report="report" title="Bostadsrätt" />
            </template>
            <template #studentLoanTab>
              <Csn v-if="report.creditReport.ctiCsnLoan" :cti-csn-loan="report.creditReport.ctiCsnLoan" />
              <ZeroState v-else icon="far fa-graduation-cap" text="Inget CSN-lån registrerat"
                data-testid="min-upplysning-credits-csn-zerostate" />
              <NdsFooter class="pt-3" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: CSN" tight />
            </template>
          </NdsTabs>
        </template>
      </NdsCard>
    </div>
    <ZeroState v-else icon="far fa-sack-dollar" text="Ingen kredit registrerad" />
    <NdsFooter class="pt-3" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: Kreditgivare i Sverige" tight />
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { NdsCard, NdsTabs, NdsHeading, NdsFooter, useTabs } from '@nds/vue';
import { formatToSek, formatToAmount } from '../../_helpers.js';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import CreditsTab from '../../components/myinformation/CreditsTab.vue';
import Csn from '../../components/Csn.vue';

const props = defineProps({
  report: Object,
  introText: String
});

const creditsTabs = computed(() => ([
  { id: 'blancoTab', name: 'Blanco' },
  { id: 'downpaymentTab', name: 'Avbetalningar' },
  { id: 'creditCardTab', name: 'Kontokredit' },
  { id: 'propertyTab', name: 'Fastighet' },
  { id: 'tenantOwnershipTab', name: 'Bostadsrätt' },
  { id: 'studentLoanTab', name: 'CSN-lån' },
]));

const { tabModel: creditsTabModel, onBack, onForward, setCurrentTab } = useTabs({
  tabs: creditsTabs,
  defaultTabId: "blancoTab",
});

const shouldShowCreditSummary = computed(() => props.report.creditReport.ctiCreditSummary || props.report.creditReport.ctiCsnLoan);
const shouldShowIntroText = computed(() => shouldShowCreditSummary.value && props.introText);


const getCreditSummary = (creditKey) => props.report.creditReport.ctiCreditSummary && props.report.creditReport.dailyCreditSummary?.creditSummaries?.[0]?.[creditKey] ? props.report.creditReport.dailyCreditSummary.creditSummaries[0][creditKey] : 0;

const totalUsedCredits = computed(() => formatToSek(getCreditSummary('usedCredits')));
const totalNumberOfCredits = computed(() => formatToAmount(getCreditSummary('numberOfCredits')));
const totalGrantedCredits = computed(() => formatToSek(getCreditSummary('grantedCredits')));
const totalNumberOfCreditors = computed(() => formatToAmount(getCreditSummary('numberOfCreditors')));

onMounted(() => {
  if (props.report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
    props.report.creditReport.ctiCreditsWithInquirer.creditInformations.some((item) => {
      const creditType = props.report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber];
      if (creditType === 5) {
        setCurrentTab('blancoTab');
        return true;
      } else if (creditType === 4) {
        setCurrentTab('downpaymentTab');
        return true;
      } else if (creditType === 1) {
        setCurrentTab('creditCardTab');
        return true;
      } else if (creditType === 7) {
        setCurrentTab('propertyTab');
        return true;
      } else if (creditType === 9) {
        setCurrentTab('tenantOwnershipTab');
        return true;
      }
      return false;
    });
  } else if (props.report.creditReport.ctiCsnLoan) {
    setCurrentTab('studentLoanTab');
  }
});

</script>