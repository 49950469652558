<template v-if="ctiConsumerProperties">
  <NdsAccordion v-model="activeAccordionId" :accordions="paginatedProperties">
    <template v-for="property in paginatedProperties" #[property.id] :key="property.id">
      <NdsList :items="property.items" variant="borderless" />
    </template>
  </NdsAccordion>
  <div v-if="totalPages > 1" class="mt-4">
    <NdsPagination :length="totalPages" v-model="currentPage" />
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import { NdsAccordion, NdsPagination, NdsList, usePagination } from '@nds/vue';
import { NumberFormat, FormatDate } from '../_helpers.js';

const props = defineProps({
  ctiConsumerProperties: Object,
});

const formatPropertyItemsList = (property, index) => [
  { id: `municipality-${index}`, title: "Kommun", subtitle: property.municipality },
  property.propertyTypeText && { id: `property-type-${index}`, title: "Fastighetstyp", subtitle: property.propertyTypeText },
  property.areal && { id: `areal-${index}`, title: "Areal", subtitle: `${NumberFormat(property.areal)} ${property.arealTypeText}` },
  property.ownedShare && { id: `owned-share-${index}`, title: "Ägarandel", subtitle: `${NumberFormat(property.ownedShare)}%` },
  property.purchaseDate?.timeStamp?.value && {
    id: `purchase-date-${index}`, title: "Förvärvsdatum", subtitle: FormatDate(property.purchaseDate.timeStamp.value),
  },
  property.taxAssessedValue && { id: `tax-value-${index}`, title: "Taxeringsvärde", subtitle: `${NumberFormat(property.taxAssessedValue)} 000 kr` },
].filter(Boolean);

const formattedProperties = computed(() =>
  props.ctiConsumerProperties.consumerPropertyDetails.map((property, index) => ({
    id: `property-${index + 1}`,
    title: property.propertyName,
    items: formatPropertyItemsList(property, index),
  }))
);

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedProperties,
} = usePagination(formattedProperties);

const activeAccordionId = ref([]);

watch(currentPage, () => {
  activeAccordionId.value = [];
});
</script>
