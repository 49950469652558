<template>
  <div>
    <div id="credits-table" class="col-12 dashboard__table" data-testid="min-upplysning-inquiry-table">
      <div class="row dashboard__table-header">
        <div class="col-4 dashboard__table-column">
          <h5 class="mb-0">Datum</h5>
        </div>
        <div class="col-5 dashboard__table-column">
          <h5 class="mb-0">Frågeställare</h5>
        </div>
        <div class="col-3 dashboard__table-column text-right">
          <h5 class="mb-0">Belopp</h5>
        </div>
      </div>

      <div v-for="(inquiry, index) in paginatedInquiries" :key="`consumer_inquires_${index}`" class="row">
        <div class="col-4 col-lg-4 dashboard__table-column" :data-testid="`min-upplysning-inquiry-table-date-${index}`">
          {{ inquiry.date }}
        </div>
        <div class="col-4 col-lg-5 dashboard__table-column"
          :data-testid="`min-upplysning-inquiry-table-inquirer-${index}`">
          {{ inquiry.nameInquirer }}
        </div>
        <div class="col-4 col-lg-3 dashboard__table-column font-weight-medium text-right"
          :data-testid="`min-upplysning-inquiry-table-amount-${index}`">
          {{ inquiry.formattedAmount }}
        </div>
      </div>
    </div>

    <div v-if="totalPages > 1" class="mt-4">
      <NdsPagination :length="totalPages" v-model="currentPage" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { NdsPagination, usePagination } from "@nds/vue";
import { formatToSek, FormatDate } from '../_helpers.js';

const props = defineProps({
  consumerInquiries: {
    type: Object,
    required: true
  }
});

const formattedInquiries = computed(() => (props.consumerInquiries.inquiriesMinucOnly.map(inquiry => ({
  ...inquiry,
  date: inquiry.dateOfInquiry?.timeStamp.value ? FormatDate(inquiry.dateOfInquiry.timeStamp.value) : null,
  formattedAmount: inquiry.creditAmount === 0 ? '-' : formatToSek(inquiry.creditAmount)
}))));

const {
  currentPage,
  totalPages,
  paginatedItems: paginatedInquiries,
} = usePagination(formattedInquiries);
</script>
