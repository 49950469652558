import $ from 'jquery';
import 'jquery.cookie';
import tipsLenght from './_tips.js';
var faqListing = faqListing || {};
var responsiveNav = responsiveNav || {};
var dataMonitor = dataMonitor || {};
var redirectPage = redirectPage || {};
var salesforceForm = salesforceForm || {};

var dmCookieName = 'datamonitor';

$(document).ready(function () {

    var $rmArea = $('.readmore-area');
    var $rmTrigger = $('.readmore-trigger .trigger');

    var $teaserImage = $('.productteaserblock img.teaserimage');

    var $conditionsInformation = $('.conditions-information');

    var $discountInput = $('.discount-input');

    var $oldEventsContainer = $('.old-events-container');

    var $progressBar = $('.progress .progress-bar');

    var $topScroll = $('.js-topscroll');

    //MOVE PRODUCT IMAGES
    function moveProductImages() {
        if ($(window).width() < 768) {
            $($teaserImage).each(function () {
                $(this).prependTo($(this).prev().children().children('.image'));
            });
        } else {
            $($teaserImage).each(function () {
                $(this).appendTo($(this).closest('.row'));
            });
        }
    }

    //TOGGLE CONDITIONS
    $conditionsInformation.hide();
    $(document.body).on("click", ".toggle-conditions", function (event) {
        event.preventDefault();

        if ($(".conditions-information").is(":visible")) {
            $(".conditions-information").slideUp();
        } else {
            $(".conditions-information").slideDown();
        }

    });

    //READ MORE
    $rmArea.hide();
    $rmTrigger.on('click', function (event) {
        event.preventDefault();

        if ($(this).parent().prev($rmArea).is(':visible')) {
            var offset = $(this).parent().parent().offset();

            $('html, body').animate({
                scrollTop: offset.top - 20
            }, 'slow', function () {
            });

            $(this).removeClass('active').addClass('inactive').children('.text').html('Läs mer');
            $(this).parent().prev($rmArea).slideUp('slow');
            $(this).children('.icon').css('transform', '');

        } else if ($(this).parent().prev($rmArea).is(':hidden')) {
            $(this).removeClass('inactive').addClass('active').children('.text').html('Stäng');
            $(this).parent().prev($rmArea).slideDown();
            $(this).children('.icon').css('transform', 'rotate(45deg)');
        }
    });

    // FAQ LIST

    $(".faq-list .question .toggle").each(function () {
        $(this).hide();
    });
    $(this).removeClass('active').addClass('inactive').children('.text').html('Läs mer');
    $(this).parent().prev($rmArea).slideUp('slow');
    $(this).children('.icon').css('transform', '');

    $(".faq-list h3.title, .faq-list .icon, .faq-list .text").on("click", function (event) {

        event.preventDefault();

        if ($(this).parent().children(".toggle").is(":visible")) {
            $(this).parent().children(".toggle").slideUp();
            $(this).parent().children(".icon").removeClass("active");
            $(this).parent().children(".icon").css("transform", "");
            $(this).parent().children(".text").html("Läs mer");
        } else if ($(this).parent().children(".toggle").is(":hidden")) {
            $(this).parent().children(".toggle").slideDown();
            $(this).parent().children(".icon").addClass("active");
            $(this).parent().children(".icon").css("transform", "rotate(45deg)");
            $(this).parent().children(".text").html("Stäng");
        } else if ($(this).parent().prev($rmArea).is(':hidden')) {
            $(this).removeClass('inactive').addClass('active').children('.text').html('Stäng');
            $(this).parent().prev($rmArea).slideDown();
            $(this).children('.icon').css('transform', 'rotate(45deg)');
        }

    });

    //TOGGLE DISCOUNT
    $discountInput.hide();
    $(document.body).on("click", ".discount-toggle", function () {
        if ($(this).next($discountInput).is(':visible')) {
            $discountInput.slideUp();
            $(this).children('.icon').addClass('active');
            $(this).children('.icon').css('transform', '');
        } else {
            $discountInput.slideDown();
            $(this).children('.icon').addClass('active');
            $(this).children('.icon').css('transform', 'rotate(45deg)');
        }
    });

    // Filter long list of events
    $('.service-activities.old-events ul li:gt(2)').hide();
    if ($('.service-activities.old-events ul li').length > 3) {
        $('.service-activities.old-events .show-more').addClass('hidden-events');
    } else {
        $('.service-activities.old-events .show-more').hide();
    }
    $('.service-activities.old-events .show-more').on('click', function (event) {
        event.preventDefault();
        $('.service-activities.old-events ul li:gt(2)').fadeIn();
        $(this).hide();
    });
    if ($('.service-activities.old-events ul li').length < 1) {
        $oldEventsContainer.hide();
    }

    // Filter long list of messages
    $('.service-activities.old-messages ul li:gt(2)').hide();
    if ($('.service-activities.old-messages ul li').length > 3) {
        $('.service-activities.old-messages .show-more').addClass('hidden-messages');
    } else {
        $('.service-activities.old-messages .show-more').hide();
    }
    $('.service-activities.old-messages .show-more').on('click', function (event) {
        event.preventDefault();
        $('.service-activities.old-messages ul li:gt(2)').fadeIn();
        $(this).hide();
    });

    //PROGRESS ANIMATION
    $progressBar.css('width', function () {
        return $(this).attr('aria-valuenow') + '%';
    });

    //TOP SCROLL
    $topScroll.on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });

    responsiveNav = {
        init: function () {
            var max767px = window.matchMedia('(max-width: 767px)').matches;

            if (max767px) {

                $('.responsive-nav').each(function () {
                    let $nav = $(this);
                    let $nextElm = $nav.next();

                    if (!$nextElm.hasClass('custom-select')) {
                        let $customSelect = $('<div class="custom-select" />');
                        let $select = $('<select />');
                        let $firstOption = $('<option value="" disabled selected>Välj</option>');
                        $select.append($firstOption);

                        $nav.find('a').each(function () {
                            let $link = $(this);
                            var $option = $('<option />');
                            $option.attr('value', $link.attr('href')).html($link.html());
                            $select.append($option);
                        });

                        $select.change(function () {
                            let url = $(this).find('option:not(:eq(0)):selected').val();
                            if (typeof url !== 'undefined' && url !== '-1') {
                                window.location = url;
                            }
                        });

                        $customSelect.append($select);

                        $nav.hide();
                        $customSelect.insertAfter($nav);
                    } else {
                        $nav.hide();
                        $nextElm.show();
                    }
                });
            } else {
                responsiveNav.reset();
            }
        },
        reset: function () {
            $('.responsive-nav').each(function () {
                let $nav = $(this);
                let $nextElm = $nav.next();

                if ($nextElm.hasClass('custom-select')) {
                    $nextElm.hide();
                }
                $nav.show();
            });
        }
    };

    //RESIZE
    var resizeTimer;

    function resizeFunction() {
        tipsLenght();
        moveProductImages();
        responsiveNav.init();
    }

    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(resizeFunction, 50);
    });
    resizeFunction();

    //ADD CARD AND ACTIVATE PAYMENT
    var $activatePayment = $('#add-card-activate-payment');
    $activatePayment.on('submit', function (ev) {
        ev.preventDefault();
        var formData = $(this).serialize();

        $.ajax({
            data: formData,
            url: $activatePayment.attr('action'),
            method: $activatePayment.attr('method'),
            processData: false,
            success: function () {
                $('#update-card-info-a').removeClass('hidden').slideDown(250).delay(2000).slideUp(250);
            },
            error: function () {
                $('#update-card-info-b').removeClass('hidden').slideDown(250).delay(2000).slideUp(250);
            }
        });
    });

    let $userServices = $('.user-services');

    if ($userServices.length > 0) {
        let originalUrl = window.location.href;
        let hash = window.location.hash;

        if (hash.length > 0) {
            originalUrl = originalUrl.replace(hash, '');
        }

        let $firstActive = $userServices.find('.service.active:not(.direct-link)').first();

        if ($firstActive.length > 0) {
            $firstActive.parent().parent().addClass('active');
            let $href = $firstActive.parent().attr('href');

            if (typeof $href !== 'undefined') {
                let $content = $($href);

                if (hash.length === 0) {
                    history.pushState(null, null, originalUrl + $href);
                }

                $content.addClass('in active');
            }
        }

        let $navTabs = $userServices.find('.nav [data-toggle="tab"]');

        $navTabs.on('show.bs.tab', function (e) {
            if ($(e.target).find('.service.active').length === 0) {
                e.preventDefault();
            }
        });

        let url = window.location.href;
        if (url.match('#')) {
            $userServices.find('.nav [href="#' + url.split('#')[1] + '"]').tab('show');
        }

        $(window).on('hashchange', function () {
            let hash = window.location.hash;
            $userServices.find('.nav [href="' + hash + '"]').tab('show');
        });

        $navTabs.on('shown.bs.tab', function (e) {
            e.preventDefault();
            let $target = $(e.target);
            let $href = $target.attr('href');
            if ($target.find('.service.active').length > 0) {
                $userServices.find('.nav [href="' + $href + '"]').tab('show');
            }
            history.pushState(null, null, originalUrl + $href);
        });

        // bootstrap 3 tabs prevents link clicks inside tab, fix this
        $userServices.find('.nav .btn-standard').click(function () {
            let $link = $(this);
            window.location.href = $link.attr('href');
        });
    }

    faqListing = {
        init: function () {
            let $faq = $('.js-faq-listing');

            if ($faq.length > 0) {
                let $originalUrl = $faq.data('ownerpageUrl') + '/';

                let $links = $('.panel-heading a');
                let currentHref = window.location.href;

                // get last part of url since parent page url is in href attribute
                let currentUrlSegment = currentHref.substr(currentHref.lastIndexOf('/'));

                let $parentFaq;

                let $activeItem = $links.filter(function () {
                    let $link = $(this);
                    let href = $link.attr('href');
                    let hrefSegment = href.substr(href.lastIndexOf('/'));
                    return hrefSegment === currentUrlSegment;
                });

                if ($activeItem.length > 0) {
                    $parentFaq = $activeItem.parents('.js-faq-listing').first();
                    faqListing.toggle($parentFaq, $links, $activeItem, $originalUrl);
                    $('html,body').animate({scrollTop: $activeItem.offset().top}, 'slow');
                }

                $links.click(function (e) {
                    e.preventDefault();
                    let $link = $(this);
                    $parentFaq = $link.parents('.js-faq-listing').first();
                    faqListing.toggle($parentFaq, $links, $link, $originalUrl);
                });
            }
        },
        toggle: function ($activeFaq, $links, $elm, $originalUrl) {
            let $this = $elm;
            console.log($this);

            // reset
            $activeFaq.find('.in').removeClass('in');
            $activeFaq.find('.toggle').addClass('collapsed');

            let $isSubFaq = $activeFaq.hasClass('sub');
            let $allFaqs = $('.js-faq-listing');
            let hasSubFaqAsParent;

            if (!$isSubFaq) {
                hasSubFaqAsParent = $this.parents('.js-faq-listing.sub').length > 0;
                if (hasSubFaqAsParent) {
                    $this.parents('.js-faq-listing').first().find('.in').removeClass('in');
                } else {
                    let $otherFaqs = $allFaqs.filter(function () {
                        let $faq = $(this);
                        return $faq.attr('id') !== $activeFaq.attr('id');
                    });
                    $otherFaqs.find('.in').removeClass('in');
                }
            }

            let $inactiveLinks = $links.filter(function () {
                let $link = $(this);
                return $link.attr('href') !== $this.attr('href');
            });

            let activeHref = $this.attr('href');
            let targetHref, targetHrefSplit, relatedFaqOriginalUrl;

            if (activeHref.endsWith('/')) {
                activeHref = activeHref.substr(0, activeHref.length - 1);
            }

            targetHrefSplit = activeHref.split('/');
            targetHref = targetHrefSplit[targetHrefSplit.length - 1];

            let $isOwnerPageChild = $this.data('isOwnerpageChild') === true;
            let $currentItemHeader = $this.text(); 

            let $targetElm = $('#' + targetHref);

            let hasRelatedFaqItems = activeHref.indexOf($originalUrl) === -1 && hasSubFaqAsParent;

            // we might have related faq items, from other pages
            if (hasRelatedFaqItems) {
                targetHref = targetHrefSplit[targetHrefSplit.length - 2] + '/' + targetHrefSplit[targetHrefSplit.length - 1];

                if ($originalUrl.endsWith('/')) {
                    $originalUrl = $originalUrl.substr(0, $originalUrl.length - 1);
                }

                let originalUrlSplit = $originalUrl.split('/');
                relatedFaqOriginalUrl = originalUrlSplit[originalUrlSplit.length - 2];
            }

            let $parentFaqLink;
            let $textContainer = $('.hero-section__text .text-container');
            let $originalHeader = $('.hero-section__text .hidden').text();

            if ($isSubFaq) {
                history.pushState(null, null, $originalUrl);
                $textContainer.html('<h1 class="ContentWhite">' + $currentItemHeader + '</h1>');

                $parentFaqLink = $activeFaq.find('.panel-heading a').first();
                let $childLinks = $activeFaq.find('.panel-collapse .panel-heading a');

                if ($parentFaqLink.attr('aria-expanded') === 'false') {
                    $parentFaqLink.attr('aria-expanded', 'true');
                    $parentFaqLink.removeClass('collapsed');
                    $parentFaqLink.parent().parent().next().addClass('in');
                } else {
                    $parentFaqLink.attr('aria-expanded', 'false');
                    $childLinks.attr('aria-expanded', 'false');
                    $parentFaqLink.addClass('collapsed');
                    $childLinks.addClass('collapsed');
                    $parentFaqLink.parent().parent().next().removeClass('in');

                    $textContainer.html($originalHeader);
                }
            } else {
                $parentFaqLink = $activeFaq.parents('.js-faq-listing').find('.panel-heading a').first();
                $parentFaqLink.attr('aria-expanded', 'true');

                if ($this.attr('aria-expanded') === 'false') {
                    $this.attr('aria-expanded', 'true');
                    $this.removeClass('collapsed');
                    $targetElm.addClass('in');
                    $this.parents('.panel-collapse').addClass('in');

                    $textContainer.html('<h1 class="ContentWhite">' + $currentItemHeader + '</h1>');

                    if ($isOwnerPageChild) {
                        if (hasRelatedFaqItems) {
                            history.replaceState(null, null, '/' + relatedFaqOriginalUrl + '/' + targetHref);
                        } else {
                            history.pushState(null, null, $originalUrl + targetHref);
                        }
                    }
                } else {
                    $this.attr('aria-expanded', 'false');
                    $this.addClass('collapsed');
                    $targetElm.removeClass('in');

                    $textContainer.html($originalHeader);

                    if ($isOwnerPageChild) {

                        if ($parentFaqLink.length > 0) {
                            $originalHeader = $parentFaqLink.text().trim();
                            $textContainer.html('<h1 class="ContentWhite">' + $originalHeader + '</h1>');
                        }

                        if ($originalUrl.endsWith('/')) {
                            $originalUrl = $originalUrl.substr(0, $originalUrl.length - 1);
                        }
                        history.pushState(null, null, $originalUrl);
                    }
                }
            }

            $inactiveLinks = $inactiveLinks.filter(function () {
                let $link = $(this);
                return $link.attr('href') !== $parentFaqLink.attr('href');
            });

            // reset finalize
            $inactiveLinks.attr('aria-expanded', 'false');
            $inactiveLinks.addClass('collapsed');
        }
    };

    dataMonitor = {
        init: function () {
            let $dataMonitorBlock = $('.js-datamonitor');

            dataMonitor.setupDefaultButton($dataMonitorBlock);
            dataMonitor.setupModal();
        },
        setupDefaultButton: function ($dataMonitorBlock) {
            if ($dataMonitorBlock.length > 0) {
                let $link = $dataMonitorBlock.find('.js-datamonitor-link');
                if ($link.length > 0) {
                    $link.click(function () {
                        let $url = $link.data('url');
                        if ($url.length > 0) {
                            let $blockId = $link.data('blockid');
                            dataMonitor.login($url, $blockId);
                        }
                    });
                }
            }
        },
        setupModal: function () {
            let $formValidateEmailDataMonitor = $('.validate-email.datamonitor');

            if ($formValidateEmailDataMonitor.length > 0) {
                let $btnValidateEmail = $formValidateEmailDataMonitor.find('.btn-validate-email');
                let $dmModal = $('#dm-modal');
                let $modalTitle = $dmModal.find('.modal-title');
                let $modalBody = $dmModal.find('.modal-body .modal-body-text');
                let $spinnerSection = $dmModal.find('.loadingscreen__wrapper');
                let $hiddenErrorSection = $dmModal.find('.hidden.error-text');

                $formValidateEmailDataMonitor.on('submit', function (e) {
                    if (e.isDefaultPrevented()) {
                        // invalid
                        if (!$btnValidateEmail.hasClass('validate-custom')) {
                            $btnValidateEmail.addClass('validate-custom');
                        }
                    } else {
                        e.preventDefault();

                        $modalTitle.css({'visibility': 'hidden'});
                        $modalBody.hide();
                        $formValidateEmailDataMonitor.hide();
                        $spinnerSection.toggleClass('hidden');

                        let $url = $btnValidateEmail.data('url');
                        let $internalRedirectUrl = $btnValidateEmail.data('redirecturl');
                        let $blockId = $btnValidateEmail.data('blockid');
                        let $email = $formValidateEmailDataMonitor.find('#enroll-email').val();

                        dataMonitor.enroll($url, $internalRedirectUrl, $blockId, $email, $spinnerSection, $hiddenErrorSection);
                        // valid
                    }
                });

                $dmModal.on('hidden.bs.modal', function () {
                    $modalTitle.css({'visibility': 'visible'});
                    $modalBody.show();
                    $spinnerSection.addClass('hidden');
                    $hiddenErrorSection.addClass('hidden');
                    $formValidateEmailDataMonitor.show();
                });
            }
        },
        enroll: function (url, internalRedirectUrl, blockId, email, $spinnerSection, $hiddenErrorSection) {
            var timestamp = Date.now() / 1000;

            let newTab = window.open('', '_blank');

            if (url.length > 0) {
                if (internalRedirectUrl.length === 0) {
                    internalRedirectUrl = 'about:blank';
                }

                newTab.location.href = internalRedirectUrl;

                $.ajax({
                    url: url,
                    data: {blockId: parseInt(blockId), email: email},
                    method: 'post',
                    timeout: 8000
                }).done(function (response) {
                    if (typeof response !== 'undefined' && response.length > 0) {
                        newTab.location.href = response;
                        $spinnerSection.toggleClass('hidden');
                        window.location.reload($spinnerSection, $hiddenErrorSection); // reload to hide modal popup & change DM login button to do direct login
                        timestamp = Date.now() / 1000;
                    } else {
                        dataMonitor.handleError($spinnerSection, $hiddenErrorSection);
                    }
                }).fail(function () {
                    dataMonitor.handleError();
                    timestamp = Date.now() / 1000;
                    console.log("Enroll function failed " + timestamp);
                });
            }
        },
        handleError: function ($spinnerSection, $hiddenErrorSection) {
            $spinnerSection.fadeOut('fast', function () {
                $hiddenErrorSection.removeClass('hidden');
            });
            $.cookie(dmCookieName, '-1'); // cookie used to communicate with intermediate redirect page
        },
        login: function (url, blockId) {
            let newTab = window.open('', '_blank');

            if (url.length > 0) {
                let $loginButton = $('.js-datamonitor-link');
                let $loginButtonParent = $loginButton.parent();

                $.ajax({
                    url: url,
                    data: {blockId: parseInt(blockId)},
                    method: 'post'
                }).done(function (response) {
                    if (typeof response !== 'undefined' && response.length > 0) {
                        $loginButtonParent.find('.error-text').remove();
                        newTab.location.href = response;
                    }
                }).fail(function () {
                    let $errorMessage = $('#dm-modal .error-text').clone();
                    $errorMessage.removeClass('hidden text-center');
                    if ($loginButtonParent.find('.error-text').length === 0) {
                        $loginButtonParent.append($errorMessage);
                    }
                });
            }
        }
    };

    redirectPage = {
        init: function () {
            let $redirectPage = $('.redirectPage');
            let $loading = $redirectPage.find('.loadingscreen__wrapper');
            let $hiddenSection = $redirectPage.find('.error-msg.hidden');

            let queryString = window.location.search;

            if (queryString.indexOf('datamonitor') !== -1) {
                let timer;
                clearTimeout(timer);

                timer = setTimeout(function () {
                    let dmCookie = $.cookie(dmCookieName);
                    if (dmCookie === '-1') {
                        $loading.fadeOut('fast', function () {
                            $hiddenSection.removeClass('hidden');
                        });
                        $.removeCookie(dmCookieName);
                        clearTimeout(timer);
                    }
                }, 500);
            }
        }
    };

    salesforceForm = {
        init: function () {
            let $formInputIdFieldParent = $('#salesforce-formid');

            if ($formInputIdFieldParent.length > 0) {
                let queryString = window.location.search;
                if (queryString.indexOf('caseid') !== -1) {
                    let caseId = getQueryStringValue('caseid');

                    $formInputIdFieldParent.find('input[type=hidden]').attr('value', caseId);
                }
            }
        }
    };

    faqListing.init();
    dataMonitor.init();
    redirectPage.init();
    salesforceForm.init();
});

function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}